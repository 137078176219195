import './style.scss';

import { FC, ReactNode } from 'react';

import classNames from 'classnames';

export interface MQNavGroupProps {
  divided?: boolean;
  children?: ReactNode;
  direction?: 'horizontal' | 'vertical';
}

const MQNavGroup: FC<MQNavGroupProps> = ({ children, direction = 'horizontal', divided = false }) => (
  <div className={classNames('mq-nav-group', { divided }, direction)}>{children}</div>
);

export default MQNavGroup;
