import { FC, ReactNode } from 'react';

import { generateMailTo } from 'helpers/routes';

interface EmailLinkProps {
  email?: string;
  label?: ReactNode;
  children?: ReactNode;
  dataTestId?: string;
}

const EmailLink: FC<EmailLinkProps> = ({ email = 'example@example.com', dataTestId, label, children }) => (
  <a href={generateMailTo(email)} data-testid={dataTestId}>
    {label ?? children ?? email}
  </a>
);

export default EmailLink;
