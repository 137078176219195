import './style.scss';

import { useCallback, FC, HTMLAttributes, useLayoutEffect, useRef, useState } from 'react';

import classNames from 'classnames';

interface MQFormMarkerItemDto {
  value: string;
  type: 'valid' | 'invalid' | string;
}

interface MQFormTextEditorProps extends HTMLAttributes<HTMLDivElement> {
  value: string;
  markers?: MQFormMarkerItemDto[];
  disabled?: boolean;
  onEdit?: (value: string) => void;
  className?: string;
  valid?: boolean;
  invalid?: boolean;
  resize?: 'none' | 'vertical' | 'horizontal';
  dataTestId: string;
}

export const markValue = ({ markers, value }: { value: string; markers: MQFormMarkerItemDto[] }) => {
  try {
    return markers.reduce((acc, marker) => {
      const regex = new RegExp(`\\b${marker.value}\\b`, 'g');
      return acc.replace(regex, `<mark data-type="${marker.type}">${marker.value}</mark>`);
    }, value);
  } catch {
    return value;
  }
};

const getCaret = (el: Node): number => {
  let caretAt = 0;
  const sel = window.getSelection();
  if (!sel || sel.rangeCount === 0) {
    return caretAt;
  }
  const range = sel.getRangeAt(0);
  const preCaretRange = range.cloneRange();
  preCaretRange.selectNodeContents(el);
  preCaretRange.setEnd(range.endContainer, range.endOffset);
  caretAt = preCaretRange.toString().length;
  return caretAt;
};

const setCaret = (el: HTMLDivElement, offset: number): void => {
  const sel = window.getSelection();
  const range = document.createRange();
  let pos = offset;

  const traverse = (node: Node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      const length = node.textContent?.length ?? 0;
      if (pos <= length) {
        range.setStart(node, pos);
        return true;
      } else {
        pos -= length;
      }
    } else {
      for (const child of node.childNodes) {
        if (traverse(child)) {
          return true;
        }
      }
    }
    return false;
  };
  traverse(el);
  range.collapse(true);
  sel?.removeAllRanges();
  sel?.addRange(range);
};

const MQFormTextEditor: FC<MQFormTextEditorProps> = ({
  value,
  disabled = false,
  valid = false,
  invalid = false,
  resize = 'none',
  markers = [],
  onEdit,
  onBlur,
  onInput,
  className,
  dataTestId,
  style,
  ...rest
}) => {
  const [editing, setEditing] = useState(false);
  const markerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (markerRef.current) {
      const result = {
        content: value,
      };

      if (!editing) {
        result.content = markValue({
          value,
          markers,
        });
      }

      if (result.content !== markerRef.current.innerHTML) {
        const offset = getCaret(markerRef.current);
        markerRef.current.innerHTML = result.content;
        setCaret(markerRef.current, offset);
      }
    }
  }, [editing, markers, value]);

  const emitChange = useCallback(
    (text: string) => {
      onEdit?.(text);
    },
    [onEdit],
  );

  return (
    <div
      {...rest}
      ref={markerRef}
      data-testid={dataTestId}
      className={classNames('mq-form-text-editor', className, `mq-resize-${resize}`, { valid, invalid, disabled })}
      style={style}
      role="textbox"
      aria-multiline="true"
      contentEditable={!disabled}
      onInput={(e) => {
        setEditing(true);
        emitChange(e.currentTarget.innerText);
        onInput?.(e);
      }}
      onBlur={(e) => {
        setEditing(false);
        emitChange(e.currentTarget.innerText);
        onBlur?.(e);
      }}
      autoCapitalize="off"
      autoCorrect="off"
      autoSave="off"
    />
  );
};

export default MQFormTextEditor;
