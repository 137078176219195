import { ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS } from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  microsoftAuth: createRoute({
    params: {
      layoutTypes: [],
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
    },
    // Make sure to sync with back end.
    data: {
      path: `/microsoft-oauth-callback`,
    },
  }),
  googleAuth: createRoute({
    params: {
      layoutTypes: [],
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
    },
    // Make sure to sync with back end.
    data: {
      path: `/google-oauth-callback`,
    },
  }),
};
