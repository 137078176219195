import './style.scss';

import { FC, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';

import { ChatMarkAsReadRequestDto, ChatMessageDto } from '@mentorcliq/storage';
import { MQBadge, MQIcon, MQLoader } from '@mentorcliq/ui';

import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import { useAppIntl } from 'hooks/useAppIntl';

import MQIsVisible from 'modules/MQIsVisible';

import AppFormattedMessage from 'formatters/AppFormattedMessage';
import AppFormattedNumber from 'formatters/AppFormattedNumber';

import { categorizeMessagesByDate } from '../../helpers';
import ChatMessage from '../ChatMessage';

interface ChatMessagesProps {
  ownerId: number;
  matchId: number;
  readTime?: number | null;
  unreadMessagesCount?: number;
  messages: ChatMessageDto[];
  isLoading?: boolean;
  loadMore: (id: number) => void;
  markAsRead: (data: ChatMarkAsReadRequestDto) => void;
  children?: ReactNode;
}

const ChatMessages: FC<ChatMessagesProps> = ({
  children,
  ownerId,
  matchId,
  messages,
  loadMore,
  markAsRead,
  isLoading,
  readTime,
  unreadMessagesCount,
}) => {
  const intl = useAppIntl();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const firstMessage = useMemo(() => messages[0], [messages]);
  const lastMessage = useMemo(() => messages[messages.length - 1], [messages]);

  const handleScrollToBottom = () => {
    bottomRef.current?.scrollIntoView({
      behavior: 'auto',
    });
  };

  useEffect(() => {
    if (firstMessage) {
      loadMore(firstMessage?.id);
    }
  }, [loadMore, firstMessage]);

  useEffect(() => {
    if (matchId) {
      handleScrollToBottom();
    }
  }, [matchId]);

  useEffect(() => {
    if (lastMessage?.authorBasicInfo?.id === ownerId) {
      handleScrollToBottom();
    }
  }, [lastMessage, ownerId]);

  const handleScroll = useCallback(
    (position: number) => {
      if (wrapperRef.current) {
        if (position < 0) {
          loadMore(messages[0]?.id);
          wrapperRef.current.scrollTo({
            top: 1,
            behavior: 'smooth',
          });
        } else if (position >= wrapperRef.current.scrollHeight - wrapperRef.current.clientHeight) {
          if (unreadMessagesCount && !!lastMessage?.chatRoomId && !!lastMessage?.created) {
            markAsRead({
              userId: ownerId,
              chatRoomId: lastMessage.chatRoomId,
              readTime: lastMessage.created,
            });
          }
        }
      }
    },
    [loadMore, messages, unreadMessagesCount, lastMessage?.chatRoomId, lastMessage?.created, markAsRead, ownerId],
  );

  const categorized = useMemo(
    () =>
      categorizeMessagesByDate(
        messages,
        intl.formatMessage(APP_GLOBAL_MESSAGES.globalDateTodayName),
        intl.formatMessage(APP_GLOBAL_MESSAGES.globalDateYesterdayName),
      ),
    [intl, messages],
  );

  return (
    <div
      className="app-chat-messages"
      ref={wrapperRef}
      onScroll={(e) => {
        handleScroll(Math.round(e.currentTarget.scrollTop));
      }}
    >
      {isLoading && (
        <div className="app-chat-messages__loading">
          <MQLoader.Spinner />
        </div>
      )}
      {!!unreadMessagesCount && (
        <div className="app-chat-messages__unread">
          <MQBadge.Clickable
            onClick={() => {
              handleScrollToBottom();
            }}
            variant="info"
            startIcon={<MQIcon.Svg icon="arrow-down" />}
            highlighted
          >
            <AppFormattedMessage
              defaultMessage="{count} new messages"
              description="[Chat] new messages badge label"
              id="chat.new.mesages.badge.label"
              values={{
                count: <AppFormattedNumber number={unreadMessagesCount} />,
              }}
            />
          </MQBadge.Clickable>
        </div>
      )}
      {children}
      {categorized.map((data) => (
        <div key={data.date} className="app-chat-messages__category">
          <div className="app-chat-messages__category-name">
            <div className="app-chat-messages__category-badge">
              <MQBadge.Info variant="primary-light">{data.date}</MQBadge.Info>
            </div>
          </div>
          {data.messages.map((item) =>
            readTime && item.authorBasicInfo.id !== ownerId && readTime < item.created ? (
              <MQIsVisible
                key={item.id}
                onVisible={() => {
                  if (item.chatRoomId) {
                    markAsRead({
                      userId: ownerId,
                      chatRoomId: item.chatRoomId,
                      readTime: item.created,
                    });
                  }
                }}
              >
                <ChatMessage
                  authorBasicInfo={item.authorBasicInfo}
                  attachment={item.attachment}
                  created={item.created}
                  message={item.message}
                  unread
                />
              </MQIsVisible>
            ) : (
              <ChatMessage
                key={item.id}
                authorBasicInfo={item.authorBasicInfo}
                attachment={item.attachment}
                created={item.created}
                message={item.message}
              />
            ),
          )}
        </div>
      ))}
      <div className="app-chat-messages__bottom" ref={bottomRef} />
    </div>
  );
};

export default ChatMessages;
