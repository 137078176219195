import { filesApi } from '@mentorcliq/storage';
import { format } from 'date-fns';

import { LOCALE_DATE_FORMAT } from 'definitions/configs';

import { openLink } from './routes';

interface DownloadProps {
  onStart?: () => void;
  onEnd?: () => void;
  onError?: () => void;
}

interface DownloadByApiParams extends DownloadProps {
  fileId: number;
  name: string;
}

interface PreviewByApiParams extends DownloadProps {
  uuid: string;
}

interface DownloadByFetchParams extends DownloadProps {
  url: string;
  name: string;
}

interface DownLoadByUrlParams extends DownloadProps {
  url: string;
  name?: string;
}

interface DownLoadByContentParams extends DownloadProps {
  content: string;
  type?: string;
  name: string;
}

interface DownloadBlobParams {
  name: string;
  blob: string;
  type?: string;
}

export const downloadBlob = (params: DownloadBlobParams) => {
  const file = new Blob([params.blob], {
    type: params.type,
  });
  const url = window.URL.createObjectURL(file);
  const element = document.createElement('a');
  element.setAttribute('data-testid', 'download-link');
  element.href = url;
  element.download = params.name;
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
  window.URL.revokeObjectURL(url);
};

export const downloadMilestoneFile = (params: DownloadByApiParams) => {
  params.onStart?.();

  filesApi
    .downloadMilestoneFile(params.fileId)
    .then((response) => {
      downloadBlob({
        name: params.name,
        blob: response.data,
      });
    })
    .catch(() => {
      params.onError?.();
    })
    .finally(() => {
      params.onEnd?.();
    });
};

export const downloadResourceFile = (params: DownloadByApiParams) => {
  params.onStart?.();

  filesApi
    .downloadResourceLibraryFile(params.fileId)
    .then((response) => {
      downloadBlob({
        name: params.name,
        blob: response.data,
      });
    })
    .catch(() => {
      params.onError?.();
    })
    .finally(() => {
      params.onEnd?.();
    });
};

export const downloadChatFile = (params: DownloadByApiParams) => {
  params.onStart?.();

  filesApi
    .downloadChatFile(params.fileId)
    .then((response) => {
      downloadBlob({
        name: params.name,
        blob: response.data,
      });
    })
    .catch(() => {
      params.onError?.();
    })
    .finally(() => {
      params.onEnd?.();
    });
};

export const previewAttachment = (params: PreviewByApiParams) => {
  params.onStart?.();

  filesApi
    .getFile(params.uuid)
    .then((response) => {
      openLink({
        url: response.data.fileHandle,
      });
    })
    .catch(() => {
      params.onError?.();
    })
    .finally(() => {
      params.onEnd?.();
    });
};

export const downloadAttachment = (params: PreviewByApiParams) => {
  params.onStart?.();

  filesApi
    .getFile(params.uuid)
    .then((response) => {
      downloadByFetch({
        url: response.data.fileHandle,
        name: response.data.name,
      });
    })
    .catch(() => {
      params.onError?.();
    })
    .finally(() => {
      params.onEnd?.();
    });
};

export const downloadByFetch = (params: DownloadByFetchParams) => {
  params.onStart?.();

  fetch(params.url)
    .then((response) => {
      response.text().then((blob) => {
        downloadBlob({
          name: params.name,
          blob: blob,
        });
      });
    })
    .catch(() => {
      params.onError?.();
    })
    .finally(() => {
      params.onEnd?.();
    });
};

export const downloadByUrl = (params: DownLoadByUrlParams) => {
  params.onStart?.();
  const element = document.createElement('a');
  element.setAttribute('data-testid', 'download-link');
  element.setAttribute('href', params.url);
  if (params.name) {
    element.download = params.name;
  }
  element.click();
  element.remove();
  params.onEnd?.();
};

export const downloadByContent = (params: DownLoadByContentParams) => {
  params.onStart?.();
  downloadBlob({
    blob: params.content,
    name: params.name,
    type: params.type,
  });
  const element = document.createElement('a');
  const file = new Blob([params.content]);
  element.href = URL.createObjectURL(file);
  element.download = params.name;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
  params.onEnd?.();
};

export const getFileName = ({ url = '', sep = '/' }: { url?: string; sep?: string }) => {
  const data = new URL(url);
  return data.pathname?.slice(data.pathname.lastIndexOf(sep) + 1);
};

export const getFileExtension = ({ url = '' }: { url: string }) => {
  const name = getFileName({
    url: getFilePureLink(url),
  });

  return name.split('.').pop()?.toLowerCase();
};

export const getFilePureLink = (link: string) => {
  if (link.includes('?expires')) {
    const data = new URL(link);
    return data.origin + data.pathname;
  }

  return link;
};

export const generateFileNameWithDateSuffix = (prefix: string) =>
  `${prefix}-${format(new Date().getTime(), LOCALE_DATE_FORMAT)}`;
