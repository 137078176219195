import './style.scss';

import { FC } from 'react';

import { ChatRoomDto } from '@mentorcliq/storage';
import classNames from 'classnames';

import { APP_KEYBOARD_EVENT_KEYS } from 'definitions/configs';

import ChatRoom from '../ChatRoom';

interface ChatMenuProps {
  ownerId: number;
  activeId: number;
  onOpenRoom: (id: number) => void;
  items: ChatRoomDto[];
}

const ChatMenu: FC<ChatMenuProps> = ({ ownerId, activeId, items, onOpenRoom }) => (
  <div className="app-chat__menu" role="tablist">
    {items.map((room) => (
      <div
        role="tab"
        tabIndex={0}
        key={room.uuid}
        className={classNames('app-chat__menu-item', { active: room.match.id === activeId })}
        onKeyDown={(e) => {
          if (APP_KEYBOARD_EVENT_KEYS.space.includes(e.key)) {
            onOpenRoom(room.match.id);
          }
        }}
        onClick={() => {
          onOpenRoom(room.match.id);
        }}
      >
        <ChatRoom
          members={room.match.members.filter(({ userId }) => userId !== ownerId)}
          room={room}
          messages={room?.messages || []}
        />
      </div>
    ))}
  </div>
);

export default ChatMenu;
