import './style.scss';

import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { deleteChatFileThunk, FileDestinationEnum, uploadChatFileThunk } from '@mentorcliq/storage';
import { MQIcon, MQButton } from '@mentorcliq/ui';
import * as yup from 'yup';

import { APP_KEYBOARD_EVENT_KEYS } from 'definitions/configs';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppErrors, useAppFormik } from 'hooks/useAppFormik';
import { useAppIntl } from 'hooks/useAppIntl';

import MQForm from 'modules/MQForm';

import AppFilePreview from 'components/AppFilePreview';
import AppFileUpload from 'components/AppFileUpload';

import { AppFileActionProps } from '../../../../../types/files';
import { ChatMessageDataProps } from '../../types';
import ChatEmojis from '../ChatEmojis';

interface ChatToolbarProps {
  matchId?: number;
  sendMessage: (message: ChatMessageDataProps) => void;
  disabled?: boolean;
  placeholder?: string;
}

const CHAT_MESSAGE_MAX_LENGTH = 300;

const ChatToolbar: FC<ChatToolbarProps> = ({ sendMessage, matchId, disabled = false, placeholder }) => {
  const intl = useAppIntl();
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [showEmojis, setShowEmojis] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [errors, setErrors] = useAppErrors({
    attachment: null,
  });

  const formik = useAppFormik<ChatMessageDataProps>({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      message: yup
        .string()
        .max(CHAT_MESSAGE_MAX_LENGTH, ({ value }) =>
          intl.formatMessage(
            {
              defaultMessage: `Must be less than {max} characters ({length} / {max})`,
              id: 'chat.message.max.validation',
              description: '[Chat] message length validation',
            },
            {
              length: value.length,
              max: CHAT_MESSAGE_MAX_LENGTH,
            },
          ),
        )
        .test('isValidChat', '', (config, context) => {
          const currentValues = context.parent;
          const textWithoutSpace = config?.trim();

          return !(
            textWithoutSpace === '' &&
            (!currentValues.attachment || textWithoutSpace !== currentValues.message)
          );
        }),
    }),
    initialValues: {
      message: '',
      attachment: null,
    },
    onSubmit: (values, { resetForm }) => {
      if (!disabled && (values.message || values.attachment)) {
        sendMessage({
          attachment: values.attachment,
          message: values.message.replaceAll('<br/>', '\n'),
        });
        resetForm({
          values: {
            message: '',
            attachment: null,
          },
          errors: {},
        });
      }
      setShowEmojis(false);
    },
  });

  const { values, setValues } = formik;

  const appendAfterCursor = useCallback(
    (content: string) => {
      if (inputRef.current) {
        const textarea = inputRef.current;
        const cursorPos = textarea.selectionStart;
        const newMessage = values.message.substring(0, cursorPos) + content + values.message.substring(cursorPos);
        setValues((prevState) => ({
          ...prevState,
          message: newMessage,
        }));
        const newCursorPos = cursorPos + content.length;
        textarea.focus();
        textarea.setSelectionRange(newCursorPos, newCursorPos, 'forward');
      }
    },
    [setValues, values.message],
  );

  const handleUpload = useCallback(
    (file: File) => {
      if (matchId) {
        setUploading(true);
        const formData = new FormData();
        formData.append('file', file, file.name);

        dispatch(
          uploadChatFileThunk({
            matchId,
            data: formData,
          }),
        )
          .then((response) => {
            if (uploadChatFileThunk.fulfilled.match(response)) {
              setValues((prevState) => ({
                ...prevState,
                attachment: response.payload,
              }));
            }
          })
          .finally(() => {
            setUploading(false);
          });
      }
    },
    [dispatch, matchId, setValues],
  );

  const handleRemoveAttachment = useCallback(
    (data: AppFileActionProps) => {
      if (matchId) {
        dispatch(
          deleteChatFileThunk({
            attachmentId: data.id,
            matchId: +matchId,
          }),
        );
      }
      setValues((prev) => ({
        ...prev,
        attachment: null,
      }));
    },
    [dispatch, matchId, setValues],
  );

  useEffect(() => {
    if (inputRef.current) {
      if (!values.message.length) {
        inputRef.current.style.height = 'auto';
      } else {
        inputRef.current.style.height = 'auto';
        inputRef.current.style.height = `${inputRef.current.scrollHeight + 1}px`;

        if (inputRef.current.scrollHeight > inputRef.current.offsetHeight) {
          inputRef.current.style.overflow = 'auto';
        } else {
          inputRef.current.style.overflow = 'hidden';
        }
      }
    }
  }, [values.message.length]);

  return (
    <div className="app-chat-toolbar">
      <MQForm onSubmit={formik.handleSubmit} className="app-chat-toolbar__form">
        {!!values.attachment && (
          <div className="app-chat-toolbar__attachment">
            <AppFilePreview
              uuid={values.attachment.uuid}
              id={values.attachment.id}
              onRemove={handleRemoveAttachment}
              height={100}
              showInfo
              cover
            />
          </div>
        )}
        <MQForm.Group>
          <div className="app-chat-toolbar__buttons">
            <div className="app-chat-toolbar__tools">
              <AppFileUpload
                triggers={['drop']}
                onUpload={(files) => handleUpload(files[0])}
                type={FileDestinationEnum.Chat}
                onReject={({ message }) => {
                  setErrors((prevState) => ({
                    ...prevState,
                    attachment: message,
                  }));
                }}
                onBlur={() => {
                  setErrors((prevState) => ({
                    ...prevState,
                    attachment: null,
                  }));
                }}
              >
                <MQForm.Textarea
                  instance={inputRef}
                  placeholder={
                    placeholder ||
                    intl.formatMessage({
                      defaultMessage: 'Write a message...',
                      description: '[Chat] Message Input placeholder',
                      id: 'chat.write.message.input.placeholder',
                    })
                  }
                  onKeyDown={(e) => {
                    if (APP_KEYBOARD_EVENT_KEYS.enter.includes(e.key) && !e.shiftKey) {
                      e.preventDefault();
                      return formik.submitForm();
                    }
                  }}
                  onChange={(e) => {
                    setValues((prevState) => ({
                      ...prevState,
                      message: e.currentTarget.value,
                    }));
                  }}
                  resize="none"
                  rows={1}
                  className="app-chat-toolbar__input"
                  dataTestId="chat-toolbar-write-a-message"
                  value={values.message}
                  autoFocus
                />
              </AppFileUpload>
              {matchId && (
                <div className="app-chat-toolbar__helpers">
                  <MQButton
                    dataTestId="toggle-chat-emojis"
                    type="button"
                    shape="square"
                    variant="sub-action--tertiary"
                    startIcon={<MQIcon.Svg icon="face-smile__r" size="lg" />}
                    onClick={() => {
                      setShowEmojis(!showEmojis);
                    }}
                  />
                  <AppFileUpload
                    onUpload={(files) => handleUpload(files[0])}
                    type={FileDestinationEnum.Chat}
                    onReject={({ message }) => {
                      setErrors((prevState) => ({
                        ...prevState,
                        attachment: message,
                      }));
                    }}
                    onBlur={() => {
                      setErrors((prevState) => ({
                        ...prevState,
                        attachment: null,
                      }));
                    }}
                  >
                    <MQButton
                      dataTestId="toggle-chat-file"
                      type="button"
                      variant="sub-action--tertiary"
                      shape="square"
                      isLoading={uploading}
                      startIcon={<MQIcon.Svg icon="paperclip" size="lg" />}
                    />
                  </AppFileUpload>
                </div>
              )}
            </div>
            <MQButton
              dataTestId="send-message"
              startIcon={<MQIcon.Svg icon="send" size="lg" />}
              disabled={!!Object.keys(formik.errors).length}
              variant="sub-action--tertiary"
              shape="square"
              onClick={formik.submitForm}
            />
          </div>
          <MQForm.Feedback type="invalid" touched={formik.touched.message}>
            {formik.errors.message}
          </MQForm.Feedback>
          <MQForm.Feedback type="invalid">{errors.attachment}</MQForm.Feedback>
        </MQForm.Group>
        {showEmojis && (
          <div className="app-chat-toolbar__emojis">
            <ChatEmojis
              onSelect={(data) => {
                appendAfterCursor(data.emoji);
              }}
            />
          </div>
        )}
      </MQForm>
    </div>
  );
};

export default ChatToolbar;
