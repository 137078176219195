import { FC } from 'react';

import classNames from 'classnames';

import { MQSidebarProps } from '../../types';

const MQSidebarProvider: FC<MQSidebarProps> = ({ className = '', ariaLabel, children, ...props }) => (
  <nav {...props} className={classNames(['mq-sidebar', className])} aria-label={ariaLabel ?? 'mq-sidebar-nav'}>
    {children}
  </nav>
);

export default MQSidebarProvider;
