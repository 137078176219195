import { useCallback, useMemo } from 'react';

import { getChatFileThunk, getFileThunk } from '@mentorcliq/storage';

import { AppFileActionProps } from 'types/files';

import { openLink } from 'helpers/routes';

import { useAppDispatch } from 'hooks/useAppDispatch';

export const useAppFiles = () => {
  const dispatch = useAppDispatch();

  const previewSecuredFile = useCallback(
    (data: AppFileActionProps) => {
      dispatch(
        getFileThunk({
          uuid: data.uuid,
        }),
      ).then((response) => {
        if (getFileThunk.fulfilled.match(response)) {
          openLink({
            url: response.payload.fileHandle,
          });
        }
      });
    },
    [dispatch],
  );

  const previewChatFile = useCallback(
    (data: AppFileActionProps) => {
      dispatch(
        getChatFileThunk({
          attachmentId: data.id,
        }),
      ).then((response) => {
        if (getChatFileThunk.fulfilled.match(response)) {
          openLink({
            url: response.payload.fileHandle,
          });
        }
      });
    },
    [dispatch],
  );

  return useMemo(
    () => ({
      previewChatFile,
      previewSecuredFile,
    }),
    [previewChatFile, previewSecuredFile],
  );
};
