import { COMMON_ROUTES_LAYOUT_ITEMS, ROUTE_COMMON_BASE_ACCESS } from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  mainPage: createRoute({
    params: {
      layoutTypes: COMMON_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_COMMON_BASE_ACCESS,
    },
    data: {
      path: '/',
    },
  }),
};
