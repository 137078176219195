import { ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_ACCESS } from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  slack: createRoute({
    params: {
      layoutTypes: [],
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_ACCESS,
    },
    data: {
      path: `/platform-addons/slack_auth`,
      exact: true,
    },
  }),
};
