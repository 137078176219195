import './style.scss';

import { ElementType, useCallback, useContext } from 'react';

import classNames from 'classnames';

import { MQDropdownToggleProps } from '../../types';
import { MQNavDropdownContext } from '../MQDropdown';

const MQDropdownToggle = <T extends ElementType = 'button'>({
  children,
  className = '',
  toggleIcon,
  as: Component = 'button',
  dataTestId,
  ...props
}: MQDropdownToggleProps<T>) => {
  const { isShow, setIsShow, triggers } = useContext(MQNavDropdownContext);

  const handleOnClick = useCallback(() => {
    if (triggers?.includes('click')) {
      setIsShow?.(!isShow);
    }
  }, [isShow, setIsShow, triggers]);

  const handleOnFocus = useCallback(() => {
    if (triggers?.includes('hover')) {
      setIsShow?.(true);
    }
  }, [setIsShow, triggers]);

  return (
    <Component
      data-testid={dataTestId || 'mq-dropdown-toggle'}
      className={classNames(['mq-dropdown__toggle', className])}
      onClick={handleOnClick}
      onFocus={handleOnFocus}
      {...props}
    >
      {children}
      {toggleIcon}
    </Component>
  );
};

export default MQDropdownToggle;
