import Bugsnag from '@bugsnag/js';
import {
  addUserThunk,
  ApiErrorCodes,
  AuthDto,
  authenticateThunk,
  BaseTokenDto,
  cancelUsersThunk,
  configureSSOConfigurationThunk,
  createAdminMatchGroupMatchThunk,
  createReportBuilderConfigThunk,
  createSuggestionThunk,
  deleteReportBuilderConfigThunk,
  editAccountThunk,
  editUserThunk,
  fetchApprovalInfoThunk,
  fetchApprovalInfoUnauthenticatedThunk,
  getEnrollmentCurrentStateThunk,
  getReportBuilderDataThunk,
  getReportBuilderModulesAndFieldsThunk,
  getReportBuilderProgramReportsListThunk,
  getReportBuilderReportsListFiltersThunk,
  getReportBuilderReportsListThunk,
  MQApiError,
  processIntlBaseMessagesTranslateThunk,
  publishDraftProgramThunk,
  registerThunk,
  requestEnrollmentThunk,
  resetPasswordThunk,
  scheduleIntelligentMatchingThunk,
  setEnrollmentNextStateThunk,
  skipEnrollmentThunk,
  testSSOLoginThunk,
  updateAdminMatchGroupMatchThunk,
  updateIntelligentMatchThunk,
  updatePeopleRolesThunk,
  updatePlatformConfigurationThunk,
  updateReportBuilderConfigThunk,
  uploadChatFileThunk,
  uploadFileThunk,
  uploadHrisSyncFilesThunk,
  validateProgramEmailsThunk,
  getEnrollmentPreviousStateThunk,
  updateEnrollmentPreferencesThunk,
  setEnrollmentPreferencesThunk,
  sendMatchRequestThunk,
  getTokensThunk,
  createTokenThunk,
  deleteTokenThunk,
  updateSmartInviteConfigThunk,
  clearSmartInviteConfigsThunk,
  createSmartInviteConfigThunk,
  removeSmartInviteConfigThunk,
} from '@mentorcliq/storage';
import { PayloadAction } from '@reduxjs/toolkit';

import ROUTES from 'routes';

import { CustomErrorsEnum } from 'types/errors';
import { AppAlertTypesEnum, AppLocaleStorageKeysEnum } from 'types/global';
import { ActionMetaProps } from 'types/storage';

import { APP_ENV_CONFIGS } from 'definitions/configs';
import { APP_ERRORS } from 'definitions/errors';
import { IGNORE_ERROR_MESSAGES, IGNORE_PAGES } from 'definitions/logger';

import alerts from 'helpers/alerts';
import caches from 'helpers/caches';
import history from 'helpers/history';

export const processStatus = (action: PayloadAction<MQApiError>) => {
  if (action.payload.subErrors) {
    for (const e of action.payload.subErrors) {
      switch (e.code) {
        case ApiErrorCodes.GdprNotAccepted:
          history.mq.push({
            pathname: ROUTES.acceptGdpr.data.path,
            search: history.mq.location.search,
          });
          break;
        case ApiErrorCodes.ViewAsNotAllowed:
        case ApiErrorCodes.LearningLabDisabled:
          history.mq.push(
            ROUTES.statusPage403.convert({
              search: {
                reason: e.code,
              },
            }),
          );
          break;
      }
    }
  } else {
    const status = Number(action.payload.code ?? 0);

    const error = Object.values(APP_ERRORS).find((err) => err.codes.includes(status));

    if (error?.redirect) {
      history.mq.push(error.redirect);
    }

    if (error?.alert) {
      alerts.addCustom({
        type: error.alert,
        message: action.payload.message,
      });
    }
  }

  return action;
};

export const processError = (action: PayloadAction<MQApiError, string, ActionMetaProps>) => {
  if (APP_ENV_CONFIGS.environments.production) {
    if (action.payload.apiError) {
      const errorDetails = {
        args: action.meta.arg,
        code: action.payload.code,
        message: action.payload.message,
        url: action.payload.apiError.request?.responseURL,
        subErrors: action.payload.subErrors,
      };

      Bugsnag.notify(action.payload.apiError, (event) => {
        const invalidError =
          action.payload.subErrors?.some((error) => error.code === ApiErrorCodes.GdprNotAccepted) ||
          IGNORE_PAGES.some((page) => event.request.url?.includes(page)) ||
          IGNORE_ERROR_MESSAGES.some((message) => action.payload.message?.includes(message));
        if (invalidError) {
          return false;
        }

        event.addMetadata('api', errorDetails);
      });
    }
  }

  switch (action.type) {
    case resetPasswordThunk.rejected.type:
    case authenticateThunk.rejected.type:
    case registerThunk.rejected.type:
    case setEnrollmentNextStateThunk.rejected.type:
    case getEnrollmentPreviousStateThunk.rejected.type:
    case getEnrollmentCurrentStateThunk.rejected.type:
    case skipEnrollmentThunk.rejected.type:
    case updateEnrollmentPreferencesThunk.rejected.type:
    case setEnrollmentPreferencesThunk.rejected.type:
    case sendMatchRequestThunk.rejected.type:
    case createSuggestionThunk.rejected.type:
    case requestEnrollmentThunk.rejected.type:
    case editUserThunk.rejected.type:
    case addUserThunk.rejected.type:
    case validateProgramEmailsThunk.rejected.type:
    case uploadFileThunk.rejected.type:
    case uploadChatFileThunk.rejected.type:
    case cancelUsersThunk.rejected.type:
    case updatePeopleRolesThunk.rejected.type:
    case configureSSOConfigurationThunk.rejected.type:
    case testSSOLoginThunk.rejected.type:
    case scheduleIntelligentMatchingThunk.rejected.type:
    case updateIntelligentMatchThunk.rejected.type:
    case fetchApprovalInfoThunk.rejected.type:
    case fetchApprovalInfoUnauthenticatedThunk.rejected.type:
    case processIntlBaseMessagesTranslateThunk.rejected.type:
    case updatePlatformConfigurationThunk.rejected.type:
    case publishDraftProgramThunk.rejected.type:
    case editAccountThunk.rejected.type:
    case createAdminMatchGroupMatchThunk.rejected.type:
    case updateAdminMatchGroupMatchThunk.rejected.type:
    case uploadHrisSyncFilesThunk.rejected.type:
    case getTokensThunk.rejected.type:
    case createTokenThunk.rejected.type:
    case deleteTokenThunk.rejected.type:
    case getReportBuilderDataThunk.rejected.type:
    case createReportBuilderConfigThunk.rejected.type:
    case updateReportBuilderConfigThunk.rejected.type:
    case deleteReportBuilderConfigThunk.rejected.type:
    case getReportBuilderProgramReportsListThunk.rejected.type:
    case getReportBuilderReportsListFiltersThunk.rejected.type:
    case getReportBuilderReportsListThunk.rejected.type:
    case getReportBuilderModulesAndFieldsThunk.rejected.type:
    case clearSmartInviteConfigsThunk.rejected.type:
    case removeSmartInviteConfigThunk.rejected.type:
    case updateSmartInviteConfigThunk.rejected.type:
    case createSmartInviteConfigThunk.rejected.type:
      alerts.addDanger({
        message: action.payload.message,
        body: action.payload.body,
        enableReinitialize: false,
      });
      break;
  }

  if (action.payload.code === CustomErrorsEnum.Econnaborted) {
    alerts.addCustom({
      type: AppAlertTypesEnum.Timeout,
    });

    return action;
  }

  return processStatus(action);
};

export const refreshToken = (user: AuthDto, payload: BaseTokenDto) => {
  user.accessToken = payload.accessToken;
  user.expiresAt = payload.expiresAt;
  caches.locale.setItem({
    type: AppLocaleStorageKeysEnum.User,
    data: user,
  });
  return user;
};

export const createAuthHeaders = (token: string) => ({
  Authorization: `Bearer ${token}`,
});
