import { LayoutTypesEnum, RouteTypes } from 'types/route';

export const COMMON_ROUTES_LAYOUT_ITEMS = [
  LayoutTypesEnum.Footer,
  LayoutTypesEnum.Header,
  LayoutTypesEnum.Sidebar,
  LayoutTypesEnum.Alerts,
  LayoutTypesEnum.Support,
  LayoutTypesEnum.Cookies,
];

export const PREVIEW_ROUTES_LAYOUT_ITEMS = [LayoutTypesEnum.Header];

export const PREVIEW_ADMIN_ROUTES_LAYOUT_ITEMS = [LayoutTypesEnum.Header, LayoutTypesEnum.Sidebar];

export const PRIVATE_ROUTES_LAYOUT_ITEMS = [...COMMON_ROUTES_LAYOUT_ITEMS, LayoutTypesEnum.Assistant];

export const PRIVATE_ROUTES_LAYOUT_WITH_SCROLLER_ITEMS = [...PRIVATE_ROUTES_LAYOUT_ITEMS, LayoutTypesEnum.Scroll];

export const PRIVATE_ROUTES_LAYOUT_WITH_BANNER_ITEMS = [...PRIVATE_ROUTES_LAYOUT_ITEMS, LayoutTypesEnum.Banner];

export const PUBLIC_ROUTES_LAYOUT_ITEMS = [LayoutTypesEnum.Footer, LayoutTypesEnum.Cookies];

export const ROUTE_AUTHENTICATED_ACCESS = [RouteTypes.Authenticated];

export const ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_ACCESS = [...ROUTE_AUTHENTICATED_ACCESS, RouteTypes.GdprAccepted];

export const ROUTE_COMMON_ACCESS = [RouteTypes.Common];

export const ROUTE_UNAUTHENTICATED_ACCESS = [RouteTypes.UnAuthenticated];

export const ROUTE_COMMON_BASE_ACCESS = [...ROUTE_COMMON_ACCESS, RouteTypes.Base];

export const ROUTE_PREVIEW_AUTHENTICATED_ACCESS = [...ROUTE_AUTHENTICATED_ACCESS, RouteTypes.Preview];

export const ROUTE_PREVIEW_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_ENROLLED_ACCESS = [
  ...ROUTE_AUTHENTICATED_ACCESS,
  RouteTypes.GdprAccepted,
  RouteTypes.Enrolled,
];

export const ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ACCESS = [
  ...ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_ACCESS,
  RouteTypes.Admin,
];

export const ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS = [
  ...ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ACCESS,
  RouteTypes.Enrolled,
];

export const ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_ACCESS = [
  ...ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_ACCESS,
  RouteTypes.Verified,
];

export const ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS = [
  ...ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_ACCESS,
  RouteTypes.Enrolled,
];
