import { FC, ReactNode } from 'react';

import { MQButton, MQIcon, MQTypo } from '@mentorcliq/ui';

import ROUTES from 'routes';

import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

import AppLink from 'router/AppLink';

interface AppLearnButtonProps {
  article?: string;
  learnMore?: () => void;
  children?: ReactNode;
  dataTestId?: string;
}
const AppLearnButton: FC<AppLearnButtonProps> = ({ article, learnMore, dataTestId, children }) => {
  if (article) {
    return (
      <AppLink
        dataTestId={dataTestId ?? 'learn-more'}
        to={ROUTES.cliqipedia.convert({
          search: {
            article,
          },
        })}
        target="_blank"
        onClick={learnMore}
      >
        <MQTypo.Formatter prefix={<MQIcon.Svg icon="info-circle__r" />}>
          {children ?? <AppFormattedMessage {...APP_GLOBAL_MESSAGES.learnMore} />}
        </MQTypo.Formatter>
      </AppLink>
    );
  }

  return (
    <MQButton
      dataTestId={dataTestId ?? 'learn-more'}
      startIcon={<MQIcon.Svg icon="info-circle__r" />}
      onClick={learnMore}
      variant="tertiary"
    >
      {children ?? <AppFormattedMessage {...APP_GLOBAL_MESSAGES.learnMore} />}
    </MQButton>
  );
};

export default AppLearnButton;
