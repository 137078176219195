import { FeatureTypeEnum } from '@mentorcliq/storage';

import { PRIVATE_ROUTES_LAYOUT_ITEMS, ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_ACCESS } from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  acceptGdpr: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_ACCESS,
      access: {
        features: {
          value: [FeatureTypeEnum.Gdpr],
        },
      },
    },
    data: {
      path: '/accept-gdpr',
    },
  }),
};
