import './style.scss';

import { FC, ReactNode } from 'react';

import classNames from 'classnames';
import { NavLink, NavLinkProps } from 'react-router-dom';

interface AppLinkProps extends NavLinkProps {
  underline?: boolean;
  children?: ReactNode;
  dataTestId?: string;
}

const AppLink: FC<AppLinkProps> = ({ underline = true, dataTestId, ...props }) => (
  <NavLink data-testid={dataTestId} className={classNames('app-link', { underline })} {...props} />
);

export default AppLink;
