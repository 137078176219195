import { FeatureTypeEnum, PermissionsEnum } from '@mentorcliq/storage';

import { LayoutSizeTypes } from 'types/route';

import {
  PRIVATE_ROUTES_LAYOUT_ITEMS,
  ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
} from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  mentorFlix: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        permissions: {
          value: [PermissionsEnum.ManageMentorFlixContent],
        },
        features: {
          value: [FeatureTypeEnum.MentorFlix],
        },
      },
    },
    data: {
      path: '/mentor-flix',
      exact: true,
    },
  }),
};
