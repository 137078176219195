import { DateRangeTypesEnum, DurationDto, DurationEnum, TimeZoneDto } from '@mentorcliq/storage';
import {
  addDays,
  addHours,
  format,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isSameMonth,
  isValid,
  minutesToMilliseconds,
  parseISO,
  startOfDay,
  subMilliseconds,
  subMonths,
} from 'date-fns';
import { range } from 'lodash';
import { FormatDateOptions } from 'react-intl';

import { DateValueType, TimeRangeValue } from 'types/global';

import { NEAREST_TIME_INTERVAL_MINUTES } from 'definitions/calendar';
import { LOCALE_DATE_FORMAT } from 'definitions/configs';
import { DATE_RANGE_MONTHS } from 'definitions/labels';

const toUTCTimestamp = (d: DateValueType) => {
  const date = new Date(d).getTime();
  const offset = new Date().getTimezoneOffset();
  return date.valueOf() + offset * 60 * 1000;
};

export const toUTCDate = (d: DateValueType): Date => new Date(toUTCTimestamp(d));

export const getUTCStartOfDay = (date: string) => {
  const selectedDate = new Date(date);
  const selectedDateUTC = new Date(
    selectedDate.getUTCFullYear(),
    selectedDate.getUTCMonth(),
    selectedDate.getUTCDate(),
  ).setHours(0, 0, 0, 0);
  return new Date(selectedDateUTC);
};

export const convertToSpecificTimezone = (d: DateValueType, timezoneDifference: number): Date => {
  const date = new Date(d).getTime();
  const offset = new Date().getTimezoneOffset();
  const utc = date.valueOf() + offset * 60 * 1000;
  const timezone = utc + timezoneDifference * 60 * 1000;
  return new Date(timezone);
};

export const getUtcLabel = (timeZone: TimeZoneDto) => {
  const label = timeZone.alias ?? timeZone.zoneId;
  const sign = timeZone.offsetSeconds < 0 ? '-' : '+';
  const duration = Math.abs(timeZone.offsetSeconds) / 60;
  const minutes = duration % 60;
  const hours = (duration - minutes) / 60;
  const hoursStr = hours < 10 ? `0${hours}` : hours;
  const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
  return `(UTC${sign}${hoursStr}:${minutesStr}): ${label}`;
};

export const tryToGetUtcLabel = (answer: TimeZoneDto | string) => {
  if (typeof answer === 'string') {
    return answer;
  }

  return `${answer.zoneId}`;
};

export const getIsEntireMonth = (startDate: string, endDate: string) => {
  const currentStartDate = parseISO(startDate);
  const currentEndDate = parseISO(endDate);

  return (
    isSameMonth(currentStartDate, currentEndDate) &&
    isFirstDayOfMonth(currentStartDate) &&
    isLastDayOfMonth(currentEndDate)
  );
};

export const currentZonedDateToUTC = (date: Date): Date => {
  const timezoneOffsetMS = minutesToMilliseconds(new Date().getTimezoneOffset());
  return subMilliseconds(date, timezoneOffsetMS);
};

export const generateDateRangeValue = (type: DateRangeTypesEnum, defaultValue?: { from?: string; to?: string }) => ({
  dateRangeType: type,
  from:
    defaultValue?.from && isValid(new Date(defaultValue?.from))
      ? format(parseISO(defaultValue.from), LOCALE_DATE_FORMAT)
      : format(subMonths(new Date(), DATE_RANGE_MONTHS[type]), LOCALE_DATE_FORMAT),
  to:
    defaultValue?.to && isValid(new Date(defaultValue?.to))
      ? format(parseISO(defaultValue.to), LOCALE_DATE_FORMAT)
      : format(new Date(), LOCALE_DATE_FORMAT),
});

export const convertDateToHours = (duration: DurationDto): number => {
  switch (duration.period) {
    case DurationEnum.Day:
      return (duration.interval ?? 1) * 24;
    case DurationEnum.Week:
      return (duration.interval ?? 1) * 24 * 7;
    case DurationEnum.Month:
      return (duration.interval ?? 1) * 24 * 7 * 4;
    case DurationEnum.Year:
      return (duration.interval ?? 1) * 24 * 7 * 4 * 12;
    default:
      return duration.interval ?? 1;
  }
};

export const getTomorrowDate = () => {
  const now = new Date();
  const tomorrow = addDays(now, 1);
  const startOfTomorrow = startOfDay(tomorrow);
  const timezoneOffsetInMinutes = startOfTomorrow.getTimezoneOffset();
  const timezoneOffsetInMilliseconds = timezoneOffsetInMinutes * 60 * 1000;

  return toUTCDate(new Date(startOfTomorrow.getTime() - timezoneOffsetInMilliseconds));
};

export const getTomorrowDateESTString = () => {
  const now = new Date();
  const tomorrow = addDays(now, 1);
  const isEDT = isEasternDaylightTime(now);
  const offset = isEDT ? -4 : -5;
  const estTomorrow = addHours(tomorrow, offset);
  return format(toUTCDate(new Date(estTomorrow.getTime())), LOCALE_DATE_FORMAT);
};

const isEasternDaylightTime = (date: Date) => {
  const year = date.getFullYear();
  const edtStart = new Date(Date.UTC(year, 2, 8, 7));
  const edtEnd = new Date(Date.UTC(year, 10, 1, 6));
  return date.getTime() >= edtStart.getTime() && date.getTime() < edtEnd.getTime();
};

export const getTimesDifference = (diff: number) => {
  const date = new Date(new Date('01/01/2007').getTime() + diff);

  return {
    hours: date.getHours(),
    minutes: date.getMinutes(),
  };
};

export const generateTimeListSeparatedBy15Minutes = (): Record<string, TimeRangeValue> =>
  new Array(24 * 4)
    .fill(1)
    .map((e, index) => index * NEAREST_TIME_INTERVAL_MINUTES * 60000) // creating ms interval separated by 15 minutes
    .reduce((obj, ms) => {
      const msInSecond = 1000;
      const secondsInMinute = 60;
      const minutesInHour = 60;

      const totalSeconds = Math.floor(ms / msInSecond);
      const totalMinutes = Math.floor(totalSeconds / secondsInMinute);
      const totalHours = Math.floor(totalMinutes / minutesInHour);

      const minutesWithoutHours = totalMinutes - totalHours * minutesInHour;
      const minutesString = minutesWithoutHours.toString().padStart(2, '0');
      const hoursString = totalHours.toString().padStart(2, '0');

      return {
        ...obj,
        [`${hoursString}:${minutesString}`]: {
          timeWithPMAM: `${!totalHours || !(totalHours % 12) ? 12 : totalHours % 12}:${minutesString} ${
            totalHours < 12 ? 'AM' : 'PM'
          }`,
          timeInMS: ms,
        },
      };
    }, {});

export const getTodayTime = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today.getTime();
};

export const getYesterdayTime = () => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  return yesterday.getTime();
};

export const getPureDate = (date: number | string) => {
  const pure = new Date(date);
  pure.setHours(0, 0, 0, 0);
  return pure;
};

export const mapDateFormatToOptions = ({ format, ...options }: FormatDateOptions): Intl.DateTimeFormatOptions => {
  const components = format?.split(/[-/ :,]/)?.filter((item) => !!item) || [];

  return components.reduce(
    (prev, component) => {
      switch (component) {
        case 'yyyy':
          prev.year = 'numeric';
          break;
        case 'MM':
          prev.month = 'numeric';
          break;
        case 'dd':
          prev.day = 'numeric';
          break;
        case 'HH':
          prev.hour = 'numeric';
          break;
        case 'hh':
          prev.hour = 'numeric';
          prev.hour12 = true;
          break;
        case 'mm':
          prev.minute = 'numeric';
          break;
        case 'ss':
          prev.second = 'numeric';
          break;
        case 'a':
          prev.hour12 = true;
          break;
        case 'do':
          prev.day = 'numeric';
          break;
        case 'MMM':
          prev.month = 'short';
          break;
        case 'MMMM':
          prev.month = 'long';
          break;
        case 'd':
          prev.day = 'numeric';
          break;
        case 'EEEE':
          prev.weekday = 'long';
          break;
        case 'aaa':
          prev.timeZoneName = 'short';
          break;
      }

      return prev;
    },
    { ...options },
  );
};

export const checkIfDateTimeInRange = (dateTime: number, before: number, after: number) =>
  dateTime >= before && dateTime <= after;

export const getMonthDayOptions = () =>
  range(1, 29).map((item) => ({
    label: item,
    value: item,
    name: item,
  }));
