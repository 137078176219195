import { ReactNode } from 'react';

import { MQIcon, MQButton, MQTypo, MQTooltip } from '@mentorcliq/ui';

import ROUTES from 'routes';

import MQNav from 'modules/MQNav';

import AppFormattedNumber from 'formatters/AppFormattedNumber';

import AppLink from 'router/AppLink';

import EmailLink from 'components/EmailLink';

interface ButtonElementProps {
  testId: string;
  callback: () => void;
}

interface LinkElementProps {
  to: string;
  action?: () => void;
}

export const MQ_RICH_FORMATING_ELEMENTS = {
  viewParticipants: (programId: string) => (chunks: ReactNode[]) => (
    <AppLink
      to={ROUTES.viewParticipants.convert({
        path: {
          programId,
        },
      })}
    >
      {chunks}
    </AppLink>
  ),
  article:
    ({ to }: LinkElementProps) =>
    (chunks: ReactNode[]) => (
      <AppLink
        to={ROUTES.cliqipedia.convert({
          search: {
            article: to,
          },
        })}
        target="_blank"
      >
        {chunks}
      </AppLink>
    ),
  navLink:
    ({ to }: LinkElementProps) =>
    (chunks: ReactNode[]) => (
      <MQNav.Link as={AppLink} to={to} underline>
        {chunks}
      </MQNav.Link>
    ),
  navLinkBlank:
    ({ to }: LinkElementProps) =>
    (chunks: ReactNode[]) => (
      <MQNav.Link as={AppLink} to={to} target="_blank" underline>
        {chunks}
      </MQNav.Link>
    ),
  subLinkButton:
    ({ testId, callback }: ButtonElementProps) =>
    (chunks: ReactNode[]) => (
      <MQButton
        dataTestId={testId}
        onClick={callback}
        variant="sub-action--tertiary"
        endIcon={<MQIcon.Svg icon="external-link" />}
      >
        {chunks}
      </MQButton>
    ),
  linkButton:
    ({ testId, callback }: ButtonElementProps) =>
    (chunks: ReactNode[]) => (
      <MQButton variant="link" dataTestId={testId} onClick={callback}>
        {chunks}
      </MQButton>
    ),
  navLinkWithAction:
    ({ to, action }: LinkElementProps) =>
    (chunks: ReactNode[]) => (
      <MQNav.Link as={AppLink} onClick={action} to={to} underline>
        {chunks}
      </MQNav.Link>
    ),
  emailLink: (email: string) => (chunks: ReactNode) => <EmailLink email={email} label={chunks} />,
  manageMatchesPendingLink: (programId: number) => (chunks: ReactNode[]) => (
    <AppLink
      to={ROUTES.manageMatchesPending.convert({
        path: {
          programId,
        },
      })}
    >
      {chunks}
    </AppLink>
  ),
  linkBlank: (link: string) => (chunks: ReactNode[]) => (
    <a href={link} target="_blank" rel="noreferrer">
      {chunks}
    </a>
  ),
  externalLink:
    ({ to }: LinkElementProps) =>
    (chunks: ReactNode) => (
      <MQNav.Link
        href={to}
        target="_blank"
        rel="noreferrer"
        endIcon={<MQIcon.Svg icon="external-link" />}
        underline
        truncate
      >
        {chunks}
      </MQNav.Link>
    ),
  quickStartButton: (chunks: ReactNode[]) => (
    <MQNav.Link
      dataTestId="learning-lab-preview-description-quick-start-link"
      to={ROUTES.mentorLabQuickStart.data.path}
      as={AppLink}
      inline
      compact
    >
      {chunks}
    </MQNav.Link>
  ),
  masterClassButton: (chunks: ReactNode[]) => (
    <MQNav.Link
      dataTestId="learning-lab-preview-description-master-class-link"
      to={ROUTES.mentorLabMasterClass.data.path}
      as={AppLink}
      inline
      compact
    >
      {chunks}
    </MQNav.Link>
  ),
  addIntelligentMatchingLink: (programId: number) => (chunks: ReactNode) => (
    <MQNav.Link
      dataTestId="past-intelligent-match-empty-state-schedule"
      as={AppLink}
      to={ROUTES.intelligentMatchingAdd.convert({
        path: {
          programId: +programId,
        },
      })}
      underline
    >
      {chunks}
    </MQNav.Link>
  ),
  format2DigitNumberWithOutSign: (count: number) => (
    <AppFormattedNumber
      number={count}
      options={{
        maximumFractionDigits: 2,
      }}
    />
  ),
  format2DigitNumberWithSign: (count: number) => (
    <AppFormattedNumber
      number={count}
      options={{
        maximumFractionDigits: 2,
        signDisplay: 'always',
      }}
    />
  ),
  format2DigitNumberWithUSD: (count: number) => (
    <AppFormattedNumber
      number={count}
      options={{
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'USD',
      }}
    />
  ),
  format1DigitNumberWithUSD: (count: number) => (
    <AppFormattedNumber
      number={count}
      options={{
        notation: 'compact',
        maximumFractionDigits: 1,
        style: 'currency',
        currency: 'USD',
      }}
    />
  ),
  formatPercentage: (count: number) => (
    <AppFormattedNumber
      number={count / 100}
      options={{
        style: 'percent',
      }}
    />
  ),
  clearFilters:
    ({ testId, callback }: ButtonElementProps) =>
    (chunks: ReactNode[]) => (
      <MQButton dataTestId={testId} onClick={callback} variant="link">
        {chunks}
      </MQButton>
    ),
  dottedWithHelpText: (overlay: ReactNode) => (chunks: ReactNode[]) => (
    <MQTooltip placement="bottom" overlay={overlay}>
      <MQTypo.Text size="auto" decoration="dotted" underline>
        {chunks}
      </MQTypo.Text>
    </MQTooltip>
  ),
  highlightedWithHelpText: (overlay: ReactNode) => (chunks: ReactNode[]) => (
    <MQTooltip placement="bottom" overlay={overlay}>
      <MQTypo.Text size="auto" decoration="dotted" variant="indigo" tag="strong" underline>
        {chunks}
      </MQTypo.Text>
    </MQTooltip>
  ),
};
