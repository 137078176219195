import './style.scss';

import { FC } from 'react';

import classesNames from 'classnames';

import { MQFormCheckProps } from '../../types';

export const MQFormRadio: FC<MQFormCheckProps> = ({
  dataTestId,
  disabled,
  labelHtml = '',
  label = '',
  description = '',
  html = '',
  isValid,
  isInvalid,
  className,
  id,
  name,
  value,
  required,
  onChange,
  inline = false,
  ...props
}) => (
  <div {...props} className={classesNames('mq-form-radio', className, { inline })}>
    <input
      {...props}
      data-testid={dataTestId || `mq-form-radio-${name}-${id}`}
      value={value}
      name={name}
      type="radio"
      onClick={(e) => {
        if (disabled || !onChange) {
          e.stopPropagation();
        }
      }}
      disabled={disabled}
      onChange={(e) => (!disabled && onChange ? onChange(e) : e.stopPropagation())}
      className={classesNames('mq-form-radio__input', { ['is-valid']: isValid }, { ['is-invalid']: isInvalid })}
      id={id}
    />
    <label className="mq-form-radio__label" htmlFor={id}>
      {label && <span className="mq-form-radio__title">{label}</span>}
      {labelHtml && <span className="mq-form-radio__title" dangerouslySetInnerHTML={{ __html: labelHtml }} />}
      {required && <span className="mq-form-radio__required">*</span>}
    </label>
    {description && <span className="mq-form-radio__description">description</span>}
    {html && <div className="mq-form-radio__description" dangerouslySetInnerHTML={{ __html: html }} />}
  </div>
);

export default MQFormRadio;
