import { PermissionsEnum, FeatureTypeEnum, PerRolePermissions, FeatureStateDto } from '@mentorcliq/storage';

export enum ProviderTypesEnum {
  VpsEnabled = 'VPS_ENABLED',
  AccountEditEnabled = 'ACCOUNT_EDIT_ENABLED',
  LabsEnabled = 'LABS_ENABLED',
  ProfilePictureEnabled = 'PROFILE_PICTURE_ENABLED',
}

export interface AccessPermissionsParamsDto {
  value: PermissionsEnum[];
  strict?: boolean;
  exist?: boolean;
}

export interface AccessFeaturesParamsDto {
  value: FeatureTypeEnum[];
  strict?: boolean;
  exist?: boolean;
}

export interface AccessProvidersParamsDto {
  value: ProviderTypesEnum[];
  strict?: boolean;
  exist?: boolean;
}

export interface AccessProgramParamsDto {
  value: number[];
  strict?: boolean;
  exist?: boolean;
}

export interface AccessDraftProgramsParamsDto {
  value: number[];
  strict?: boolean;
  exist?: boolean;
}

export interface AccessParamsDto {
  permissions?: AccessPermissionsParamsDto;
  features?: AccessFeaturesParamsDto;
  providers?: AccessProvidersParamsDto;
  programs?: AccessProgramParamsDto;
  draftPrograms?: AccessDraftProgramsParamsDto;
  strict?: boolean;
}

export interface AccessMetaDto {
  permissions: PerRolePermissions;
  adminMode: boolean;
  features: FeatureStateDto[];
  vpsEnabled: boolean;
  profilePictureEnabled: boolean;
  labsEnabled: boolean;
  accountEditEnabled: boolean;
  programs: number[];
  draftPrograms: number[];
}
