import { FeatureTypeEnum, PermissionsEnum } from '@mentorcliq/storage';

import { ProviderTypesEnum } from 'types/access';
import { LayoutSizeTypes } from 'types/route';

import {
  PRIVATE_ROUTES_LAYOUT_ITEMS,
  PRIVATE_ROUTES_LAYOUT_WITH_BANNER_ITEMS,
  ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
} from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  reports: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        permissions: {
          value: [PermissionsEnum.ViewRhr],
        },
      },
    },
    data: {
      path: `/reports`,
    },
  }),
  reportsROICalculator: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_WITH_BANNER_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        features: {
          value: [FeatureTypeEnum.RoiReport],
        },
        permissions: {
          value: [PermissionsEnum.RoiCalculator],
        },
        strict: true,
      },
    },
    data: {
      path: `/reports/roi-calculator`,
      exact: true,
    },
  }),
  reportsROICalculatorProfileFieldSummary: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_WITH_BANNER_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        features: {
          value: [FeatureTypeEnum.RoiReport],
        },
        permissions: {
          value: [PermissionsEnum.ViewRoiProfileFieldsSummary],
        },
        strict: true,
      },
    },
    data: {
      path: `/reports/roi-calculator/profile-field-summary`,
      exact: true,
    },
  }),
  roiTrackingReport: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        permissions: {
          value: [PermissionsEnum.ViewRoiTrackingResults],
        },
        features: {
          value: [FeatureTypeEnum.RoiReport],
        },
        strict: true,
      },
    },
    data: {
      path: `/reports/roi-tracking-report`,
      exact: true,
    },
  }),
  learningLabReport: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        permissions: {
          value: [PermissionsEnum.ViewLearningLabReport],
        },
        providers: {
          value: [ProviderTypesEnum.LabsEnabled],
        },
        strict: true,
      },
    },
    data: {
      path: `/reports/learning-lab-report`,
      exact: true,
    },
  }),
  peopleReport: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        permissions: {
          value: [PermissionsEnum.ManageCustomerUsers],
        },
        strict: true,
      },
    },
    data: {
      path: `/reports/people-tracking-report`,
      exact: true,
    },
  }),
  platformReports: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        permissions: {
          value: [PermissionsEnum.ExportPlatformData],
        },
      },
    },
    data: {
      path: `/reports/platform-report`,
      exact: true,
    },
  }),
  mentorFlixReport: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        permissions: {
          value: [PermissionsEnum.ViewMentorFlixReport],
        },
        features: {
          value: [FeatureTypeEnum.MentorFlix],
        },
        strict: true,
      },
    },
    data: {
      path: `/reports/mentorflix`,
      exact: true,
    },
  }),
  helpdeskReport: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        permissions: {
          value: [PermissionsEnum.ViewContentHelpdeskReport],
        },
        features: {
          value: [FeatureTypeEnum.ContentHelpdesk],
        },
        strict: true,
      },
    },
    data: {
      path: `/reports/helpdesk`,
      exact: true,
    },
  }),
  platformAdvancedReports: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        permissions: {
          value: [PermissionsEnum.ManageReportBuilder],
        },
        features: {
          value: [FeatureTypeEnum.ReportBuilder],
        },
      },
    },
    data: {
      path: `/reports/advanced-reports`,
    },
  }),
  resourceLibraryReport: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        features: {
          value: [FeatureTypeEnum.ResourceLibrary],
        },
        permissions: {
          value: [PermissionsEnum.ManagePlatformResources],
        },
        strict: true,
      },
    },
    data: {
      path: `/reports/resource-library`,
      exact: true,
    },
  }),
  platformRelationshipExceptionReport: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        permissions: {
          value: [PermissionsEnum.ViewRhr],
        },
      },
    },
    data: {
      path: `/reports/relationship-exception`,
      exact: true,
    },
  }),
  programLogsReport: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        permissions: {
          value: [PermissionsEnum.ViewProgramLogs],
        },
      },
    },
    data: {
      path: `/reports/program-logs`,
      exact: true,
    },
  }),
  aiAssistantLogsReport: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        permissions: {
          value: [PermissionsEnum.ViewMqAiLogs],
        },
      },
    },
    data: {
      path: `/reports/ai-assistant-logs`,
      exact: true,
    },
  }),
};
