import './style.scss';

import { FC, useMemo } from 'react';

import { MQAiTypeEnum, PLATFORM_SUPPORT_EMAIL_ADDRESS } from '@mentorcliq/storage';
import { MQIcon, MQImage, MQTypo } from '@mentorcliq/ui';

import ROUTES from 'routes';

import { STATIC_ASSETS } from 'definitions/assets';
import { APP_ENV_CONFIGS } from 'definitions/configs';
import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import { generateLink } from 'helpers/routes';

import { useAppIntl } from 'hooks/useAppIntl';

import MQForm from 'modules/MQForm';
import MQGrid from 'modules/MQGrid';
import MQNav from 'modules/MQNav';

import { MQ_RICH_FORMATING_ELEMENTS } from 'formatters/AppFormattedElements';
import AppFormattedMessage from 'formatters/AppFormattedMessage';

import { ADMIN_AI_ARTICLES, CLIQ_AI_ARTICLES } from './Addons/definitions';

interface WelcomeWindowProps {
  type: MQAiTypeEnum;
  next: () => void;
}

const WelcomeWindow: FC<WelcomeWindowProps> = ({ next, type }) => {
  const intl = useAppIntl();

  const assistantTypeLabel = useMemo(
    () =>
      type === MQAiTypeEnum.AdminAi
        ? intl.formatMessage(APP_GLOBAL_MESSAGES.adminAiLabel)
        : intl.formatMessage(APP_GLOBAL_MESSAGES.cliqAiLabel),
    [intl, type],
  );

  const assistantArticles = useMemo(
    () => (type === MQAiTypeEnum.AdminAi ? ADMIN_AI_ARTICLES : CLIQ_AI_ARTICLES),
    [type],
  );

  return (
    <aside className="welcome-window">
      <div className="welcome-window__header">
        <MQTypo.Title type="h5">
          <MQTypo.Formatter prefix={<MQIcon.Svg size="lg" icon="sparkles" />}>{assistantTypeLabel}</MQTypo.Formatter>
        </MQTypo.Title>

        <MQTypo.Text size="5x">
          <AppFormattedMessage
            defaultMessage="Introducing {assistantTypeLabel} - your personal mentoring assistant and chat bot. How can we help you today?"
            description="[AiAssistant.WelcomeWindow] Header description"
            id="ai.assistant.welcome.window.header.description"
            values={{
              assistantTypeLabel,
            }}
          />
        </MQTypo.Text>

        <MQForm.Input
          onClick={next}
          placeholder={intl.formatMessage({
            defaultMessage: 'Ask a question...',
            description: '[AiAssistant.WelcomeWindow] Placeholder text',
            id: 'ai.assistant.welcome.window.header.placeholder.text',
          })}
        />
      </div>
      <div className="welcome-window__body">
        <MQNav.Link
          className="welcome-window__body-item"
          href={ROUTES.cliqipedia.convert({
            search: {
              article: APP_ENV_CONFIGS.supportPath,
            },
          })}
          target="_blank"
          rel="noreferrer"
        >
          <MQGrid.Row>
            <MQGrid.Col lg={4}>
              <MQImage
                src={STATIC_ASSETS.images.cliqAi.cliQiPedia}
                width="100%"
                height="100%"
                alt={intl.formatMessage({
                  defaultMessage: 'Access the CliQipedia image',
                  description: '[AiAssistant.WelcomeWindow] Access the CliQipedia image text',
                  id: 'ai.assistant.welcome.window.header.access.cliqipedia.image',
                })}
              />
            </MQGrid.Col>
            <MQGrid.Col lg={8}>
              <div className="welcome-window__body-item__description">
                <MQTypo.Title type="h5" bold>
                  <AppFormattedMessage
                    defaultMessage="Access the CliQiPedia for more help articles"
                    description="[AiAssistant.WelcomeWindow] Access the CliQiPedia item header"
                    id="ai.assistant.welcome.window.access.cliqipedia.item.header"
                  />
                </MQTypo.Title>
                <MQTypo.Paragraph>
                  <AppFormattedMessage
                    defaultMessage="Learn about the MentorcliQ software and find articles, videos, and how-to tips to support you throughout your mentoring journey."
                    description="[AiAssistant.WelcomeWindow] Access the CliQiPedia item description"
                    id="ai.assistant.welcome.window.access.cliqipedia.item.header.description"
                  />
                </MQTypo.Paragraph>
              </div>
            </MQGrid.Col>
          </MQGrid.Row>
        </MQNav.Link>
        <MQTypo.Text size="3x">
          <AppFormattedMessage
            defaultMessage="Top Suggestions"
            description="[AiAssistant.WelcomeWindow] Top Suggestions header"
            id="ai.assistant.welcome.window.top.suggestions.header"
          />
        </MQTypo.Text>
        <MQGrid.FlexBox direction="column">
          {assistantArticles.map((article, index) => (
            <MQTypo.Paragraph key={article.link}>
              <AppFormattedMessage
                {...article.name}
                values={{
                  index: index + 1,
                  a: MQ_RICH_FORMATING_ELEMENTS.linkBlank(
                    ROUTES.cliqipedia.convert({
                      search: {
                        article: article.link,
                      },
                    }),
                  ),
                }}
              />
            </MQTypo.Paragraph>
          ))}
        </MQGrid.FlexBox>
        <MQNav.Link
          href={generateLink({
            email: PLATFORM_SUPPORT_EMAIL_ADDRESS,
          })}
          variant="secondary"
          dataTestId="ai-assistant-contact-support"
          aria-label="Contact Support"
        >
          <AppFormattedMessage
            defaultMessage="Contact Support"
            description="[AiAssistant.WelcomeWindow] Contact Support button"
            id="ai.assistant.welcome.window.contact.support.button"
          />
        </MQNav.Link>
      </div>
    </aside>
  );
};

export default WelcomeWindow;
