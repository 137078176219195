import './style.scss';

import { FC, ReactNode, useCallback, useEffect, useState } from 'react';

import { MQIcon, MQButton } from '@mentorcliq/ui';

import { useAppScroll } from 'hooks/useAppScroll';

interface AppScrollTopProps {
  icon?: ReactNode;
}

const AppScrollTop: FC<AppScrollTopProps> = ({ icon }) => {
  const scroll = useAppScroll();
  const [isShowScrollToTop, setIsShowScrollToTop] = useState(false);

  const onScroll = useCallback(() => {
    if (window.scrollY > 60) {
      setIsShowScrollToTop(true);
    } else {
      setIsShowScrollToTop(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  const scrollToTop = () => {
    scroll.trigger();
  };

  if (isShowScrollToTop) {
    return (
      <div className="app-scroll-top">
        <MQButton
          dataTestId="scroll-top"
          onClick={scrollToTop}
          startIcon={icon || <MQIcon.Svg size="3x" icon="arrow-circle-up" />}
          shape="square"
        />
      </div>
    );
  }

  return null;
};

export default AppScrollTop;
