import { ProviderTypesEnum } from 'types/access';

import {
  ROUTE_PREVIEW_AUTHENTICATED_ACCESS,
  PREVIEW_ADMIN_ROUTES_LAYOUT_ITEMS,
  PREVIEW_ROUTES_LAYOUT_ITEMS,
  ROUTE_PREVIEW_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_ENROLLED_ACCESS,
} from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  previewMainPage: createRoute({
    params: {
      layoutTypes: PREVIEW_ADMIN_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_PREVIEW_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_ENROLLED_ACCESS,
    },
    data: {
      path: '/preview/',
    },
  }),
  previewDashboard: createRoute({
    params: {
      layoutTypes: PREVIEW_ADMIN_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_PREVIEW_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_ENROLLED_ACCESS,
    },
    data: {
      path: '/preview/user-dashboard',
    },
  }),
  previewAdmin: createRoute({
    params: {
      layoutTypes: PREVIEW_ADMIN_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_PREVIEW_AUTHENTICATED_ACCESS,
    },
    data: {
      path: `/preview/user-dashboard/mycliq/admin`,
      exact: true,
    },
  }),
  previewMyCliQ: createRoute({
    params: {
      layoutTypes: PREVIEW_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_PREVIEW_AUTHENTICATED_ACCESS,
    },
    data: {
      path: `/preview/user-dashboard/mycliq`,
      exact: true,
    },
  }),
  previewPrograms: createRoute({
    params: {
      layoutTypes: PREVIEW_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_PREVIEW_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_ENROLLED_ACCESS,
    },
    data: {
      path: `/preview/user-dashboard/programs`,
      exact: true,
    },
  }),
  previewTimeline: createRoute({
    params: {
      layoutTypes: PREVIEW_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_PREVIEW_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_ENROLLED_ACCESS,
    },
    data: {
      path: '/preview/user-dashboard/timeline',
      exact: true,
    },
  }),
  previewDevelopmentReports: createRoute({
    params: {
      layoutTypes: PREVIEW_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_PREVIEW_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_ENROLLED_ACCESS,
    },
    data: {
      path: `/preview/user-dashboard/development-reports`,
    },
  }),
  previewProfile: createRoute({
    params: {
      layoutTypes: PREVIEW_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_PREVIEW_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_ENROLLED_ACCESS,
    },
    data: {
      path: `/preview/user-dashboard/profile`,
    },
  }),
  previewProfileVps: createRoute({
    params: {
      layoutTypes: [],
      types: ROUTE_PREVIEW_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_ENROLLED_ACCESS,
      access: {
        providers: {
          value: [ProviderTypesEnum.VpsEnabled],
        },
      },
    },
    data: {
      path: `/preview/user-dashboard/profile/vps`,
      exact: true,
    },
  }),
  previewProfileVpsQuestions: createRoute({
    params: {
      layoutTypes: [],
      types: ROUTE_PREVIEW_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_ENROLLED_ACCESS,
      access: {
        providers: {
          value: [ProviderTypesEnum.VpsEnabled],
        },
      },
    },
    data: {
      path: `/preview/user-dashboard/profile/vps/questions`,
      exact: true,
    },
  }),
  previewProfileVpsComplete: createRoute({
    params: {
      layoutTypes: [],
      types: ROUTE_PREVIEW_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_ENROLLED_ACCESS,
      access: {
        providers: {
          value: [ProviderTypesEnum.VpsEnabled],
        },
      },
    },
    data: {
      path: `/preview/user-dashboard/profile/vps/complete`,
      exact: true,
    },
  }),
};
