import { PermissionsEnum } from '@mentorcliq/storage';

import { LayoutSizeTypes } from 'types/route';

import {
  PRIVATE_ROUTES_LAYOUT_ITEMS,
  PRIVATE_ROUTES_LAYOUT_WITH_SCROLLER_ITEMS,
  ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
} from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  helpdesk: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_WITH_SCROLLER_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        permissions: {
          value: [PermissionsEnum.ManageContentHelpdeskData],
        },
      },
    },
    data: {
      path: '/helpdesk',
    },
  }),
  helpdeskData: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_WITH_SCROLLER_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        permissions: {
          value: [PermissionsEnum.ManageContentHelpdeskData],
        },
      },
    },
    data: {
      path: '/helpdesk',
      exact: true,
    },
  }),
  suggestResource: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        permissions: {
          value: [PermissionsEnum.ManageContentHelpdeskData],
        },
      },
    },
    data: {
      path: '/helpdesk/suggest-resource',
      exact: true,
    },
  }),
};
