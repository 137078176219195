import { FC, ReactNode, Suspense, useMemo } from 'react';

import { MQLoader } from '@mentorcliq/ui';

import ActivityWrapper from './ActivityWrapper';
import BrandingWrapper from './BrandingWrapper';
import ConfigWrapper from './ConfigWrapper';
import GoogleAnalyticsWrapper from './GoogleAnalyticsWrapper';
import GuideWrapper from './GuideWrapper';
import LocaleWrapper from './LocaleWrapper';
import PlatformWrapper from './PlatformWrapper';
import PreviewWrapper from './PreviewWrapper';
import SocketWrapper from './SocketWrapper';

export const APP_WRAPPERS = {
  socket: SocketWrapper,
  branding: BrandingWrapper,
  config: ConfigWrapper,
  locale: LocaleWrapper,
  platform: PlatformWrapper,
  preview: PreviewWrapper,
  activity: ActivityWrapper,
  guide: GuideWrapper,
  analytics: GoogleAnalyticsWrapper,
};

type WrapperType = keyof typeof APP_WRAPPERS;

interface Props {
  children?: ReactNode;
  wrappers?: WrapperType[];
}

const AppWrapper: FC<Props> = ({
  wrappers = ['guide', 'socket', 'preview', 'config', 'platform', 'branding', 'activity', 'locale', 'analytics'],
  children,
}) => {
  const WrapperComponents = useMemo(
    () =>
      wrappers.reduce((Acc, wrapper) => {
        const WrapperComponent = APP_WRAPPERS[wrapper];

        return <WrapperComponent>{Acc}</WrapperComponent>;
      }, children),
    [wrappers, children],
  );

  return <Suspense fallback={<MQLoader.Spinner />}>{WrapperComponents}</Suspense>;
};

export default AppWrapper;
