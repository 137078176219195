import { defineMessage } from 'react-intl';

import { SUPPORT_ARTICLES_CONFIGS } from '__mocks/zendesk';

export const ADMIN_AI_ARTICLES = [
  {
    link: SUPPORT_ARTICLES_CONFIGS.createProgram,
    name: defineMessage({
      defaultMessage: '{index}. <a>How do I create a new mentoring program?</a>',
      description: '[AdminAiArticles] Create new mentoring program link text',
      id: 'admin.ai.articles.create.mentoring.program',
    }),
  },
  {
    link: SUPPORT_ARTICLES_CONFIGS.configureMatchScore,
    name: defineMessage({
      defaultMessage: '{index}. <a>What are the best practices for setting matching criteria?</a>',
      description: '[AdminAiArticles] Setting matching criteria link text',
      id: 'admin.ai.articles.setting.matching.criteria',
    }),
  },
  {
    link: SUPPORT_ARTICLES_CONFIGS.matchScoreAlgorithm,
    name: defineMessage({
      defaultMessage: '{index}. <a>What does the Match Score mean, and how is it calculated?</a>',
      description: '[AdminAiArticles] Match Score calculation link text',
      id: 'admin.ai.articles.match.score.calculation',
    }),
  },
];

export const CLIQ_AI_ARTICLES = [
  {
    link: SUPPORT_ARTICLES_CONFIGS.participantsEnroll,
    name: defineMessage({
      defaultMessage: '{index}. <a>How do I join a mentoring program?</a>',
      description: '[CliQAiArticles] Join mentoring program link text',
      id: 'cliq.ai.articles.join.mentoring.program',
    }),
  },
  {
    link: SUPPORT_ARTICLES_CONFIGS.requestMatch,
    name: defineMessage({
      defaultMessage: '{index}. <a> How do I find a mentor/mentee that aligns with my goals?</a>',
      description: '[CliQAiArticles] Find mentor/mentee link text',
      id: 'cliq.ai.articles.find.mentor.mentee',
    }),
  },
  {
    link: SUPPORT_ARTICLES_CONFIGS.collaborationTools,
    name: defineMessage({
      defaultMessage: '{index}. <a> How do I make the most of my mentoring relationships?</a>',
      description: '[CliQAiArticles] Make relationships link text',
      id: 'cliq.ai.articles.make.relationships',
    }),
  },
];
