import { DateRangeTypesEnum, ProfileSectionTypes } from '@mentorcliq/storage';
import { defineMessage } from 'react-intl';

import { APP_GLOBAL_MESSAGES } from './messages';

export const DATE_RANGE_TYPES_LABELS = {
  [DateRangeTypesEnum.LAST_1_MONTH]: {
    label: defineMessage({
      description: 'dateRange.last1Months',
      defaultMessage: 'Last 1 month',
      id: 'date.range.last.one.month',
    }),
    type: DateRangeTypesEnum.LAST_1_MONTH,
  },
  [DateRangeTypesEnum.LAST_3_MONTHS]: {
    label: defineMessage({
      description: 'dateRange.last3Months',
      defaultMessage: 'Last 3 months',
      id: 'date.range.last.three.month',
    }),
    type: DateRangeTypesEnum.LAST_3_MONTHS,
  },
  [DateRangeTypesEnum.LAST_6_MONTHS]: {
    label: defineMessage({
      description: 'dateRange.last6Months',
      defaultMessage: 'Last 6 months',
      id: 'date.range.last.six.month',
    }),
    type: DateRangeTypesEnum.LAST_6_MONTHS,
  },
  [DateRangeTypesEnum.LAST_12_MONTHS]: {
    label: defineMessage({
      description: 'dateRange.last12Months',
      defaultMessage: 'Last 12 months',
      id: 'date.range.last.twelve.month',
    }),
    type: DateRangeTypesEnum.LAST_12_MONTHS,
  },
  [DateRangeTypesEnum.CUSTOM]: {
    label: defineMessage({
      description: 'dateRange.custom',
      defaultMessage: 'Custom',
      id: 'date.range.custom',
    }),
    type: DateRangeTypesEnum.CUSTOM,
  },
};

export const DATE_RANGE_MONTHS = {
  [DateRangeTypesEnum.LAST_12_MONTHS]: 12,
  [DateRangeTypesEnum.LAST_1_MONTH]: 1,
  [DateRangeTypesEnum.LAST_3_MONTHS]: 3,
  [DateRangeTypesEnum.LAST_6_MONTHS]: 6,
  [DateRangeTypesEnum.CUSTOM]: 0,
};

export const DRAFT_SUGGESTION_LABELS = [
  {
    label: defineMessage({
      defaultMessage: 'First',
      description: '[DraftSuggestion] First option',
      id: 'draft.suggestions.first.option',
    }),
    value: 'first',
  },
  {
    label: defineMessage({
      defaultMessage: 'Second',
      description: '[DraftSuggestion] Second option',
      id: 'draft.suggestions.second.option',
    }),
    value: 'second',
  },
  {
    label: defineMessage({
      defaultMessage: 'Third',
      description: '[DraftSuggestion] Third option',
      id: 'draft.suggestions.third.option',
    }),
    value: 'third',
  },
];

export const ASYNC_STATUSES = {
  waiting: defineMessage({
    defaultMessage: 'Waiting…',
    description: '[AsyncProgress] status',
    id: 'async.progress.status.waiting',
  }),
  progress: defineMessage({
    defaultMessage: 'In progress',
    description: '[AsyncProgress] status',
    id: 'async.progress.status.in.progress',
  }),
  done: defineMessage({
    defaultMessage: 'Done!',
    description: '[AsyncProgress] status',
    id: 'async.progress.status.done',
  }),
  processing: defineMessage({
    defaultMessage: 'Processing',
    description: '[AsyncProgress] status',
    id: 'async.progress.status.processing',
  }),
};

export const PROFILE_DISPLAY_SECTIONS_MAP = {
  [ProfileSectionTypes.NAME]: {
    label: APP_GLOBAL_MESSAGES.profileNameSectionLabel,
    type: ProfileSectionTypes.NAME,
  },
  [ProfileSectionTypes.HEADLINE]: {
    label: APP_GLOBAL_MESSAGES.profileHeadlineSectionLabel,
    type: ProfileSectionTypes.HEADLINE,
  },
  [ProfileSectionTypes.SUB_HEADLINE]: {
    label: APP_GLOBAL_MESSAGES.profileSubHeadlineSectionLabel,
    type: ProfileSectionTypes.SUB_HEADLINE,
  },
  [ProfileSectionTypes.DETAILS]: {
    label: APP_GLOBAL_MESSAGES.profileDetailsSectionLabel,
    type: ProfileSectionTypes.DETAILS,
  },
  [ProfileSectionTypes.INFO]: {
    label: APP_GLOBAL_MESSAGES.profileInfoSectionLabel,
    type: ProfileSectionTypes.INFO,
  },
  [ProfileSectionTypes.HIDDEN]: {
    label: APP_GLOBAL_MESSAGES.profileHiddenSectionLabel,
    type: ProfileSectionTypes.HIDDEN,
  },
};
