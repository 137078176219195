export const prepareKeys = <T = unknown>(items: T[]) => {
  let count = 0;

  return items.map((item) => ({
    item,
    key: String(++count),
  }));
};

export const generateUrl = (link: string) => {
  try {
    return new URL(link).href;
  } catch {
    return window.location.origin;
  }
};

export const intersect = (first: unknown[], second: unknown[]) => first.some((v) => second.includes(v));

export function arrayMoveMutable<T = unknown>(array: T[], fromIndex: number, toIndex: number) {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
}

export function arrayMoveImmutable<T = unknown>(array: T[], fromIndex: number, toIndex: number) {
  const newArray = [...array];
  arrayMoveMutable(newArray, fromIndex, toIndex);
  return newArray;
}
