import './style.scss';

import { FC, useCallback, useMemo } from 'react';

import { ChatRoomDto, getChatLoadingStateSelector, getChatRoomMessagesThunk } from '@mentorcliq/storage';
import { MQAnimate, MQBadge, MQIcon, MQButton, MQTypo } from '@mentorcliq/ui';

import ROUTES from 'routes';

import history from 'helpers/history';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppSocket } from 'hooks/useAppSocket';

import MQAvatar from 'modules/MQAvatar';

import AppFormattedList from 'formatters/AppFormattedList';

import ChatMessages from '../ChatMessages';
import ChatToolbar from '../ChatToolbar';

interface ChatDialogProps {
  room: ChatRoomDto;
  ownerId: number;
  participantId: number;
  closeRoom: () => void;
  closeChat: () => void;
}

const ChatDialog: FC<ChatDialogProps> = ({ room, ownerId, participantId, closeRoom, closeChat }) => {
  const socket = useAppSocket();
  const dispatch = useAppDispatch();
  const chatLoadingState = useAppSelector(({ chat }) => getChatLoadingStateSelector(chat));

  const others = useMemo(
    () => room.match?.members.filter((item) => item.userId !== ownerId),
    [ownerId, room.match?.members],
  );

  const loadMore = useCallback(
    (id: number) => {
      if (room?.id && room?.hasNext !== false) {
        dispatch(
          getChatRoomMessagesThunk({
            roomId: room.id,
            messageId: id,
          }),
        );
      }
    },
    [dispatch, room?.id, room?.hasNext],
  );

  return (
    <div className="app-chat-dialog">
      <div className="app-chat-dialog__header">
        <div className="app-chat-dialog__header-info">
          <MQButton
            dataTestId="close-chat-room"
            startIcon={<MQIcon.Svg icon="left" />}
            variant="sub-action--tertiary"
            shape="square"
            onClick={() => {
              closeRoom();
            }}
          />
          {others && (
            <>
              <MQAvatar.Group
                items={others.map((item) => ({
                  imageUrl: item.picture,
                  title: item.name,
                }))}
                size="sm"
                max={2}
                overlap
              />
              <MQTypo.Text bold nowrap>
                <AppFormattedList list={others.map((item) => item.name)} />
              </MQTypo.Text>
            </>
          )}
        </div>
        <div className="app-chat-dialog__header-tools">
          {!!room.unreadMessagesCount && (
            <MQAnimate.Styles dependencies={room.unreadMessagesCount} animation="scale-up-center">
              <MQBadge.Info variant="warning" size="sm" circle>
                {room.unreadMessagesCount}
              </MQBadge.Info>
            </MQAnimate.Styles>
          )}
          <MQButton
            variant="tertiary"
            dataTestId="expand-chat"
            startIcon={<MQIcon.Svg icon="arrow-up-right-from-square" />}
            onClick={() => {
              history.mq.push(
                ROUTES.chat.convert({
                  search: {
                    matchId: room.match?.id,
                    participantId,
                  },
                }),
              );
            }}
          />
          <MQButton
            variant="tertiary"
            dataTestId="close-chat"
            startIcon={<MQIcon.Svg icon="times" />}
            onClick={() => closeChat()}
          />
        </div>
      </div>
      <div className="app-chat-dialog__body">
        {room.match && (
          <ChatMessages
            ownerId={ownerId}
            matchId={room.match.id}
            messages={room.messages || []}
            markAsRead={(data) => {
              socket.read?.(data);
            }}
            loadMore={loadMore}
            isLoading={chatLoadingState.isPending}
            unreadMessagesCount={room.unreadMessagesCount}
            readTime={room.readTime}
          />
        )}
      </div>
      <div className="app-chat-dialog__footer">
        {room.match && (
          <ChatToolbar
            matchId={room.match.id}
            sendMessage={(data) => {
              if (room.match?.id) {
                socket.send?.({
                  matchId: room.match.id,
                  authorId: ownerId,
                  message: data.message,
                  attachmentId: data.attachment?.id ?? null,
                });
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ChatDialog;
