import { FeatureTypeEnum } from '@mentorcliq/storage';

import { PRIVATE_ROUTES_LAYOUT_ITEMS, ROUTE_COMMON_ACCESS } from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  support: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_COMMON_ACCESS,
      access: {
        features: {
          value: [FeatureTypeEnum.Support],
        },
      },
    },
    data: {
      path: `/support`,
      exact: true,
    },
  }),
};
