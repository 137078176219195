import './style.scss';

import { FC, useMemo } from 'react';

import { MQAnimate, MQIcon, MQButton, MQTooltip } from '@mentorcliq/ui';
import classNames from 'classnames';

import ROUTES from 'routes';

import { useAppIntl } from 'hooks/useAppIntl';
import { useAppLayout } from 'hooks/useAppLayout';
import { useAppRouter } from 'hooks/useAppRouter';

import MQSidebar from 'modules/MQSidebar';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

import Dashboard from './Dashboard';
import Platform from './Platform';
import Preview from './Preview';
import Program from './Program';
import Reports from './Reports';

const SIDEBARS = [
  {
    component: <Platform />,
    paths: [ROUTES.configure.data.path],
  },
  {
    component: <Reports />,
    paths: [ROUTES.reports.data.path],
  },
  {
    component: <Program />,
    paths: [ROUTES.program.data.path, ROUTES.programWizardDraftEdit.data.path],
  },
  {
    component: <Preview />,
    paths: [ROUTES.previewMainPage.data.path],
  },
  {
    component: <Dashboard />,
    paths: [ROUTES.mainPage.data.path],
  },
];

interface Props {
  expanded: boolean;
  preview: boolean;
}

const AppSidebar: FC<Props> = ({ expanded, preview }) => {
  const intl = useAppIntl();
  const { route } = useAppRouter();
  const { setSidebarExpanded } = useAppLayout();

  const sidebar = useMemo(
    () => SIDEBARS.find((item) => item.paths.some((path) => route.data.path.includes(path))),
    [route],
  );

  return (
    <nav
      className={classNames(['app-sidebar', { expanded }])}
      aria-label={
        preview
          ? intl.formatMessage({
              defaultMessage: 'Preview sidebar',
              description: '[AppSidebar] Preview sidebar aria label',
              id: 'app.sidebar.preview.sidebar.aria.label',
            })
          : intl.formatMessage({
              defaultMessage: 'App sidebar',
              description: '[AppSidebar] App sidebar aria label',
              id: 'app.sidebar.app.sidebar.aria.label',
            })
      }
    >
      <div className="app-sidebar__toggle">
        <MQTooltip
          placement="right"
          overlay={
            expanded ? (
              <AppFormattedMessage
                defaultMessage="Collapse"
                description="[AppSidebar] Collapse tooltip"
                id="app.sidebar.collapse"
              />
            ) : (
              <AppFormattedMessage
                defaultMessage="Expand"
                description="[Expand] Expand tooltip"
                id="app.sidebar.expand"
              />
            )
          }
          triggers={['hover']}
        >
          <MQButton
            dataTestId="sidebar-toggle"
            className="app-sidebar__toggle-button"
            variant="tertiary"
            shape="circle"
            onClick={() => {
              setSidebarExpanded?.(!expanded);
            }}
            startIcon={expanded ? <MQIcon.Svg icon="minus" /> : <MQIcon.Svg icon="plus" />}
          />
        </MQTooltip>
      </div>
      <MQAnimate.Styles animation="slide-right" dependencies={sidebar?.paths}>
        <MQSidebar
          className="app-sidebar__nav"
          ariaLabel={
            preview
              ? intl.formatMessage({
                  defaultMessage: 'Preview sidebar nav',
                  description: '[AppSidebar] Preview sidebar nav aria label',
                  id: 'app.sidebar.preview.sidebar.nav.aria.label',
                })
              : intl.formatMessage({
                  defaultMessage: 'App sidebar nav',
                  description: '[AppSidebar] App sidebar nav aria label',
                  id: 'app.sidebar.app.sidebar.nav.aria.label',
                })
          }
        >
          {sidebar?.component}
        </MQSidebar>
      </MQAnimate.Styles>
    </nav>
  );
};

export default AppSidebar;
