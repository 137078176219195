import './style.scss';

import { FC, ReactNode, useEffect, useMemo } from 'react';

import { FeatureTypeEnum, PermissionsEnum } from '@mentorcliq/storage';
import classNames from 'classnames';

import { SUPPORT_KEYWORDS_CONFIGS } from '__mocks/zendesk';

import { searchArticles } from 'helpers/support';

import { useAppAuthState } from 'hooks/useAppAuthState';
import { useAppConfigs } from 'hooks/useAppConfigs';
import { useAppIntl } from 'hooks/useAppIntl';
import { useAppLayout } from 'hooks/useAppLayout';
import { useAppRouter } from 'hooks/useAppRouter';
import { useAppScroll } from 'hooks/useAppScroll';
import { useAppViewAs } from 'hooks/useAppViewAs';

import MQGrid from 'modules/MQGrid';

import AppAiAssistant from 'layout/AppAiAssistant';
import AppAlerts from 'layout/AppAlerts';
import AppChat from 'layout/AppChat';
import AppFooter from 'layout/AppFooter';
import AppHeader from 'layout/AppHeader';
import AppScrollTop from 'layout/AppScrollTop';
import AppSidebar from 'layout/AppSidebar';
import AppSupport from 'layout/AppSupport';
import CookiesBanner from 'layout/CookiesBanner';

import AppViewAs from 'components/AppViewAs';
import PermissionWrapper from 'components/PermissionWrapper';

interface AppLayoutProps {
  children?: ReactNode;
}

const AppLayout: FC<AppLayoutProps> = ({ children }) => {
  const intl = useAppIntl();
  const { viewingUserId } = useAppViewAs();
  const layout = useAppLayout();
  const router = useAppRouter();
  const { profile, mode } = useAppAuthState();
  const { privateConfigs } = useAppConfigs();
  const scroll = useAppScroll({
    force: true,
    behavior: 'auto',
  });

  const aiAssistantPermissions = useMemo(() => {
    if (mode.admin && !!privateConfigs.mqAiConfiguration?.enabledForAdmins) {
      return [PermissionsEnum.AccessDashboard];
    } else if (!mode.admin && !!privateConfigs.mqAiConfiguration?.enabledForParticipants) {
      return [];
    }

    return [PermissionsEnum.ManagePlatformAddons];
  }, [
    mode.admin,
    privateConfigs.mqAiConfiguration?.enabledForAdmins,
    privateConfigs.mqAiConfiguration?.enabledForParticipants,
  ]);

  useEffect(() => {
    const config = Object.values(SUPPORT_KEYWORDS_CONFIGS).find((item) =>
      item.locations.some((path) => path === router.route.data.path),
    );

    if (config?.keywords) {
      searchArticles(config.keywords);
    }
  }, [router.route.data.path]);

  useEffect(() => {
    if (router.location.pathname) {
      scroll.trigger();
    }
  }, [scroll, layout.wrapperId, router.location.pathname]);

  return (
    <div className="app-layout">
      {layout.showHeader && (
        <header
          className="app-layout__header"
          aria-label={
            router.requirements.preview
              ? intl.formatMessage({
                  defaultMessage: 'Preview header',
                  description: '[AppLayout] Preview header aria label',
                  id: 'app.layout.preview.header.aria.label',
                })
              : intl.formatMessage({
                  defaultMessage: 'App header',
                  description: '[AppLayout] App header aria label',
                  id: 'app.layout.app.header.aria.label',
                })
          }
        >
          <AppHeader preview={router.requirements.preview} />
        </header>
      )}
      <main
        className="app-layout__content"
        aria-label={
          router.requirements.preview
            ? intl.formatMessage({
                defaultMessage: 'Preview main',
                description: '[AppLayout] Preview main aria label',
                id: 'app.layout.preview.main.aria.label',
              })
            : intl.formatMessage({
                defaultMessage: 'App main',
                description: '[AppLayout] App main aria label',
                id: 'app.layout.app.main.aria.label',
              })
        }
      >
        {layout.showSidebar && (
          <div
            className={classNames('app-layout__sidebar', {
              expanded: layout.sidebarExpanded,
            })}
          >
            <AppSidebar expanded={layout.sidebarExpanded} preview={router.requirements.preview} />
          </div>
        )}
        <div id={layout.wrapperId} className="app-layout__wrapper">
          {layout.showBanner && (
            <div
              className={classNames('app-layout-banner', {
                sticky: layout.layoutBanner.sticky,
              })}
            >
              {layout.layoutBanner.node}
            </div>
          )}
          <MQGrid.Container size={layout.layoutSize}>
            <div className="app-layout__container">
              <div className="app-layout__body">
                {layout.showAlerts && <AppAlerts containerId="app-alerts-wrapper" />}
                {children}
              </div>
              {layout.showFooter && (
                <div className="app-layout__footer">
                  <AppFooter />
                </div>
              )}
            </div>
          </MQGrid.Container>
        </div>
      </main>
      {layout.showScroller && <AppScrollTop />}
      {layout.showViewAs && <AppViewAs participantId={viewingUserId} />}

      {layout.showSupport && (
        <PermissionWrapper features={{ value: [FeatureTypeEnum.Support] }}>
          <AppSupport name={profile?.name} email={profile?.email} />
        </PermissionWrapper>
      )}
      {layout.showChat && (
        <PermissionWrapper features={{ value: [FeatureTypeEnum.Chat] }}>
          <AppChat />
        </PermissionWrapper>
      )}
      {layout.showCookies && <CookiesBanner />}
      {layout.showAiAssistant && (
        <PermissionWrapper permissions={{ value: aiAssistantPermissions }}>
          <AppAiAssistant />
        </PermissionWrapper>
      )}
    </div>
  );
};

export default AppLayout;
