import './style.scss';

import { FC, ReactNode } from 'react';

import { MQ_KEYBOARD_EVENT_KEYS } from '@mentorcliq/ui';
import classNames from 'classnames';

export interface MQCardProviderProps {
  className?: string;
  children?: ReactNode;
  border?: 'light' | 'cover' | 'alt' | 'gray' | 'blue' | 'transparent' | 'gray-transparent';
  onClick?: () => void;
  fill?: boolean;
  dataTestId?: string;
  ariaLabel?: string;
}

const MQCardProvider: FC<MQCardProviderProps> = ({
  children,
  className,
  onClick,
  border,
  fill = false,
  dataTestId = 'mq-card',
  ariaLabel = 'Card',
}) => (
  <div
    data-testid={dataTestId}
    aria-label={onClick && ariaLabel}
    tabIndex={onClick ? 0 : -1}
    className={classNames(['mq-card', className, border, { stateless: !onClick, fill }])}
    onClick={onClick}
    onKeyDown={(e) => {
      if (MQ_KEYBOARD_EVENT_KEYS.EnterSpace.includes(e.key)) {
        onClick?.();
      }
    }}
  >
    {children}
  </div>
);

export default MQCardProvider;
