import {
  PRIVATE_ROUTES_LAYOUT_ITEMS,
  ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_ACCESS,
  ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_ACCESS,
} from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  verify: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_ACCESS,
    },
    data: {
      path: `/verify`,
    },
  }),
  verifyWelcome: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_ACCESS,
    },
    data: {
      path: `/verify/welcome`,
      exact: true,
    },
  }),
  verifyPerson: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_ACCESS,
    },
    data: {
      path: `/verify/person`,
      exact: true,
    },
  }),
  verifyProfile: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_ACCESS,
    },
    data: {
      path: `/verify/profile`,
      exact: true,
    },
  }),
  verifyProfilePicture: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_ACCESS,
    },
    data: {
      path: `/verify/profile-picture`,
      exact: true,
    },
  }),
  verifyVps: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_ACCESS,
    },
    data: {
      path: `/verify/vps`,
      exact: true,
    },
  }),
  verifyComplete: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_ACCESS,
    },
    data: {
      path: `/verify/complete`,
      exact: true,
    },
  }),
};
