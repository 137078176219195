import { LayoutSizeTypes } from 'types/route';

import {
  PRIVATE_ROUTES_LAYOUT_ITEMS,
  PRIVATE_ROUTES_LAYOUT_WITH_BANNER_ITEMS,
  ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
} from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  learningLab: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
      layoutSize: LayoutSizeTypes.lg,
    },
    data: {
      path: `/learning-lab`,
    },
  }),
  labsList: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
      layoutSize: LayoutSizeTypes.lg,
    },
    data: {
      path: `/learning-lab/labs-list`,
      exact: true,
    },
  }),
  mentorLab: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_WITH_BANNER_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
      layoutSize: LayoutSizeTypes.lg,
    },
    data: {
      path: `/learning-lab/mentor-lab`,
      exact: true,
    },
  }),
  mentorLabQuickStart: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
      layoutSize: LayoutSizeTypes.lg,
    },
    data: {
      path: `/learning-lab/mentor-lab/quick-start`,
      exact: true,
    },
  }),
  mentorLabMasterClass: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_WITH_BANNER_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
      layoutSize: LayoutSizeTypes.lg,
    },
    data: {
      path: `/learning-lab/mentor-lab/master-class`,
      exact: true,
    },
  }),
  inclusionLab: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_WITH_BANNER_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
      layoutSize: LayoutSizeTypes.lg,
    },
    data: {
      path: `/learning-lab/inclusion-lab`,
      exact: true,
    },
  }),
  inclusionLabQuickStart: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
      layoutSize: LayoutSizeTypes.lg,
    },
    data: {
      path: `/learning-lab/inclusion-lab/quick-start`,
      exact: true,
    },
  }),
  inclusionLabMasterClass: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_WITH_BANNER_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
      layoutSize: LayoutSizeTypes.lg,
    },
    data: {
      path: `/learning-lab/inclusion-lab/master-class`,
      exact: true,
    },
  }),
  leadershipLab: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_WITH_BANNER_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
    },
    data: {
      path: `/learning-lab/leadership-lab`,
      exact: true,
    },
  }),
  leadershipLabQuickStart: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
      layoutSize: LayoutSizeTypes.lg,
    },
    data: {
      path: `/learning-lab/leadership-lab/quick-start`,
      exact: true,
    },
  }),
  leadershipLabMasterClass: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_WITH_BANNER_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
      layoutSize: LayoutSizeTypes.lg,
    },
    data: {
      path: `/learning-lab/leadership-lab/master-class`,
      exact: true,
    },
  }),
  learningLabLive: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
      layoutSize: LayoutSizeTypes.lg,
    },
    data: {
      path: `/learning-lab/:lab/:type/live`,
      exact: true,
    },
  }),
};
