import { COMMON_ROUTES_LAYOUT_ITEMS, ROUTE_COMMON_ACCESS } from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  acceptMatchRequest: createRoute({
    params: {
      layoutTypes: COMMON_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_COMMON_ACCESS,
    },
    data: {
      path: `/match/:token/accept`,
      exact: true,
    },
  }),
  declineMatchRequest: createRoute({
    params: {
      layoutTypes: COMMON_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_COMMON_ACCESS,
    },
    data: {
      path: `/match/:token/decline`,
      exact: true,
    },
  }),
  matchRequestExpired: createRoute({
    params: {
      layoutTypes: COMMON_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_COMMON_ACCESS,
    },
    data: {
      path: `/match/expired`,
      exact: true,
    },
  }),
  matchRequestDeclined: createRoute({
    params: {
      layoutTypes: COMMON_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_COMMON_ACCESS,
    },
    data: {
      path: `/match/declined`,
      exact: true,
    },
  }),
  matchRequestConfirmed: createRoute({
    params: {
      layoutTypes: COMMON_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_COMMON_ACCESS,
    },
    data: {
      path: `/match/confirmed`,
    },
  }),
  nudgeLeaveRelationship: createRoute({
    params: {
      layoutTypes: COMMON_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_COMMON_ACCESS,
    },
    data: {
      path: `/match/leave/:token`,
      exact: true,
    },
  }),
  matchComparison: createRoute({
    params: {
      layoutTypes: COMMON_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_COMMON_ACCESS,
    },
    data: {
      path: `/match/:token/compatibility`,
      exact: true,
    },
  }),
};
