import { FeatureTypeEnum } from '@mentorcliq/storage';

import { LayoutSizeTypes } from 'types/route';

import {
  PRIVATE_ROUTES_LAYOUT_ITEMS,
  ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
} from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  matches: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
    },
    data: {
      path: `/matches`,
    },
  }),
  chat: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.md,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
      access: {
        features: {
          value: [FeatureTypeEnum.Chat],
        },
      },
    },
    data: {
      path: `/matches/chat`,
    },
  }),
  meeting: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
      access: {
        features: { value: [FeatureTypeEnum.Calendar] },
      },
    },
    data: {
      path: `/matches/meeting/match/:matchId/organizer/:enrollmentId`,
      exact: true,
    },
  }),
  goals: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
    },
    data: {
      path: `/matches/goals/:programId/:enrollmentId/:matchId`,
    },
  }),
  goalsList: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
    },
    data: {
      path: `/matches/goals/:programId/:enrollmentId/:matchId`,
      exact: true,
    },
  }),
  addGoal: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
    },
    data: {
      path: `/matches/goals/:programId/:enrollmentId/:matchId/add`,
      exact: true,
    },
  }),
  editGoal: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
    },
    data: {
      path: `/matches/goals/:programId/:enrollmentId/:matchId/:id/update`,
      exact: true,
    },
  }),
  milestones: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
    },
    data: {
      path: `/matches/milestones/:programId/:enrollmentId/:matchId`,
    },
  }),
  conclude: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
    },
    data: {
      path: `/matches/leave/:matchId/program/:programId/enrollment/:enrollmentId/role/:roleId`,
      exact: true,
    },
  }),
};
