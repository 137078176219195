import {
  PRIVATE_ROUTES_LAYOUT_ITEMS,
  ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_ACCESS,
} from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  profilette: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_ACCESS,
    },
    data: {
      path: `/profilette/:id`,
    },
  }),
};
