import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

export type MQNavDividerProps = {
  className?: string;
  direction?: 'horizontal' | 'vertical';
};

const MQNavDivider: FC<MQNavDividerProps> = ({ className = '', direction = 'vertical', ...props }) => (
  <div className={classNames(['mq-nav-divider', direction, className])} {...props} />
);

export default MQNavDivider;
