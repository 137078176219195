import { ProgramRoleDto, ZoomMeetingResponseDto } from '@mentorcliq/storage';

import { STATIC_ASSETS } from 'definitions/assets';
import { APP_SUPPORT_EMAIL } from 'definitions/configs';
import { APP_MESSAGE_TOKENS } from 'definitions/tokens';

export const generateMicrosoftAzureLoginUrl = ({
  microsoftAzureADTenant,
  microsoftAzureADApplicationID,
  memberId,
  openModal,
  enrollmentId,
  matchId,
}: {
  microsoftAzureADTenant?: string;
  microsoftAzureADApplicationID?: string;
  matchId?: string;
  enrollmentId?: string;
  memberId?: string;
  openModal?: boolean;
}) =>
  `https://login.microsoftonline.com/${microsoftAzureADTenant}/oauth2/v2.0/authorize?client_id=${microsoftAzureADApplicationID}&response_type=code&response_mode=query&redirect_uri=${
    window.location.origin
  }/microsoft-oauth-callback&scope=calendars.readwrite+calendars.readwrite.shared&state=${JSON.stringify({
    matchId,
    enrollmentId,
    memberId,
    openModal,
  })}`;

export const generateUnMatchDefaultMessageTemplates = ({
  programRoles,
  name,
}: {
  programRoles: ProgramRoleDto[];
  name: string;
}) =>
  programRoles.map((role) => ({
    name: role.name,
    subject: `${name} Relationship Closure`,
    programRoleId: role.id,
    includeFinalQuickcliq: false,
    includeSatisfactionSurvey: false,
    body: `
        <p>Thank you for participating in the program. We hope you had a rewarding and enjoyable experience.</p>
        <p><strong>Next Steps</strong></p>
        <ul>
          <li><strong>Review relationship closure resources.</strong> The <a href="${APP_MESSAGE_TOKENS.resourceLibrary.label}">Resource Library</a> has some helpful tips of closing your relationship as well as transition new mentoring relationship.</li>
          <li><strong>Review your <a href="${APP_MESSAGE_TOKENS.developmentTimelineLink.label}">Development Timeline</a>.</strong> Celebrate your successes and complete any pending surveys relating to your relationship. This information helps us measure our program success and lets us know what is going well and where we can make improvements.</li>
          <li><strong>Stay tuned.</strong> We'll keep you informed with details about upcoming opportunities in the program.</li>
        </ul>
        <p>Thank you for your work and participation in the Mentoring Program!</p>
      `,
  })) || [];

export const getSupperVisorApprovalCustomMessages = (roleId: number) => ({
  programRoleId: roleId,
  approvalMessageSubject: `Employee request to participate in the ${APP_MESSAGE_TOKENS.programName.label}`,
  approvalMessageDescription: `
<p>${APP_MESSAGE_TOKENS.participantName.label} has applied to participate as a ${APP_MESSAGE_TOKENS.roleTitle.label} in the ${APP_MESSAGE_TOKENS.programName.label}. Please review the request to participate and approve or decline their request. <i>If declining the request, you’ll have the option to include a note to ${APP_MESSAGE_TOKENS.participantName.label}.</i></p>
<p>${APP_MESSAGE_TOKENS.approvalLink.label}</p>
<b>Program Overview</b>
<p>${APP_MESSAGE_TOKENS.programDescription.label}</p>`,
  approvedMessageSubject: `${APP_MESSAGE_TOKENS.programName.label}: Participation Status – Approved`,
  approvedMessageDescription: `
<p>Hello ${APP_MESSAGE_TOKENS.participantName.label},</p>
<p>Your manager has approved your participation in the ${APP_MESSAGE_TOKENS.programName.label}. Now we’ll work on finding you a match! You can expect to hear from us soon.</p>
<p>Happy Mentoring,</p>
<p>-${APP_MESSAGE_TOKENS.programName.label} Team</p>`,
  declinedMessageSubject: `${APP_MESSAGE_TOKENS.programName.label}: Participation Status - Declined`,
  declinedMessageDescription: `<p>Hello ${APP_MESSAGE_TOKENS.participantName.label},</p>
<p>Unfortunately, your manager has declined your request to participate in the ${APP_MESSAGE_TOKENS.programName.label}. If you have any questions, please speak to your manager. We hope you are able to reapply in the future!</p>
<p>${APP_MESSAGE_TOKENS.approvalDeclineMessage.label}</p>
<p>Thank you,</p>
<p>-${APP_MESSAGE_TOKENS.programName.label} Team</p>`,
});

export const getSelfMatchingEmailCustomMessages = () => ({
  requestSubject: `${APP_MESSAGE_TOKENS.programName.label} - Match Request`,
  requestBody: `
<p>You have a match request from:</p>
<p>${APP_MESSAGE_TOKENS.profileLink.label}</p>
<p><a href="${APP_MESSAGE_TOKENS.matchCompatibility.label}">View the match details for your potential match.</a></p>
<p><b>Message from ${APP_MESSAGE_TOKENS.requesterName.label}:</b></p>
<p>${APP_MESSAGE_TOKENS.messageToRequestee.label}</p>
<p>This request will expire in ${APP_MESSAGE_TOKENS.matchRequestExpiresDays.label} days.</p>
<p>Click the link below to either <b>Accept</b> this match request and begin mentoring or <b>Decline</b> this match request. You will be asked to confirm your selection on the next page.</p>
<p><a href="${APP_MESSAGE_TOKENS.acceptLink.label}">Click here to confirm that you are accepting this match request.</a></p>
<p><a href="${APP_MESSAGE_TOKENS.declineLink.label}">Click here to confirm that you would like to decline this match request.</a> You can include a note to the requester explaining your choice on the confirmation page.</p>`,
  acceptanceRequesterSubject: `${APP_MESSAGE_TOKENS.programName.label} - Match Accepted`,
  acceptanceRequesterBody: `
<p>Congratulations! Your match request was accepted. Let the mentoring begin!</p>
<p>${APP_MESSAGE_TOKENS.profileLink.label}</p>
<p>Please reach out to your match to set up a time for an initial 30-minute meeting.</p>
<p>Your first meeting is a great opportunity to not only say hello, but also to start discussing your mentoring goals and expectations for the mentoring relationship. Here are a few pieces of content that can help you prepare for that first meeting:</p>
<ul>
  <li><a href="${STATIC_ASSETS.docs.resources.gettingStartedForMentees}">Getting started as a Mentee</a></li>
  <li><a href="youtu.be/rn_X9DN4H84">3 steps for building a good mentoring relationship</a></li>
  <li><a href="${STATIC_ASSETS.docs.resources.mentoringPartnershipAgreement}">Goal Setting</a></li>
</ul>
<p>Even more great resources can be found in the ${APP_MESSAGE_TOKENS.resourceLibrary.label} to assist you at all stages of your mentoring relationship.</p>
<p>Have fun at that first meeting. Get to know a little bit about each other. And set the stage for a successful mentoring relationship.</p>`,
  acceptanceRequesteeSubject: `${APP_MESSAGE_TOKENS.programName.label} - Match Accepted`,
  acceptanceRequesteeBody: `
<p>Congratulations! Let the mentoring begin!</p>
<p>${APP_MESSAGE_TOKENS.profileLink.label}</p>
<p>Your match should be reaching out to you shortly to set up your first meeting.</p>
<p>Your first meeting is a great opportunity to not only say hello, but also to start discussing your mentoring goals and expectations for the mentoring relationship. Here are a few pieces of content that can help you prepare for that first meeting:</p>
<ul>
  <li><a href="${STATIC_ASSETS.docs.resources.gettingStartedForMentors}">Getting started as a Mentor</a></li>
  <li><a href="youtu.be/rn_X9DN4H84">3 steps for building a good mentoring relationship</a></li>
  <li><a href="${STATIC_ASSETS.docs.resources.mentoringPartnershipAgreement}">Goal Setting</a></li>
</ul>
<p>Even more great resources can be found in the ${APP_MESSAGE_TOKENS.resourceLibrary.label} to assist you at all stages of your mentoring relationship.</p>
<p>Have fun at that first meeting. Get to know a little bit about each other. And set the stage for a successful mentoring relationship.</p>`,
  declineSubject: `${APP_MESSAGE_TOKENS.programName.label} - Match Declined`,
  declineBody: `
<p>Your match request with ${APP_MESSAGE_TOKENS.requested.label} was declined.</p>
<p>${APP_MESSAGE_TOKENS.noteFromRequester.label}</p>
<p><a href="${APP_MESSAGE_TOKENS.requestAnotherMatchLink.label}">Click here to login and request a different match.</a></p>`,
  expirationRequesterSubject: `${APP_MESSAGE_TOKENS.programName.label} - Your Match Request Has Expired`,
  expirationRequesterBody: `
<p>Hi ${APP_MESSAGE_TOKENS.name.label},</p>
<p>We’re sorry, your match request for ${APP_MESSAGE_TOKENS.requesteeName.label} has expired. But don’t fear, you are now back in the mentoring pool, <a href="${APP_MESSAGE_TOKENS.requestAnotherMatchLink.label}">click here to request another match.</a></p>
<p>Happy Mentoring!</p>`,
  expirationRequesteeSubject: `${APP_MESSAGE_TOKENS.programName.label} - Your Match Request Has Expired`,
  expirationRequesteeBody: `
<p>Hi ${APP_MESSAGE_TOKENS.name.label},</p>
<p>${APP_MESSAGE_TOKENS.profileLink.label}</p>
<p>The match request from ${APP_MESSAGE_TOKENS.requesterName.label} has expired. You are now back in the mentoring pool and are available for others to request you as a match..</p>
<p>Happy Mentoring!</p>`,
});

export const getEligibilityRequirementsPerRoleMessage = (roleName: string) =>
  `<p>Type your <b>${roleName}</b>-specific eligibility requirements here. You may also add <a href="#">links</a> to company documentation that you find relevant.</p>`;

export const getQuickcliQCustomMessage = () => ({
  message: {
    subject: `QuickcliQ: ${APP_MESSAGE_TOKENS.interval.label} for ${APP_MESSAGE_TOKENS.programName.label}`,
    body: `<p>Thanks for participating in the ${APP_MESSAGE_TOKENS.programName.label}. In order to ensure that you’re getting credit for the time you’ve invested, we have a quick and simple way for you to report your progress – the QuickcliQ .</p><p>In less than a minute you can:</p><ul><li>Notate the number of hours you spent</li><li>Select the topics covered</li></ul><p>Please respond by ${APP_MESSAGE_TOKENS.dueDate.label}.</p><p>${APP_MESSAGE_TOKENS.surveyLink.label}</p>`,
  },
  reminderSubject: `QuickcliQ Reminder: ${APP_MESSAGE_TOKENS.interval.label} for ${APP_MESSAGE_TOKENS.programName.label}`,
});

export const getQuickcliQNotificationMessage = () => ({
  subject: `QuickcliQ Confirmation: ${APP_MESSAGE_TOKENS.interval.label} for ${APP_MESSAGE_TOKENS.programName.label}`,
  body: `<p>Thanks for participating in the ${APP_MESSAGE_TOKENS.programName.label}. You recorded the following for this QuickcliQ period.</p>
<p>${APP_MESSAGE_TOKENS.quickcliqPeriodSummary.label}</p>
<p>If this all looks correct, you’re all set! Otherwise, ${APP_MESSAGE_TOKENS.surveyLink.label}.</p>`,
});

export const getSatisfactionSurveyCustomMessage = () => ({
  subject: `${APP_MESSAGE_TOKENS.programName.label}. How’s it going? (Short Survey)`,
  body: `<p>Hello! Thanks again for your participation in the ${APP_MESSAGE_TOKENS.programName.label}. Your effort and dedication are making our organization an even better place to build a career.</p><p>Please take a minute (actually, less than a minute) to let us know how it's going for you. What do you think of the program? How is your mentoring relationship going?</p><p>${APP_MESSAGE_TOKENS.mentorcliqSurveyLink.label}</p><p>Your feedback will help us refine the program and make mentoring even better for you and future participants.</p><p>Thanks for sharing!</p>`,
});

export const getQuickcliQNudgeMessage = () => ({
  subject: `How is your mentoring relationship going?`,
  body: `<p>Hi ${APP_MESSAGE_TOKENS.userName.label},</p>
<p>Based on your mentoring “QuickcliQ” submissions, it looks like you haven’t met with ${APP_MESSAGE_TOKENS.partnerNames.label} in a while. Is everything going OK?</p>
<p>Let us know how things are going and if we can help by using the following link to check-in.</p>
<i>Don’t worry, this takes less than a minute.</i>
<p><a href="${APP_MESSAGE_TOKENS.surveyInbox.label}">Click here to check-in and update your mentoring hours.</a></p>
<p>We recognize that sometimes mentoring relationships run their course or just aren’t a great fit. If you’d like to end your mentoring relationship, please reach out to your program administrator.</p>
<p>Happy mentoring!</p>`,
});

export const getEnrollmentCompletionMessages = () => ({
  subject: `Thank you for enrolling in the ${APP_MESSAGE_TOKENS.programName.label}`,
  body: `<p>Thanks for completing your enrollment as a ${APP_MESSAGE_TOKENS.roleTitle.label} in the ${APP_MESSAGE_TOKENS.programName.label}. Welcome!</p>
  <p><b>What happens next?</b></p>
  <p>You will be assigned a match. We consider your suggestions, locations, professional roles, and backgrounds when connecting participants to ensure a great experience for both parties.</p>
  <p>We will send you an e-mail when you’ve been matched. The e-mail will include contact information and instructions for connecting for your first mentoring conversation.</p>
  <p>Welcome again and thanks for your participation in the program!</p> 
  `,
});

export const getMatchNotificationMessage = () => ({
  subject: `${APP_MESSAGE_TOKENS.programName.label} Match`,
  body: `
 <p>Congratulations! You are now officially matched. We hope you find this relationship rewarding and truly enjoy the mentoring experience.</p>
 <p>${APP_MESSAGE_TOKENS.groupMatchDetails.label}</p>
 `,
});

export const getMatchNotificationMessageByRoleId = (roleId: number) => ({
  programRoleId: roleId,
  subject: `${APP_MESSAGE_TOKENS.programName.label} Match`,
  body: `
 <p>Congratulations! You are now officially matched. We hope you find this relationship rewarding and truly enjoy the mentoring experience.</p>
 <p>${APP_MESSAGE_TOKENS.groupMatchDetails.label}</p>
 `,
});

export const activateAndNotifyParticipantMessage = (programName: string) => ({
  subject: `Welcome Back to the ${programName}`,
  body: `Your program administrator has re-enrolled you in the ${programName}.<br/>Please take a moment to log into the mentoring platform using the link below and update your profile and program preferences so you can start mentoring!
<br/> <a href="${APP_MESSAGE_TOKENS.mentorcliqLoginLink.label}">${APP_MESSAGE_TOKENS.mentorcliqLoginLink.label}</a>
<br/>If you have any questions please contact your program administrator or <a href="mailto:support${APP_SUPPORT_EMAIL}">support@mentorcliq.com</a>.</p>`,
});

export const getRelationshipClosureMessage = () => ({
  subject: `${APP_MESSAGE_TOKENS.programName.label} Relationship Closure`,
  body: `<div class="c-message_kit__gutter">
  <div class="c-message_kit__gutter__right" role="presentation" data-qa="message_content">
  <div class="c-message_kit__blocks c-message_kit__blocks--rich_text">
  <div class="c-message__message_blocks c-message__message_blocks--rich_text" data-qa="message-text">
  <div class="p-block_kit_renderer" data-qa="block-kit-renderer">
  <div class="p-block_kit_renderer__block_wrapper p-block_kit_renderer__block_wrapper--first">
  <div class="p-rich_text_block" dir="auto">
  <div class="p-rich_text_section">Thank you for participating in the program. We hope you had a rewarding and enjoyable experience.<br><strong data-stringify-type="bold">Next Steps</strong></div>
  <ul class="p-rich_text_list p-rich_text_list__bullet" data-stringify-type="unordered-list" data-indent="0" data-border="0">
  <li data-stringify-indent="0" data-stringify-border="0"><strong data-stringify-type="bold">Review relationship closure resources.</strong>&nbsp;The&nbsp;<a href="${APP_MESSAGE_TOKENS.resourceLibrary.label}">Resource Library</a>&nbsp;has some helpful tips of closing your relationship as well as transition new mentoring relationship.</li>
  <li data-stringify-indent="0" data-stringify-border="0"><strong data-stringify-type="bold">Review your Development Timeline.</strong>&nbsp;Celebrate your successes and complete any pending surveys relating to your relationship. This information helps us measure our program success and lets us know what is going well and where we can make improvements.</li>
  <li data-stringify-indent="0" data-stringify-border="0"><strong data-stringify-type="bold">Stay tuned.</strong>&nbsp;We'll keep you informed with details about upcoming opportunities in the program.</li>
  </ul>
  <div class="p-rich_text_section">Thank you for your work and participation in the Mentoring Program!</div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  <div class="c-message_actions__container c-message__actions" role="group">
  <div class="c-message_actions__group" role="group" aria-label="Message actions" data-qa="message-actions">&nbsp;</div>
  </div>`,
});

export const getMilestoneCompleteMessage = (programName: string) => ({
  subject: `I completed my milestone for the ${programName}!`,
  body: `Hello! I just completed the milestone for our relationship in the ${programName}.`,
});

export const getGoalCompleteMessage = (programName: string) => ({
  subject: `I completed my goal for the ${programName}!`,
  body: `Hello! I just completed the goal assigned to me for our relationship in the ${programName}.`,
});

export const getMatchRequestMessage = () => ({
  note: `<p>I would appreciate the opportunity to partner with you in this program. Please consider accepting my request.</p>`,
});

export const getMatchRequestDeclineMessage = () =>
  `Thank you for your mentoring request. Unfortunately, it is not a good fit for me at this time. I wish you luck in finding a more suitable mentoring fit. \n\nSincerely,`;

export const getDefaultScheduleMessages = () => ({
  tipsForBeingMentee: {
    subject: '10 tips for being a mentee.',
    body: `<p>Hi!</p>
            <p>We hope your early mentoring sessions have been productive and engaging. Here are a few tips that can help you become a great mentee and maximize the benefits of this mentoring program.&nbsp;</p>
            <ol><li><strong>Be an active listener</strong> with questions and body language that shows openness to what your mentor is saying.</li>
            <li><strong>Be trustworthy.</strong> Follow through. Show integrity.</li>
            <li><strong>Respect the time</strong> given by being prepared for your meetings.</li>
            <li><strong>Be patient</strong> - even if you already know what your mentor is saying, listen politely and follow up by asking about their personal experiences relating to that advice. You might get a perspective you hadn’t considered before.</li>
            <li><strong>Be ready to work!</strong> Be a mentee that follows through with tasks and assignments.</li>
            <li><strong>Be willing to take risks.</strong> Your mentor may ask you to do something outside your comfort zone. Be open to it!</li>
            <li><strong>Be a great introduction.</strong> Treat any introduction into your mentor’s network with the utmost respect - remember that you have been given access into a network you didn’t earn on your own accord.</li>
            <li><strong>Provide feedback.</strong> Let your mentor know the status of the situations you’ve discussed—especially in situations they have given advice on or introduced you into.</li>
            <li><strong>Reciprocate!</strong> Giving thanks is a wonderful way to reciprocate, but also look for ways to give back in terms of a timely article, an introduction that would benefit them, or an offer to help where you can.</li></ol>
            <p>For more information on starting strong in your mentoring relationship, read our short <a href="${APP_MESSAGE_TOKENS.mentorcliqDomainToken.label}/files/resource_library/Closure%20Plan%20form.pdf" data-cke-saved-href="${APP_MESSAGE_TOKENS.mentorcliqDomainToken.label}/files/resource_library/Closure%20Plan%20form.pdf" data-mce-href="${APP_MESSAGE_TOKENS.mentorcliqDomainToken.label}/files/resource_library/Closure%20Plan%20form.pdf"> <u>Starting Strong as a Mentee</u> </a> guide.</p>
            <p>-Talent Development Team</p>`,
  },
  mentorTip: {
    subject: 'Mentor Tip. Ask more questions.',
    body: `<p>Hi!</p><p>Here’s a quick mentoring tip to help get more out of (and for) your new mentee.</p>
          <p><strong>TIP: Ask more questions. Provide fewer answers. </strong></p>
          <p>It’s hard not to give advice when you absolutely know a great solution. But while giving advice is certainly part of your role as a mentor, the learning process is much stronger when people “discover” knowledge rather than being given a solution. If you are more prone to giving advice than questioning, using the Socratic method of questioning will help you to challenge the accuracy and completeness of your mentee’s thinking in a way that prods them towards their ultimate goal.</p>
          <p>The Socratic method challenges clarity, assumptions, rationale, perspectives, and consequences. Use the questions below to jump-start your mentee’s thinking as you talk.</p>
          <ul><li>Can you give me an example?</li>
          <li>Explain why/how…?</li>
          <li>Are you saying … or … ?</li>
          <li>What do we already know about this?</li>
          <li>What else could we assume?</li>
          <li>How did you come to that conclusion?</li>
          <li>What would happen if … ?</li>
          <li>Why?</li><li>What do you think causes … ?</li>
          <li>Why do you think that is happening … ?</li>
          <li>Another way of looking at this is …, does that seem reasonable?</li>
          <li>What if you compared … and … ?</li>
          <li>Why is … necessary?</li>
          <li>What are some other ways of looking at this?</li>
          <li>How does … fit with what we learned before?</li>
          <li>What is the best … ? Why?</li>
          <li>What would the consequences be if … ?</li>
          <li>Why do you think I asked that question?</li>
          <li>What else might I ask?</li></ul>
          <p>Obviously, you shouldn’t withhold all direct advice, but do try to guide your mentee to their own solutions. Not only do they get their ultimate solution, they also learn to get there through critical analysis.</p>
          <p>For more information on starting strong in your mentoring relationship, read our short <a data-cke-saved-href="${APP_MESSAGE_TOKENS.mentorcliqDomainToken.label}/files/resource_library/Closure%20Plan%20form.pdf" href="${APP_MESSAGE_TOKENS.mentorcliqDomainToken.label}/files/resource_library/Closure%20Plan%20form.pdf"> <u>Starting Strong as a Mentor</u> </a> guide.</p>
          <p>-Talent Development Team</p>`,
  },
  mentoringProgram: {
    subject: 'Mentoring Program. Time for the Mid-Cycle Mentoring Check-In',
    body: `<p>Hi!</p><p>It’s time for you and your mentoring partner to sit down and answer one critical question:</p>
        <p><strong>How’s it going?</strong></p>
        <p>You need to meet with your mentoring partner and discuss your mentoring relationship, your mentoring structure, your goals, and your progress. If you discover issues, you can course correct. If you find things that are working well, you can reinforce and continue.</p>
        <p>Dedicate a portion of you next mentoring session to this check-in.</p>
        <p>Here are some resources that can help you prepare for this discussion:</p>
        <ul><li><a data-cke-saved-href="${APP_MESSAGE_TOKENS.mentorcliqDomainToken.label}/files/resource_library/Closure%20Plan%20form.pdf" href="${APP_MESSAGE_TOKENS.mentorcliqDomainToken.label}/files/resource_library/Closure%20Plan%20form.pdf"><u>Mentoring Mid-Cycle Check-In</u> </a> worksheet can help guide your conversation</li>
        <li><a data-cke-saved-href="https://youtu.be/QoLZeZHBvfo" href="https://youtu.be/QoLZeZHBvfo">
        <u>Safety Valves: The art of the mid-cycle check-in</u> 
        </a> (Video) for a quick overview on how to approach this meeting</li>
        </ul><p>Have a great talk. And let us know if you need any additional help or support coming out of that meeting.</p>
        <p>-Talent Development Team</p>`,
  },
  talkAboutNextSteps: {
    subject: 'The mentoring cycle is winding down. Time to talk about next steps!',
    body: `<p>Hi!</p>
      <p>It’s the final month of this mentoring cycle. That means it’s time for you and your mentoring partner to schedule some time to:</p>
      <ul><li><strong>Discuss your mentoring progress and goals. </strong>What did you achieve? What were your big wins? Major challenges?</li>
      <li><strong>Reflect on you mentoring program relationship. </strong>Was the collaboration fruitful? How can continue in a less formal capacity?</li>
      <li><strong>Talk about next steps.</strong> Do you plan on signing up for the next mentoring cycle? Has the mentee considered mentoring someone else in the future?</li></ul>
      <p>Here are some resources that can help you prepare for this discussion:</p>
      <ul><li><a data-cke-saved-href="${APP_MESSAGE_TOKENS.mentorcliqDomainToken.label}/files/resource_library/Closure%20Plan%20form.pdf" href="${APP_MESSAGE_TOKENS.mentorcliqDomainToken.label}/files/resource_library/Closure%20Plan%20form.pdf"><u>Mentoring Closure</u> </a> worksheet can help guide your conversation</li>
      <li><a data-cke-saved-href="https://youtu.be/v4t6kAKHu0Q" href="https://youtu.be/v4t6kAKHu0Q"><u>Onward and Upward: Transitioning from your mentoring relationship</u> </a> (Video) for a quick overview on how to approach this meeting</li></ul>
      <p>Have a great talk. And let us know if you need any additional help or support coming out of that meeting.</p>
      <p>-Talent Development Team</p>`,
  },
});

export const getBrandingEmailTemplateContent = () => `
  <html lang="en">
  <p><strong>Welcome to the e-mail branding page!</strong></p>
  <p>This is merely an <strong>e-mail preview</strong> with placeholder content. Your e-mail length will depend on your actual content, but the header and footer should appear as seen in this preview.</p>
  <p>You'll notice that you can get different results by aligning your logo and changing the header background color. You may also edit the footer content, which is the usual location for copyright notices and support links.</p>
  <p>Please feel free to contact our <a class="underline" href="mailto:${APP_SUPPORT_EMAIL}">support team</a> if you have any remaining questions regarding e-mail templates and your branding options.</p>
  </html>
  `;

export const getReminderMessageTemplate = () =>
  `<p>You haven't completed your enrollment, yet.</p><p><a href="${APP_MESSAGE_TOKENS.loginLink.label}">Click here to complete your enrollment and start mentoring.</a></p>`;

export const ssoLoginPageContent =
  '<body><h1>Are you a [platform-name] Employee?</h1><p>Use your Single-Sign-On (SSO) to log in and start mentoring!</p></body>';

export const generateZoomCredentials = (zoomMeetingData: ZoomMeetingResponseDto) => {
  const agenda = zoomMeetingData.agenda ? `<p>${zoomMeetingData.agenda}</p>` : '';
  const password = zoomMeetingData.password ? `<p>Meeting Password: ${zoomMeetingData.password}</p>` : '';

  return `${agenda}
   <p>-----------------------</p>
   <p>Meeting ID: ${zoomMeetingData.id}</p>
   ${password}
   <p>Topic: ${zoomMeetingData.topic}</p>
   <p>Join URL: <a href=${zoomMeetingData.join_url}>${zoomMeetingData.join_url}</a></p>
   <p>-----------------------</p>`;
};

export const getProgramAdminHrIsNotifyMessage = () => ({
  subject: `Participants removed from during HRIS update`,
  body: `'The following users were not listed in the most recent HRIS data set MentorcliQ received and have been removed from ${APP_MESSAGE_TOKENS.mentorcliqPlatformName.label}: [removed_users_details]',`,
});

export const generateInviteSubjectTemplate = (roleName: string | null) => `${roleName ?? 'Participant'} - Invite`;

export const generateInviteMessageTemplate = (
  programName: string,
) => `<p>Thank you for participating in the ${programName}. We're glad you're here! To get started, please complete your enrollment so we can learn more about you.</p>
<p>Enroll by clicking this link or copying and pasting it to your browser:<br><a href="${APP_MESSAGE_TOKENS.loginLink.label}">Click here to enroll and begin mentoring!</a></p>
<p>We'll use the information you provide to help track program participation so we can provide the support and resources you need during the mentoring program.</p>`;
