import './style.scss';

import { FC, ReactNode, useEffect, useMemo, useState, useCallback } from 'react';

import { MQIcon, MQButton, MQOverlay } from '@mentorcliq/ui';

import MQCard from 'modules/MQCard';
import MQSlider from 'modules/MQSlider';

export interface MQTourStepProps {
  selector: string;
  content: ReactNode;
  title: ReactNode;
  name: string;
  actions: string[];
}

interface GuideTourProps {
  steps: MQTourStepProps[];
  active: string;
  setActiveStep: (stepName: string) => void;
  onHide?: () => void;
  interval?: number;
  prevButtonLabel?: ReactNode;
  nextButtonLabel?: ReactNode;
  closeButtonLabel?: ReactNode;
}

const MQTour: FC<GuideTourProps> = ({
  steps = [],
  active,
  setActiveStep,
  onHide,
  interval = 200,
  prevButtonLabel = 'Back',
  nextButtonLabel = 'Next',
  closeButtonLabel = 'Close',
}) => {
  const [guideElement, setGuideElement] = useState<{
    node: HTMLElement | null;
    step: MQTourStepProps | null;
    rect: {
      left: number;
      right: number;
      top: number;
      bottom: number;
      width: number;
      height: number;
    };
  }>({
    node: null,
    step: steps.find((step) => step.name === active) ?? null,
    rect: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      width: 0,
      height: 0,
    },
  });

  const activeStepIndex = useMemo(() => steps.findIndex((step) => step.name === active), [active, steps]);

  const changeGuide = useCallback(
    (target: HTMLElement | null) => {
      target?.scrollIntoView({
        block: 'center',
      });
      setGuideElement((prevState) => ({
        ...prevState,
        node: target,
      }));
    },
    [setGuideElement],
  );

  const loadGuide = useCallback(
    (selector: string) => {
      const intervalInfo = {
        counter: 0,
      };

      const intervalId = setInterval(() => {
        const element = document.querySelector(selector);
        if (intervalInfo.counter >= 4) {
          clearInterval(intervalId);
        }

        if (element instanceof HTMLElement) {
          changeGuide(element);
          clearInterval(intervalId);
        } else {
          intervalInfo.counter++;
        }
      }, interval);
    },
    [changeGuide, interval],
  );

  useEffect(() => {
    setGuideElement((prevState) => ({
      ...prevState,
      step: steps.find((step) => step.name === active) ?? null,
    }));
  }, [active, steps]);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      const rect = guideElement.node?.getBoundingClientRect();
      if (rect) {
        setGuideElement((prevState) => ({
          ...prevState,
          rect: {
            left: rect.left,
            right: rect.right,
            top: rect.top,
            bottom: rect.bottom,
            width: rect.width,
            height: rect.height,
          },
        }));
      }
    });

    if (guideElement.node) {
      observer.observe(guideElement.node);
    }

    return () => {
      observer.disconnect();
    };
  }, [guideElement.node, setGuideElement]);

  useEffect(() => {
    const activateGuide = (event: Event) => {
      if (event.currentTarget instanceof HTMLElement) {
        changeGuide(event.currentTarget);
      }
    };

    guideElement.step?.actions.forEach((action) => {
      if (guideElement.step) {
        if (action === 'load') {
          loadGuide(guideElement.step.selector);
        } else {
          guideElement.node?.addEventListener(action, activateGuide);
        }
      }
    });

    return () => {
      guideElement.step?.actions.forEach((action) => {
        if (action !== 'load') {
          guideElement.node?.removeEventListener(action, activateGuide);
        }
      });
    };
  }, [changeGuide, guideElement.node, guideElement.step, loadGuide]);

  const handleNextSlide = useCallback(() => {
    if (activeStepIndex < steps.length - 1) {
      setActiveStep(steps[activeStepIndex + 1].name);
    } else if (onHide) {
      onHide();
    }
  }, [activeStepIndex, setActiveStep, steps, onHide]);

  const handlePrevSlide = useCallback(() => {
    if (activeStepIndex > 0) {
      setActiveStep(steps[activeStepIndex - 1].name);
    }
  }, [activeStepIndex, setActiveStep, steps]);

  const handleSlideChange = (slide: number) => {
    if (steps[slide]?.name) {
      setActiveStep(steps[slide].name);
    }
  };

  if (guideElement.node) {
    return (
      <div className="mq-tour-wrapper" style={guideElement.rect}>
        <MQOverlay.Wrapper
          className="mq-tour-modal-wrapper"
          state={{
            target: guideElement.node,
          }}
          setState={(data) => {
            changeGuide(data?.target ?? null);
          }}
          hideOnScroll={false}
          rootClose={false}
          placement="auto"
          rect={guideElement.rect}
          centralize
        >
          {({ placement }) => (
            <div className="mq-tour-modal" data-placement={placement}>
              {guideElement.step && (
                <MQCard border="light">
                  <MQCard.Header justify="space-between">
                    {guideElement.step.title}
                    <MQButton
                      dataTestId="guide-tour-close"
                      onClick={onHide}
                      variant="sub-action--tertiary"
                      endIcon={<MQIcon.Svg icon="xmark-circle__r" />}
                    />
                  </MQCard.Header>
                  <MQCard.Body>
                    <MQSlider
                      slidesPerView={1}
                      slidesPerSlide={1}
                      activeSlide={activeStepIndex}
                      animation="slide"
                      onSlideChange={handleSlideChange}
                      data={steps.map((step) => step.content)}
                      gap={0}
                      showDots
                      breakPoints={[
                        {
                          size: 1200,
                          count: 1,
                        },
                        {
                          size: 768,
                          count: 1,
                        },
                        {
                          size: 576,
                          count: 1,
                        },
                      ]}
                    />
                  </MQCard.Body>
                  <MQCard.Footer>
                    <MQButton
                      dataTestId="prev-step"
                      variant="tertiary"
                      onClick={handlePrevSlide}
                      disabled={activeStepIndex === 0}
                    >
                      {prevButtonLabel}
                    </MQButton>
                    <MQButton dataTestId="next-step" variant="tertiary" onClick={handleNextSlide}>
                      {activeStepIndex === steps.length - 1 ? closeButtonLabel : nextButtonLabel}
                    </MQButton>
                  </MQCard.Footer>
                </MQCard>
              )}
            </div>
          )}
        </MQOverlay.Wrapper>
      </div>
    );
  }

  return null;
};

export default MQTour;
