import './style.scss';

import { FC } from 'react';

import { MQAiMessageSenderEnum } from '@mentorcliq/storage';
import { MQIcon, MQTypo } from '@mentorcliq/ui';
import { format } from 'date-fns';

import ROUTES from 'routes';

import { APP_ENV_CONFIGS, TIME_HOURS_MINUTES_WITH_AM_PM } from 'definitions/configs';

import { openLink } from 'helpers/routes';

import MQLayout from 'modules/MQLayout';

interface ChatMessageProps {
  assistantTypeLabel: string;
  sender: string;
  created?: string;
  message: string;
}

const ChatMessage: FC<ChatMessageProps> = ({ sender, message, created, assistantTypeLabel }) => {
  document.querySelectorAll<HTMLAnchorElement>('.chat-message__ai-content a').forEach((link) => {
    if (link.href.startsWith(`${APP_ENV_CONFIGS.supportArticlesPath}`)) {
      link?.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopImmediatePropagation();

        openLink({
          url: ROUTES.cliqipedia.convert({
            search: {
              article: link.href,
            },
          }),
        });
      });
    }
  });

  return (
    <div className="chat-message">
      {sender === MQAiMessageSenderEnum.MQAi ? (
        <>
          <MQLayout.Heading
            variant="neutral"
            info={created && <MQTypo.Caption>{format(+created, TIME_HOURS_MINUTES_WITH_AM_PM)}</MQTypo.Caption>}
          >
            <MQTypo.Formatter
              prefix={
                <div className="chat-message__icon">
                  <MQIcon.Svg size="lg" icon="sparkles" />
                </div>
              }
            >
              <MQTypo.Text size="3x" bold nowrap>
                {assistantTypeLabel}
              </MQTypo.Text>
            </MQTypo.Formatter>
          </MQLayout.Heading>
          <div className="chat-message__ai-content" dangerouslySetInnerHTML={{ __html: message }} />
        </>
      ) : (
        <div className="chat-message__user-content">{message}</div>
      )}
    </div>
  );
};

export default ChatMessage;
