import './style.scss';

import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { clearAiHistoryThunk, MQAiTypeEnum } from '@mentorcliq/storage';
import { MQIcon } from '@mentorcliq/ui';
import classNames from 'classnames';

import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import { useAppAuthState } from 'hooks/useAppAuthState';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppIntl } from 'hooks/useAppIntl';
import { useAppRouter } from 'hooks/useAppRouter';

import ChatWindow from './Addons/components/ChatWindow';
import WelcomeWindow from './Addons/components/WelcomeWindow';
import { AI_ACTIVATION_PATHS } from './Addons/definitions';

enum AiAssistantStepsEnum {
  Welcome = 'WELCOME',
  Chat = 'CHAT',
}

const AppAiAssistant: FC = () => {
  const intl = useAppIntl();
  const dispatch = useAppDispatch();
  const { location } = useAppRouter();
  const { administrator, mode } = useAppAuthState();
  const dialogRef = useRef<HTMLDivElement>(null);
  const [showContent, setShowContent] = useState(false);
  const [step, setStep] = useState<AiAssistantStepsEnum>(AiAssistantStepsEnum.Welcome);

  const type = useMemo(
    () => (administrator && mode?.admin ? MQAiTypeEnum.AdminAi : MQAiTypeEnum.CliqAi),
    [administrator, mode?.admin],
  );

  const assistantTypeLabel = useMemo(
    () =>
      type === MQAiTypeEnum.AdminAi
        ? intl.formatMessage(APP_GLOBAL_MESSAGES.adminAiLabel)
        : intl.formatMessage(APP_GLOBAL_MESSAGES.cliqAiLabel),
    [intl, type],
  );

  useEffect(() => {
    const handleBeforeUnload = async () => {
      await dispatch(clearAiHistoryThunk());
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [dispatch]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
        setShowContent(!showContent);
      }
    };

    if (showContent) {
      document.addEventListener('mouseup', handleClickOutside);
    }
  }, [showContent]);

  useEffect(() => {
    if (AI_ACTIVATION_PATHS.includes(location.pathname)) {
      setShowContent(true);
    }
  }, [location.pathname]);

  return (
    <aside className="app-cliq-ai" ref={dialogRef}>
      <button
        data-testid="cliq-ai-button"
        aria-label={showContent ? 'Close AI Assistant' : 'Open AI Assistant'}
        className={classNames('app-cliq-ai__button', { initial: !showContent })}
        onClick={() => setShowContent(!showContent)}
      >
        {showContent ? <MQIcon.Svg size="lg" icon="times__l" /> : <MQIcon.Svg size="lg" icon="sparkles" />}
        {!showContent && <div className="app-cliq-ai__button-label">{assistantTypeLabel} </div>}
      </button>
      {showContent && (
        <div>
          {step === AiAssistantStepsEnum.Welcome ? (
            <WelcomeWindow
              type={type}
              next={() => {
                setStep(AiAssistantStepsEnum.Chat);
              }}
            />
          ) : (
            <ChatWindow
              type={type}
              back={() => {
                setStep(AiAssistantStepsEnum.Welcome);
              }}
            />
          )}
        </div>
      )}
    </aside>
  );
};

export default AppAiAssistant;
