import { createContext, ReactNode } from 'react';

import {
  AuthDto,
  ChatMarkAsReadRequestDto,
  ChatMessageDto,
  ChatMessageSubmitDto,
  ChatRoomDto,
  EnrollmentStateEnum,
  EnrollmentStateMetadata,
  ProfileStateEnum,
  UserProgramDto,
} from '@mentorcliq/storage';

import { LayoutSizeTypes } from 'types/route';

export interface AppLayoutContextBannerProps {
  node: ReactNode;
  sticky: boolean;
}

export interface AppLayoutContextProps {
  wrapperId: string;
  sidebarSize: number;
  sidebarExpanded: boolean;
  setSidebarExpanded?: (value: boolean) => void;
  layoutBanner: AppLayoutContextBannerProps;
  setLayoutBanner?: (banner: AppLayoutContextBannerProps) => void;
  layoutSize: LayoutSizeTypes;
  showCookies: boolean;
  showAiAssistant: boolean;
  showChat: boolean;
  showSupport: boolean;
  showBanner: boolean;
  showAlerts: boolean;
  showFooter: boolean;
  showViewAs: boolean;
  showScroller: boolean;
  showSidebar: boolean;
  showHeader: boolean;
}

export const APP_LAYOUT_CONTEXT_INITIAL_DATA: AppLayoutContextProps = {
  wrapperId: 'mq-wrapper',
  sidebarSize: 250,
  layoutBanner: {
    node: null,
    sticky: false,
  },
  layoutSize: LayoutSizeTypes.md,
  sidebarExpanded: true,
  showCookies: false,
  showAiAssistant: false,
  showChat: false,
  showSupport: false,
  showBanner: false,
  showAlerts: false,
  showFooter: false,
  showViewAs: false,
  showScroller: false,
  showSidebar: false,
  showHeader: false,
};

export const AppLayoutContext = createContext<AppLayoutContextProps>(APP_LAYOUT_CONTEXT_INITIAL_DATA);

interface AppChatConfigsDto {
  active: boolean;
  roomId: number;
  messages: ChatMessageDto[];
}

export interface AppSocketErrorDto {
  code: number;
  reason: string;
}

interface AppSocketConfigsDto {
  errors: AppSocketErrorDto[];
  attempts: number;
}

interface AppSocketContextParams {
  socketConfigs: AppSocketConfigsDto;
  chatConfigs: AppChatConfigsDto;
  chatRooms: Record<string, ChatRoomDto>;
  toggleRoom?: (id: number) => void;
  toggleChat?: (status: boolean) => void;
  send?: (data: ChatMessageSubmitDto) => void;
  read?: (data: ChatMarkAsReadRequestDto) => void;
  unread?: number;
  init?: () => void;
}

const APP_SOCKET_HOST = process.env.REACT_APP_API_HOST?.replace(/^http:/, 'ws:')?.replace(/^https:/, 'wss:');

export const APP_SOCKET_CONFIGS = {
  brokerUrl: `${APP_SOCKET_HOST}/socket`,
  brokerTimeout: 10000,
  connectTimeout: 2000,
  chatSubscribeUrl: '/chat',
  chatSubmitUrl: '/app/chat/submit',
  chatReadUrl: '/app/chat/mark-as-read',
};

export const APP_SOCKET_INITIAL_CONTEXT: AppSocketContextParams = {
  chatConfigs: {
    active: false,
    roomId: 0,
    messages: [],
  },
  chatRooms: {},
  socketConfigs: {
    errors: [],
    attempts: 0,
  },
};

export const AppSocketContext = createContext<AppSocketContextParams>(APP_SOCKET_INITIAL_CONTEXT);

interface AppVerifyActionsDto {
  save: () => Promise<void>;
  next: () => Promise<void>;
  prev: () => Promise<void>;
}

export interface AppVerifyContextParams {
  state?: ProfileStateEnum | null;
  user: AuthDto | null;
  loading: boolean;
  actions?: AppVerifyActionsDto;
}

export const AppVerifyContext = createContext<AppVerifyContextParams>({
  state: null,
  user: null,
  loading: false,
});

interface AppEnrollActionsDto {
  save: (callback?: (invitations: UserProgramDto[]) => void) => Promise<void>;
  next: (callback?: () => Promise<void>) => Promise<void>;
  skip: (callback?: () => Promise<void>) => Promise<void>;
  back: (callback?: () => Promise<void>) => Promise<void>;
  start: (programId: number, roleIds: number[]) => Promise<void>;
}

export interface AppEnrollContextParams {
  state?: EnrollmentStateEnum | null;
  meta?: EnrollmentStateMetadata | null;
  program?: UserProgramDto | null;
  invited: boolean | null;
  user: AuthDto | null;
  loading: boolean;
  actions?: AppEnrollActionsDto;
}

export const AppEnrollContext = createContext<AppEnrollContextParams>({
  state: null,
  meta: null,
  user: null,
  program: null,
  invited: null,
  loading: false,
});
