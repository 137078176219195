import { LayoutTypesEnum } from 'types/route';

import { ROUTE_COMMON_ACCESS } from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  statusPage403: createRoute({
    params: {
      layoutTypes: [LayoutTypesEnum.Header, LayoutTypesEnum.Alerts],
      types: ROUTE_COMMON_ACCESS,
    },
    data: {
      path: '/403',
      exact: true,
    },
  }),
  statusPage404: createRoute({
    params: {
      layoutTypes: [LayoutTypesEnum.Header],
      types: ROUTE_COMMON_ACCESS,
    },
    data: {
      path: '/404',
      exact: true,
    },
  }),
  statusPage429: createRoute({
    params: {
      layoutTypes: [LayoutTypesEnum.Header],
      types: ROUTE_COMMON_ACCESS,
    },
    data: {
      path: '/429',
      exact: true,
    },
  }),
  statusPage500: createRoute({
    params: {
      layoutTypes: [LayoutTypesEnum.Chat],
      types: ROUTE_COMMON_ACCESS,
    },
    data: {
      path: '/500',
      exact: true,
    },
  }),
};
