import { PermissionsEnum } from '@mentorcliq/storage';

import { ProviderTypesEnum } from 'types/access';
import { LayoutSizeTypes, LayoutTypesEnum } from 'types/route';

import {
  PRIVATE_ROUTES_LAYOUT_ITEMS,
  ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
} from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  people: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        permissions: {
          value: [PermissionsEnum.ManageCustomerUsers],
        },
      },
    },
    data: {
      path: `/people`,
    },
  }),
  peopleList: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        permissions: {
          value: [PermissionsEnum.ManageCustomerUsers],
        },
      },
    },
    data: {
      path: `/people`,
      exact: true,
    },
  }),
  peopleAddUser: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        permissions: {
          value: [PermissionsEnum.ManageCustomerUsers],
        },
      },
    },
    data: {
      path: `/people/add-user`,
      exact: true,
    },
  }),
  peopleEdit: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        permissions: {
          value: [PermissionsEnum.ManageCustomerUsers],
        },
      },
    },
    data: {
      path: `/people/edit-user/:id`,
    },
  }),
  peopleEditUser: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        permissions: {
          value: [PermissionsEnum.ManageCustomerUsers],
        },
      },
    },
    data: {
      path: `/people/edit-user/:id`,
      exact: true,
    },
  }),
  peopleEditProfilePicture: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        providers: {
          value: [ProviderTypesEnum.ProfilePictureEnabled],
        },
        permissions: {
          value: [PermissionsEnum.ManageCustomerUsers],
        },
      },
    },
    data: {
      path: `/people/edit-user/:id/profile/picture`,
      exact: true,
    },
  }),
  peopleEditProfileVps: createRoute({
    params: {
      layoutTypes: [LayoutTypesEnum.Footer, LayoutTypesEnum.Header, LayoutTypesEnum.Sidebar, LayoutTypesEnum.Cookies],
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        permissions: {
          value: [PermissionsEnum.ManageCustomerUsers],
        },
      },
    },
    data: {
      path: `/people/edit-user/:id/profile/vps`,
      exact: true,
    },
  }),
  peopleEditProfileVpsQuestions: createRoute({
    params: {
      layoutTypes: [LayoutTypesEnum.Footer, LayoutTypesEnum.Header, LayoutTypesEnum.Sidebar, LayoutTypesEnum.Cookies],
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        permissions: {
          value: [PermissionsEnum.ManageCustomerUsers],
        },
      },
    },
    data: {
      path: `/people/edit-user/:id/profile/vps/questions`,
      exact: true,
    },
  }),
  peopleEditProfileVpsComplete: createRoute({
    params: {
      layoutTypes: [LayoutTypesEnum.Footer, LayoutTypesEnum.Header, LayoutTypesEnum.Sidebar, LayoutTypesEnum.Cookies],
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        permissions: {
          value: [PermissionsEnum.ManageCustomerUsers],
        },
      },
    },
    data: {
      path: `/people/edit-user/:id/profile/vps/complete`,
      exact: true,
    },
  }),
  peopleEditAccount: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      layoutSize: LayoutSizeTypes.lg,
      types: ROUTE_AUTHENTICATED_ADMIN_AND_GDPR_ACCEPTED_ENROLLED_ACCESS,
      access: {
        permissions: {
          value: [PermissionsEnum.ManageCustomerUsers],
        },
      },
    },
    data: {
      path: `/people/edit-user/:id/profile/account`,
      exact: true,
    },
  }),
};
