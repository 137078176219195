import './style.scss';

import { FC, useState } from 'react';

import { MQIcon } from '@mentorcliq/ui';

import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import MQSidebar from 'modules/MQSidebar';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

import AppLink from 'router/AppLink';

const Preview: FC = () => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  return (
    <MQSidebar.List className="app-sidebar__list" data-testid="sidebar-dashboard">
      <MQSidebar.ListItem
        as={AppLink}
        className="app-sidebar__list-item"
        data-testid="sidebar-dashboard-link"
        to="#"
        prefix={<MQIcon.Svg icon="house__r" />}
      >
        <AppFormattedMessage
          defaultMessage="Current Menu Item"
          description="[AppSidebar.Preview] Current Menu Item label"
          id="app.sidebar.preview.current.menu.item"
        />
      </MQSidebar.ListItem>
      <MQSidebar.ListItem
        as={AppLink}
        data-testid="sidebar-configure-link"
        to="#"
        prefix={<MQIcon.Svg icon="sliders" />}
        overlay={<MQIcon.Svg icon="arrow-circle-right" />}
      >
        <AppFormattedMessage
          defaultMessage="Other Menu Item"
          description="[AppSidebar.Preview] Other Menu Item label"
          id="app.sidebar.preview.other.menu.item"
        />
      </MQSidebar.ListItem>
      <MQSidebar.ListItem className="app-sidebar__list">
        <MQSidebar.List className="app-sidebar__list">
          <MQSidebar.ListItem>
            <MQSidebar.List className="app-sidebar__list">
              <MQSidebar.ListItem>
                <MQSidebar.Heading className="app-sidebar__heading">
                  <AppFormattedMessage {...APP_GLOBAL_MESSAGES.userDashBoardProgramsTabLabel} />
                  <MQSidebar.Badge className="mq-ml-scale-3">2</MQSidebar.Badge>
                </MQSidebar.Heading>
              </MQSidebar.ListItem>

              <MQSidebar.ListItem>
                <MQSidebar.ListToggle
                  eventKey={`category-1`}
                  badge={1}
                  title="Program Category"
                  className="app-sidebar__list-toggle"
                  activeEventKeys={selectedKeys}
                  setActiveEventKeys={(keys) => setSelectedKeys(keys)}
                >
                  <MQSidebar.List className="app-sidebar__list">
                    <MQSidebar.ListItem
                      as={AppLink}
                      data-testid={`sidebar-program-1-link`}
                      to="#"
                      overlay={<MQIcon.Svg icon="arrow-circle-right" />}
                      truncate
                    >
                      <AppFormattedMessage
                        defaultMessage="My Program Link"
                        description="[AppSidebar.Preview] My Program Link label"
                        id="app.sidebar.preview.my.program.link"
                      />
                    </MQSidebar.ListItem>
                  </MQSidebar.List>
                </MQSidebar.ListToggle>
              </MQSidebar.ListItem>

              <MQSidebar.ListItem>
                <MQSidebar.ListToggle
                  key="draft-programs"
                  dataTestId="program-draft-programs"
                  eventKey="draft-programs"
                  title={
                    <AppFormattedMessage
                      defaultMessage="Draft Programs"
                      description="[AppSidebar.Preview] Draft Programs label"
                      id="app.sidebar.preview.draft.programs"
                    />
                  }
                  badge={1}
                  activeEventKeys={selectedKeys}
                  setActiveEventKeys={(keys) => setSelectedKeys(keys)}
                  className="app-sidebar__list-toggle"
                  draft
                >
                  <MQSidebar.List className="app-sidebar__list">
                    <MQSidebar.ListItem
                      as={AppLink}
                      data-testid={`sidebar-draft-1-link`}
                      key={`draft-1`}
                      to="#"
                      overlay={<MQIcon.Svg icon="arrow-circle-right" />}
                      truncate
                    >
                      <AppFormattedMessage
                        defaultMessage="My Program Draft Link"
                        description="[AppSidebar.Preview] My Program Draft Link label"
                        id="app.sidebar.preview.my.program.draft.link"
                      />
                    </MQSidebar.ListItem>
                  </MQSidebar.List>
                </MQSidebar.ListToggle>
              </MQSidebar.ListItem>
            </MQSidebar.List>
          </MQSidebar.ListItem>
        </MQSidebar.List>
      </MQSidebar.ListItem>
    </MQSidebar.List>
  );
};

export default Preview;
