import { ReactNode } from 'react';

import { isEqual } from 'lodash';

import { AppAlertTypesEnum } from 'types/global';

import { uuid } from 'helpers/string';

export interface AppAlertLightDto {
  message?: ReactNode;
  body?: ReactNode;
  enableReinitialize?: boolean;
  button?: ReactNode;
  type?: AppAlertTypesEnum;
}

export interface AppAlertDto {
  message?: ReactNode;
  body?: ReactNode;
  id: string;
  variant: 'info' | 'success' | 'warning' | 'danger';
  enableReinitialize?: boolean;
  button?: ReactNode;
  type: AppAlertTypesEnum;
}

export type MQAlertListener = (alerts: AppAlertDto[]) => void;

export interface AppAlertStoreProps {
  items: AppAlertDto[];
  subscribe: (listener: MQAlertListener | null) => void;
  conclude: () => void;
  listener: MQAlertListener | null;
  addInfo: (alert: AppAlertLightDto) => string;
  addSuccess: (alert: AppAlertLightDto) => string;
  addDanger: (alert: AppAlertLightDto) => string;
  addWarning: (alert: AppAlertLightDto) => string;
  addCustom: (alert: AppAlertLightDto) => string;
  add: (alert: AppAlertDto) => string;
  reset: (id: string) => void;
  remove: (id: string) => void;
  clear: () => void;
  reload: () => void;
}

const alerts: AppAlertStoreProps = {
  items: [],
  listener: null,
  subscribe: (listener) => {
    alerts.listener = listener;
  },
  conclude: () => {
    alerts.listener = null;
  },
  reset: (id) => {
    alerts.items = alerts.items.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          enableReinitialize: false,
        };
      }
      return item;
    });
  },
  remove: (id) => {
    alerts.items = alerts.items.filter((item) => item.id !== id);
    alerts.listener?.(alerts.items);
  },
  clear: () => {
    alerts.items = [];
    alerts.listener?.(alerts.items);
  },
  reload: () => {
    alerts.items.forEach((item) => {
      if (!item.enableReinitialize) {
        alerts.remove(item.id);
      } else {
        alerts.reset(item.id);
      }
    });
  },
  add: (alert) => {
    alerts.items = alerts.items.filter((item) => !isEqual(item.message, alert.message)).concat([alert]);
    alerts.listener?.(alerts.items);
    return alert.id;
  },
  addInfo: (alert) =>
    alerts.add({
      ...alert,
      id: `mq-alert-${uuid()}`,
      variant: 'info',
      type: AppAlertTypesEnum.Basic,
    }),
  addSuccess: (alert) =>
    alerts.add({
      ...alert,
      id: `mq-alert-${uuid()}`,
      variant: 'success',
      type: AppAlertTypesEnum.Basic,
    }),
  addWarning: (alert) =>
    alerts.add({
      ...alert,
      id: `mq-alert-${uuid()}`,
      variant: 'warning',
      type: AppAlertTypesEnum.Basic,
    }),
  addDanger: (alert) =>
    alerts.add({
      ...alert,
      id: `mq-alert-${uuid()}`,
      variant: 'danger',
      type: AppAlertTypesEnum.Basic,
    }),
  addCustom: (alert) =>
    alerts.add({
      ...alert,
      id: `mq-alert-${uuid()}`,
      variant: 'danger',
      type: alert.type ?? AppAlertTypesEnum.Basic,
    }),
};

export default alerts;
