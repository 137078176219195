import { FC, ReactNode, useMemo } from 'react';

import {
  AccessDraftProgramsParamsDto,
  AccessFeaturesParamsDto,
  AccessPermissionsParamsDto,
  AccessProgramParamsDto,
  AccessProvidersParamsDto,
} from 'types/access';

import { checkIsAuthorized } from 'helpers/access';

import { useAppAuthState } from 'hooks/useAppAuthState';
import { useAppConfigs } from 'hooks/useAppConfigs';

type PermissionWrapperProps = {
  permissions?: AccessPermissionsParamsDto;
  features?: AccessFeaturesParamsDto;
  providers?: AccessProvidersParamsDto;
  programs?: AccessProgramParamsDto;
  draftPrograms?: AccessDraftProgramsParamsDto;
  fallback?: ReactNode;
  render?: (disabled: boolean) => ReactNode;
  strict?: boolean;
  children?: ReactNode;
};

const PermissionWrapper: FC<PermissionWrapperProps> = ({
  permissions = {
    value: [],
    strict: false,
    exist: true,
  },
  features = {
    value: [],
    strict: false,
    exist: true,
  },
  providers = {
    value: [],
    strict: false,
    exist: true,
  },
  programs = {
    value: [],
    strict: false,
    exist: true,
  },
  draftPrograms = {
    value: [],
    strict: false,
    exist: true,
  },
  children,
  fallback = null,
  strict = false,
  render,
}) => {
  const { privateConfigs, publicConfigs } = useAppConfigs();
  const authState = useAppAuthState();

  const isAuthorized = useMemo(
    () =>
      checkIsAuthorized(
        {
          permissions,
          features,
          providers,
          programs,
          draftPrograms,
          strict,
        },
        {
          permissions: authState.permissions ?? {},
          features: publicConfigs.features ?? [],
          adminMode: authState.mode.admin,
          vpsEnabled: privateConfigs.vps.enabled,
          profilePictureEnabled: privateConfigs.profilePictureEnabled,
          labsEnabled: privateConfigs.learningLab.enabled,
          accountEditEnabled: publicConfigs.accountEditEnabled,
          programs: authState.adminProgramIds ?? [],
          draftPrograms: authState.adminProgramDraftIds ?? [],
        },
      ),
    [
      permissions,
      features,
      providers,
      programs,
      draftPrograms,
      strict,
      authState.permissions,
      authState.mode.admin,
      authState.adminProgramIds,
      authState.adminProgramDraftIds,
      publicConfigs.features,
      publicConfigs.accountEditEnabled,
      privateConfigs.vps.enabled,
      privateConfigs.profilePictureEnabled,
      privateConfigs.learningLab.enabled,
    ],
  );

  if (render) {
    return <>{render(!isAuthorized)}</>;
  }

  if (isAuthorized) {
    return <>{children}</>;
  }

  return <>{fallback}</>;
};

export default PermissionWrapper;
