import { cloneElement, FC, ReactElement, ReactNode, useCallback, useMemo } from 'react';

import { MQIcon, MQButton, MQTooltip } from '@mentorcliq/ui';

import { FILE_STORAGE_CONFIGS } from 'definitions/files';
import { APP_GLOBAL_MESSAGES } from 'definitions/messages';


import { useAppIntl } from 'hooks/useAppIntl';

import MQUpload from 'modules/MQUpload';

import AppFormattedList from 'formatters/AppFormattedList';
import AppFormattedMessage from 'formatters/AppFormattedMessage';

interface AppFileUploadErrorProps {
  message: ReactNode;
  files: FileList;
}

type AppFileUploadTriggerTypes = 'click' | 'drop';

interface AppFileUploadProps {
  name?: string;
  children?: ReactElement;
  triggers?: AppFileUploadTriggerTypes[];
  fileName?: string;
  uploading?: boolean;
  removing?: boolean;
  onRemove?: () => void;
  onBlur?: () => void;
  onUpload?: (files: FileList) => void;
  onReject?: (data: AppFileUploadErrorProps) => void;
  type: keyof typeof FILE_STORAGE_CONFIGS;
  disabled?: boolean;
  fullWidth?: boolean;
  uploadTestId?: string;
  removeTestId?: string;
}

const AppFileUpload: FC<AppFileUploadProps> = ({
  name = 'app-file-upload-field',
  fileName = '',
  triggers = ['click', 'drop'],
  fullWidth = false,
  children,
  onUpload,
  onReject,
  type,
  onRemove,
  onBlur,
  disabled,
  uploading = false,
  removing = false,
  uploadTestId = 'app-file-upload-button',
  removeTestId = 'app-file-remove-button',
}) => {
  const intl = useAppIntl();
  const config = useMemo(() => FILE_STORAGE_CONFIGS[type], [type]);

  const upload = useCallback(
    (files: FileList) => {
      if (config?.maxSize) {
        for (const file of files) {
          if (file.size > config.maxSize) {
            onReject?.({
              files: files,
              message: intl.formatMessage(APP_GLOBAL_MESSAGES.invalidFileSize, {
                size: config.maxSize / 1000000,
              }),
            });
            return;
          }
        }
      }

      onUpload?.(files);
    },
    [config.maxSize, intl, onReject, onUpload],
  );

  const element = useMemo(() => {
    if (children) {
      return cloneElement(children, {
        ...children.props,
        onBlur,
      });
    }
  }, [children, onBlur]);

  if (!!onRemove && !!fileName) {
    return (
      <MQTooltip triggers={['visibility']} overlay={fileName}>
        <MQButton
          dataTestId={removeTestId}
          variant="danger"
          endIcon={<MQIcon.Svg icon="times" />}
          onClick={() => {
            onRemove?.();
          }}
          onBlur={() => {
            onBlur?.();
          }}
          isLoading={removing}
          className="app-file-upload__button"
          disabled={disabled || removing}
          fullWidth={fullWidth}
        >
          {fileName}
        </MQButton>
      </MQTooltip>
    );
  }

  return (
    <MQUpload
      name={name}
      triggers={triggers}
      onUpload={upload}
      extensions={config.extensions}
      onReject={(files) => {
        onReject?.({
          files: files,
          message: intl.formatMessage(APP_GLOBAL_MESSAGES.invalidFileFormat, {
            extensions: (
              <strong>
                <AppFormattedList list={config.extensions.map((item) => item.split('.').pop())} />
              </strong>
            ),
          }),
        });
      }}
    >
      {element || (
        <MQButton
          dataTestId={uploadTestId}
          variant="secondary"
          startIcon={<MQIcon.Svg icon="cloud-arrow-up__r" />}
          isLoading={uploading}
          onBlur={() => {
            onBlur?.();
          }}
          className="app-file-upload__button"
          disabled={disabled || uploading}
          fullWidth={fullWidth}
        >
          {uploading ? (
            <AppFormattedMessage
              defaultMessage="Uploading..."
              description="[AppFileUpload] Uploading text"
              id="app.file.upload.uploading"
            />
          ) : fileName ? (
            <AppFormattedMessage
              defaultMessage="Replace File"
              description="[AppFileUpload] Replace text"
              id="app.file.replace.file"
            />
          ) : (
            <AppFormattedMessage
              defaultMessage="Upload File"
              description="[AppFileUpload] Upload text"
              id="app.file.upload.file"
            />
          )}
        </MQButton>
      )}
    </MQUpload>
  );
};

export default AppFileUpload;
