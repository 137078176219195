import { FeatureTypeEnum } from '@mentorcliq/storage';

import { ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_ACCESS } from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  cliqipedia: createRoute({
    params: {
      layoutTypes: [],
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_ACCESS,
      access: {
        features: {
          value: [FeatureTypeEnum.Support],
        },
      },
    },
    data: {
      path: `/cliqipedia`,
      exact: true,
    },
  }),
};
