import ROUTES from 'routes';

export const SECTIONS_ROOT_PATHS_AND_KEYS = {
  [ROUTES.programDashboard.data.path]: [],
  [ROUTES.launch.data.path]: ['launch'],
  [ROUTES.match.data.path]: ['match'],
  [ROUTES.engage.data.path]: ['engage'],
  [ROUTES.measure.data.path]: ['measure'],
  [ROUTES.programSettings.data.path]: ['settings'],
  [ROUTES.programWizardDraft.data.path]: ['settings'],
};

export const SMART_MATCH_PATHS = [
  ROUTES.viewParticipants.data.path,
  ROUTES.adminMatches.data.path,
  ROUTES.intelligentMatching.data.path,
  ROUTES.manageMatches.data.path,
];
