import {
  DurationEnum,
  EnrollmentTriggerEventEnum,
  InvitationStyleEnum,
  ParticipantMatchingStyle,
  PredefinedReasonDto,
  ProgramLightResponseDto,
  ProgramRequestDto,
  ProgramResponseDto,
  ProgramRoleDto,
  RoleEnrollmentInfoDto,
  RoleTypesEnum,
  ScheduledMessageCommunicationConfigDto,
} from '@mentorcliq/storage';

import { getSatisfactionSurveyCustomMessage } from '__mocks/helpers';

export interface SortRoleByType {
  data: any;
  programRole: ProgramRoleDto;
}

export const sortRolesByType = (programRoles: ProgramRoleDto[]): ProgramRoleDto[] =>
  Object.values(RoleTypesEnum).flatMap((type) =>
    programRoles.filter(({ programRoleType }) => programRoleType === type),
  );

export const sortEnrollmentsByType = (enrollments: RoleEnrollmentInfoDto[]): RoleEnrollmentInfoDto[] =>
  Object.keys(RoleTypesEnum).flatMap((programRoleType) =>
    enrollments.filter(({ programRole }) => programRole.programRoleType === programRoleType),
  );

export const sortDataByRole = (data: SortRoleByType[]) => {
  const orderedData = data.toSorted((a, b) => a.programRole.name.localeCompare(b.programRole.name));

  return Object.keys(RoleTypesEnum).flatMap((programRoleType) =>
    orderedData.filter((item) => item.programRole.programRoleType === programRoleType),
  );
};

export const sortRolesByRoleIds = <
  T extends {
    programRoleId: number;
    [key: string]: any;
  },
>(
  programRoles: ProgramRoleDto[],
  items: T[],
): T[] => {
  const roleIds = sortRolesByType(programRoles).map((role) => role.id);
  return [...items].sort((prev, next) => roleIds.indexOf(prev.programRoleId) - roleIds.indexOf(next.programRoleId));
};

export const serializeProgramResponse = (request: ProgramRequestDto): ProgramResponseDto => ({
  ...request,
  id: request.id ?? -1,
  name: request.name ?? '',
  description: request.description ?? '',
  demo: false,
  retired: false,
  programCategory: request.programCategory ?? {
    id: -1,
    name: '',
  },
  programPillar: request.programPillar ?? {
    id: -1,
    name: '',
  },
  invitationStyleConfig: request.invitationStyleConfig ?? {
    id: -1,
    invitationStyle: InvitationStyleEnum.Public,
    openStyleExcludedRoleIds: [],
  },
  relationshipLength: request.relationshipLength ?? 0,
  allowManageRelationship: !!request.allowManageRelationship,
  matchingStyle: request.matchingStyle ?? ParticipantMatchingStyle.OneToOne,
  showProgramDetails: !!request.showProgramDetails,
  programRoles: request.programRoles ?? [],
  matchConfigurations: request.matchConfigurations ?? [],
  enrollmentPreferenceConfig: request.enrollmentPreferenceConfig ?? {
    questions: [],
  },
  preferencesDisplayConfigs: request.preferencesDisplayConfigs ?? [],
  goalsEnabled: !!request.goalsEnabled,
});

export const findManageMatchesOtherReason = (reasons: PredefinedReasonDto[], selectedReasonId: number) =>
  reasons.find((value) => value.other)?.id === selectedReasonId;

export const getUnMatchReason = (
  reasons: PredefinedReasonDto[],
  data: {
    id: number;
    customReason?: string;
  },
) => {
  const predefined = reasons.find((value) => value.id === data.id);

  if (predefined) {
    return predefined.reason;
  }

  const other = reasons.find((value) => value.other);

  if (other?.id === data.id) {
    return data.customReason ?? other.reason;
  }
};

export const checkSatisfactionSurveysEnabled = (communications: ScheduledMessageCommunicationConfigDto[]) => {
  const { surveyIncluded } = filterCommunicationsBySurvey(communications);
  return !!surveyIncluded.length;
};

export const generateSatisfactionSurveyConfig = (params: { userId: number; roleIds: number[]; interval: number }) => ({
  lastUpdatedUserId: params.userId,
  lastUpdatedTime: Date.now(),
  relativeDateConfig: {
    programRoleIds: params.roleIds,
    relativeDate: {
      interval: params.interval,
      period: DurationEnum.Month,
    },
    enrollmentStatusRelativeDateCriterion: EnrollmentTriggerEventEnum.Matched,
  },
  messageConfig: {
    surveyPolicy: {
      programSurveyId: -1,
      relationshipSurveyId: -1,
      reminder: {
        period: DurationEnum.Day,
        interval: params.interval,
      },
    },
    message: getSatisfactionSurveyCustomMessage(),
  },
});

export const transformProgramResponseToDetails = (data: ProgramResponseDto) => ({
  name: data.name,
  selfMatchConfigs: data.selfMatchConfigs ?? null,
  suggestionConfigurations: data.suggestionConfigurations ?? null,
  matchingStyle: data.matchingStyle ?? ParticipantMatchingStyle.OneToOne,
  programRoles: data.programRoles,
  quickcliqFrequency: {
    period: data.quickcliqConfig?.quickcliqFrequency.repeatDuration.period ?? DurationEnum.Month,
    interval: data.quickcliqConfig?.quickcliqFrequency.repeatDuration.interval ?? 0,
  },
  quickcliqRoleIds: data.quickcliqConfig?.programRoleIds ?? [],
  quickcliqAutoScheduleEnabled: !!data.quickcliqConfig?.autoScheduleEnabled,
  relationshipLength: data.relationshipLength,
  allowManageRelationship: data.allowManageRelationship,
  goalsEnabled: !!data.goalsEnabled,
  milestoneConfigs: data.milestoneConfigs ?? null,
  scheduledMessageCommunicationConfigs: data.scheduledCommunicationConfigs?.scheduledMessageCommunicationConfigs ?? [],
  matchNotificationConfigs: data.scheduledCommunicationConfigs?.matchNotificationConfigs ?? [],
  relationshipClosureConfig: data.scheduledCommunicationConfigs?.relationshipClosureConfig?.perRoleMessageConfigs ?? [],
  enrollmentCompletionConfigs: data.scheduledCommunicationConfigs?.enrollmentCompletionConfigs ?? [],
});

export const transformProgramLightResponseToDetails = (data: ProgramLightResponseDto) => ({
  name: data.name,
  selfMatchConfigs: data.selfMatchConfigs ?? null,
  suggestionConfigurations: data.suggestionConfigurations ?? null,
  matchingStyle: data.matchingStyle ?? ParticipantMatchingStyle.OneToOne,
  programRoles: data.programRoles,
  quickcliqFrequency: {
    period: data.quickcliqConfig?.quickcliqFrequency.repeatDuration.period ?? DurationEnum.Month,
    interval: data.quickcliqConfig?.quickcliqFrequency.repeatDuration.interval ?? 0,
  },
  quickcliqRoleIds: data.quickcliqConfig?.programRoleIds ?? [],
  quickcliqAutoScheduleEnabled: !!data.quickcliqConfig?.autoScheduleEnabled,
  relationshipLength: data.relationshipLength ?? 0,
  allowManageRelationship: !!data.allowManageRelationship,
  goalsEnabled: !!data.goalsEnabled,
  milestoneConfigs: null,
  scheduledMessageCommunicationConfigs: [],
  matchNotificationConfigs: [],
  relationshipClosureConfig: [],
  enrollmentCompletionConfigs: [],
});

export const filterCommunicationsBySurvey = (communications: ScheduledMessageCommunicationConfigDto[]) => ({
  surveyIncluded: communications.filter(
    (item) =>
      !!item.messageConfig.surveyPolicy?.relationshipSurveyId || !!item.messageConfig.surveyPolicy?.programSurveyId,
  ),
  surveyExcluded: communications.filter(
    (item) =>
      !item.messageConfig.surveyPolicy?.relationshipSurveyId && !item.messageConfig.surveyPolicy?.programSurveyId,
  ),
});

export const getCommunicableValidRoles = (roles: ProgramRoleDto[]) => {
  if (roles.length === 2) {
    const advisorRole = roles.find((role) => role.programRoleType === RoleTypesEnum.ADVISOR);
    const adviseeRole = roles.find((role) => role.programRoleType === RoleTypesEnum.ADVISEE);

    if (adviseeRole && advisorRole) {
      return {
        advisorRole,
        adviseeRole,
      };
    }
  }

  return null;
};
