import './style.scss';

import { ElementType, useCallback, useContext } from 'react';

import classNames from 'classnames';

import { MQDropdownItemProps } from '../../types';
import { MQNavDropdownContext } from '../MQDropdown';

const MQDropdownItem = <T extends ElementType = 'button'>({
  className = '',
  children,
  startIcon,
  endIcon,
  truncate = false,
  onClick,
  as: Component = 'button',
  ...props
}: MQDropdownItemProps<T>) => {
  const { setIsShow } = useContext(MQNavDropdownContext);

  const clickHandler = useCallback(() => {
    setIsShow?.(false);
    onClick?.();
  }, [onClick, setIsShow]);

  return (
    <Component
      role="menuitem"
      data-testid="mq-dropdown-item"
      className={classNames(['mq-dropdown__item', className, { truncate }])}
      onClick={clickHandler}
      {...props}
    >
      {startIcon && <span className="mq-dropdown__item-icon">{startIcon}</span>}
      {children && <span className="mq-dropdown__item-label">{children}</span>}
      {endIcon && <span className="mq-dropdown__item-icon">{endIcon}</span>}
    </Component>
  );
};

MQDropdownItem.displayName = 'MQDropdownItem';

export default MQDropdownItem;
