import { PUBLIC_ROUTES_LAYOUT_ITEMS, ROUTE_UNAUTHENTICATED_ACCESS } from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  externalLogin: createRoute({
    params: {
      layoutTypes: PUBLIC_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_UNAUTHENTICATED_ACCESS,
    },
    data: {
      path: `/external/login/:credentials*`,
      exact: true,
    },
  }),
  createPassword: createRoute({
    params: {
      layoutTypes: PUBLIC_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_UNAUTHENTICATED_ACCESS,
    },
    data: {
      path: `/create-password`,
      exact: true,
    },
  }),
  resetPassword: createRoute({
    params: {
      layoutTypes: PUBLIC_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_UNAUTHENTICATED_ACCESS,
    },
    data: {
      path: `/reset-password`,
      exact: true,
    },
  }),

  createAccount: createRoute({
    params: {
      layoutTypes: PUBLIC_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_UNAUTHENTICATED_ACCESS,
    },
    data: {
      path: `/create-account`,
      exact: true,
    },
  }),
  verification: createRoute({
    params: {
      layoutTypes: PUBLIC_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_UNAUTHENTICATED_ACCESS,
    },
    data: {
      path: `/verification`,
      exact: true,
    },
  }),
  login: createRoute({
    params: {
      layoutTypes: PUBLIC_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_UNAUTHENTICATED_ACCESS,
    },
    data: {
      path: `/login`,
      exact: true,
    },
  }),
  adminLogin: createRoute({
    params: {
      layoutTypes: PUBLIC_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_UNAUTHENTICATED_ACCESS,
    },
    data: {
      path: `/login/admin`,
      exact: true,
    },
  }),
  requestPassword: createRoute({
    params: {
      layoutTypes: PUBLIC_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_UNAUTHENTICATED_ACCESS,
    },
    data: {
      path: `/request-password`,
      exact: true,
    },
  }),
  quickcliqSubmission: createRoute({
    params: {
      layoutTypes: PUBLIC_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_UNAUTHENTICATED_ACCESS,
    },
    data: {
      path: `/quickcliq-submission/:token`,
      exact: true,
    },
  }),
  surveySubmission: createRoute({
    params: {
      layoutTypes: PUBLIC_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_UNAUTHENTICATED_ACCESS,
    },
    data: {
      path: `/survey-submission/:token`,
      exact: true,
    },
  }),
};
