import ROUTES from 'routes';

export const PRIVATE_ARGS = ['accessToken', 'refreshToken', 'password'];

export const IGNORE_PAGES = [
  ROUTES.statusPage403.data.path,
  ROUTES.statusPage404.data.path,
  ROUTES.statusPage429.data.path,
  ROUTES.statusPage500.data.path,
];

export const IGNORE_ERROR_MESSAGES = [
  'Invalid refresh token',
  'Jwt expired.',
  'Sorry, unrecognized username or password.',
  'Your session has expired. Please log in again to continue.',
];
