import {
  ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_ACCESS,
  ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
  PRIVATE_ROUTES_LAYOUT_ITEMS,
} from 'definitions/routes';

import { createRoute } from 'helpers/routes';

export default {
  enrollment: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
    },
    data: {
      path: `/enroll`,
    },
  }),
  enrollWizard: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
    },
    data: {
      path: '/enroll/wizard',
    },
  }),
  enrollWizardOverview: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
    },
    data: {
      path: '/enroll/wizard/overview',
      exact: true,
    },
  }),
  enrollWizardWelcome: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
    },
    data: {
      path: '/enroll/wizard/welcome/:programId',
      exact: true,
    },
  }),
  enrollWizardProgram: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_ACCESS,
    },
    data: {
      path: '/enroll/wizard/program',
      exact: true,
    },
  }),
  editPreferences: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
    },
    data: {
      path: '/enroll/preferences/:programId/:roleId/:enrollmentId',
      exact: true,
    },
  }),
  editRequestMatch: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
    },
    data: {
      path: `/enroll/request-match/:enrollmentId`,
      exact: true,
    },
  }),
  editSuggestMatch: createRoute({
    params: {
      layoutTypes: PRIVATE_ROUTES_LAYOUT_ITEMS,
      types: ROUTE_AUTHENTICATED_AND_GDPR_ACCEPTED_AND_VERIFIED_AND_ENROLLED_ACCESS,
    },
    data: {
      path: `/enroll/suggest-match/:enrollmentId`,
      exact: true,
    },
  }),
};
