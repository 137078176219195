import { FC, HTMLAttributes, ReactNode } from 'react';

interface MMQTableTdProps extends HTMLAttributes<HTMLTableCellElement> {
  width?: number | `${number}%`;
  children?: ReactNode;
  dataTestId?: string;
}

export const MQTableTd: FC<MMQTableTdProps> = ({ width, dataTestId = '', children, ...props }) => (
  <td
    style={{
      width,
    }}
    {...props}
    data-testid={dataTestId}
  >
    {children}
  </td>
);

export default MQTableTd;
