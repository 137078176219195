import { FC, useEffect, useMemo, useState } from 'react';

import {
  fetchProgramDraftsThunk,
  fetchProgramsBriefThunk,
  getAuthUserSelector,
  getDraftProgramsSelector,
  getProgramsBriefSelector,
} from '@mentorcliq/storage';
import { MQIcon } from '@mentorcliq/ui';

import ROUTES from 'routes';

import { APP_GLOBAL_MESSAGES } from 'definitions/messages';


import history from 'helpers/history';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppIntl } from 'hooks/useAppIntl';
import { useAppRouter } from 'hooks/useAppRouter';
import { useAppSelector } from 'hooks/useAppSelector';

import MQSidebar from 'modules/MQSidebar';

import AppDynamicMessage from 'formatters/AppDynamicMessage';
import AppFormattedMessage from 'formatters/AppFormattedMessage';

import AppLink from 'router/AppLink';

const Programs: FC = () => {
  const intl = useAppIntl();

  const dispatch = useAppDispatch();
  const { location } = useAppRouter();

  const user = useAppSelector(({ auth }) => getAuthUserSelector(auth));
  const draftsList = useAppSelector(({ draftPrograms }) => getDraftProgramsSelector(draftPrograms));
  const programsList = useAppSelector(({ programsBrief }) => getProgramsBriefSelector(programsBrief));

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  useEffect(() => {
    const unListen = history.mq.listen((newLocation) => {
      if (location.key !== newLocation.key) {
        setSelectedKeys([]);
      }
    });

    return () => {
      unListen();
    };
  }, [location.key, setSelectedKeys]);

  useEffect(() => {
    dispatch(fetchProgramsBriefThunk());
    dispatch(fetchProgramDraftsThunk());
  }, [dispatch]);

  const categorizedPrograms = useMemo(() => {
    const briefPrograms = programsList ?? [];
    const activePrograms = briefPrograms.filter((item) => !item.isDemo && !item.isRetired) ?? [];
    const activeCategories = Array.from(
      new Map(
        activePrograms.map((item) => [
          item.programCategory.id,
          {
            id: item.programCategory.id,
            label: item.programCategory.name,
          },
        ]),
      ).values(),
    ).toSorted((a, b) => a.label.localeCompare(b.label));

    return {
      count: briefPrograms.length,
      sections: activeCategories
        .map((item) => ({
          ...item,
          testId: `programs-category-${item.id}`,
          key: `${item.id}`,
          label: intl.dynamicMessage({
            defaultMessage: item.label,
          }),
          programs: activePrograms.filter((program) => program.programCategory.id === item.id),
        }))
        .concat([
          {
            id: -1,
            key: 'category-demo',
            testId: 'programs-category-demo',
            label: intl.formatMessage({
              defaultMessage: 'Demo',
              description: '[Admin.Sidebar] label',
            }),
            programs: briefPrograms.filter((item) => item.isDemo),
          },
          {
            id: -2,
            key: 'category-retired',
            testId: 'programs-category-retired',
            label: intl.formatMessage({
              defaultMessage: 'Retired',
              description: '[Admin.Sidebar] label',
            }),
            programs: briefPrograms.filter((item) => item.isRetired),
          },
        ])
        .filter((item) => item.programs.length),
    };
  }, [programsList, intl]);

  const draftPrograms = useMemo(() => {
    const briefDraftsList = Object.values(draftsList ?? {}).map(({ brief }) => brief);
    return {
      count: briefDraftsList.length,
      sections: briefDraftsList
        .toSorted((prev, next) => Number(next.userId === user?.id) - Number(prev.userId === user?.id))
        .map((draft) => ({
          ...draft,
          route: draft.programId
            ? ROUTES.programWizardDraftEdit.convert({
                path: {
                  programId: draft.programId,
                  draftId: draft.id,
                },
              })
            : ROUTES.programWizardDraftPreview.convert({
                path: {
                  draftId: draft.id,
                },
              }),
          label:
            draft.userId === user?.id ? (
              <AppFormattedMessage
                defaultMessage="{programName} (owner)"
                description="[AppSidebar.Dashboard] Draft Program Name"
                id="app.sidebar.dashboard.draft.program.name"
                values={{
                  programName: draft.programName,
                }}
              />
            ) : (
              draft.programName
            ),
        })),
    };
  }, [draftsList, user?.id]);

  return (
    <MQSidebar.ListItem>
      <MQSidebar.List className="app-sidebar__list">
        {!!categorizedPrograms.sections.length && (
          <MQSidebar.ListItem>
            <MQSidebar.List className="app-sidebar__list">
              <MQSidebar.ListItem>
                <MQSidebar.Heading className="app-sidebar__heading">
                  <AppFormattedMessage {...APP_GLOBAL_MESSAGES.userDashBoardProgramsTabLabel} />
                  <MQSidebar.Badge>{categorizedPrograms.count}</MQSidebar.Badge>
                </MQSidebar.Heading>
              </MQSidebar.ListItem>

              {categorizedPrograms.sections.map((item) => (
                <MQSidebar.ListItem key={item.id}>
                  <MQSidebar.ListToggle
                    eventKey={item.key}
                    badge={item.programs.length}
                    title={item.label}
                    dataTestId={item.testId}
                    activeEventKeys={selectedKeys}
                    setActiveEventKeys={(keys) => setSelectedKeys(keys)}
                    className="app-sidebar__list-toggle"
                  >
                    <MQSidebar.List className="app-sidebar__list">
                      {item.programs.map((program) => (
                        <MQSidebar.ListItem
                          as={AppLink}
                          data-testid={`sidebar-program-${[program.id]}-link`}
                          key={program.id}
                          to={ROUTES.programDashboard.convert({
                            path: {
                              programId: program.id,
                            },
                          })}
                          overlay={<MQIcon.Svg icon="arrow-circle-right" />}
                          truncate
                        >
                          <AppDynamicMessage defaultMessage={program.name} />
                        </MQSidebar.ListItem>
                      ))}
                    </MQSidebar.List>
                  </MQSidebar.ListToggle>
                </MQSidebar.ListItem>
              ))}
            </MQSidebar.List>
          </MQSidebar.ListItem>
        )}

        {!!draftPrograms.sections?.length && (
          <MQSidebar.ListItem>
            <MQSidebar.ListToggle
              eventKey="draft-programs"
              dataTestId="program-draft-programs"
              title={intl.formatMessage({
                defaultMessage: 'Draft Programs',
                description: '[Admin.Sidebar] label',
              })}
              badge={draftPrograms.sections.length}
              activeEventKeys={selectedKeys}
              setActiveEventKeys={(keys) => setSelectedKeys(keys)}
              className="app-sidebar__list-toggle"
              draft
            >
              <MQSidebar.List className="app-sidebar__list">
                {draftPrograms.sections.map((draft) => (
                  <MQSidebar.ListItem
                    as={AppLink}
                    data-testid={`sidebar-draft-${draft.id}-link`}
                    key={`draft-${draft.id}`}
                    to={draft.route}
                    overlay={<MQIcon.Svg icon="arrow-circle-right" />}
                    truncate
                  >
                    {draft.label}
                  </MQSidebar.ListItem>
                ))}
              </MQSidebar.List>
            </MQSidebar.ListToggle>
          </MQSidebar.ListItem>
        )}
      </MQSidebar.List>
    </MQSidebar.ListItem>
  );
};

export default Programs;
