import './style.scss';

import { FC, useState, useEffect, useRef, createContext, useCallback } from 'react';

import classNames from 'classnames';

import { MQDropdownProps, MQDropdownTriggersProps } from '../../types';

export interface MQNavDropdownContextProps {
  isShow?: boolean;
  setIsShow?: (show: boolean) => void;
  triggers?: MQDropdownTriggersProps[];
}

export const MQNavDropdownContext = createContext<MQNavDropdownContextProps>({});

const MQDropdown: FC<MQDropdownProps> = ({ children, className = '', variant = 'primary', show, triggers }) => {
  const [isShow, setIsShow] = useState(show || false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleOnFocus = (e: FocusEvent) => {
      if (e.target instanceof Node && !containerRef.current?.contains(e.target)) {
        setIsShow(false);
      }
    };

    const handleOnClick = (e: MouseEvent) => {
      if (!containerRef.current?.contains(e.target)) {
        setIsShow(false);
      }
    };

    document.addEventListener('focus', (e) => handleOnFocus(e), true);
    document.addEventListener('click', handleOnClick);

    return () => {
      document.removeEventListener('focus', (e) => handleOnFocus(e), true);
      document.removeEventListener('click', handleOnClick);
    };
  }, []);

  const mouseEnter = useCallback(() => {
    if (triggers?.includes('hover')) {
      setIsShow(true);
    }
  }, [triggers]);

  const mouseLeave = useCallback(() => {
    if (triggers?.includes('hover')) {
      setIsShow(false);
    }
  }, [triggers]);

  return (
    <MQNavDropdownContext.Provider value={{ isShow, setIsShow, triggers }}>
      <div
        data-testid="mq-dropdown"
        ref={containerRef}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        className={classNames(['mq-nav-dropdown', className, variant], { show: isShow })}
      >
        {children}
      </div>
    </MQNavDropdownContext.Provider>
  );
};

export default MQDropdown;
