import { FC, ReactNode, useMemo } from 'react';

import { useAppIntl } from 'hooks/useAppIntl';

interface RenderElDataProps {
  item: ReactNode;
  index: number;
  key: number | string;
}

interface AppFormattedListProps {
  list?: ReactNode[] | null;
  suffix?: ReactNode;
  separator?: ReactNode;
  renderEl?: (data: RenderElDataProps) => ReactNode;
  empty?: ReactNode;
}

const AppFormattedList: FC<AppFormattedListProps> = ({ list, empty, suffix, separator = ',', renderEl }) => {
  const intl = useAppIntl();
  const newItems = useMemo(() => {
    const items = list ? [...list].filter((item) => !!item) : [];

    const newList = [];

    if (items?.length) {
      const last = items.pop();
      let key = 0;
      items.forEach((item, index) => {
        newList.push(renderEl?.({ item, index, key: ++key }) || item);

        if (index !== items.length - 1) {
          if (suffix) {
            newList.push(` ${suffix} `);
          } else {
            newList.push(`${separator} `);
          }
        }
      });

      if (items.length) {
        newList.push(
          ` ${
            suffix ??
            intl.formatMessage({
              id: 'global.list.items.separator',
              defaultMessage: 'and',
              description: '[Separator] separate last member',
            })
          } `,
        );
      }

      newList.push(last);
    }
    return newList;
  }, [list, renderEl, separator, suffix, intl]);

  if (newItems.length) {
    return newItems;
  } else if (empty) {
    return empty;
  }

  return null;
};

export default AppFormattedList;
