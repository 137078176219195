import { AxiosError } from 'axios';

import ROUTES from 'routes';

import { AppAlertTypesEnum } from 'types/global';

export const APP_ERRORS = {
  badRequest: {
    codes: [400],
    alert: AppAlertTypesEnum.Basic,
    redirect: null,
  },
  notAccess: {
    codes: [403],
    redirect: ROUTES.statusPage403.data.path,
    alert: null,
  },
  notFound: {
    codes: [404],
    redirect: ROUTES.statusPage404.data.path,
    alert: null,
  },
  mismatch: {
    codes: [409],
    redirect: null,
    alert: AppAlertTypesEnum.Mismatch,
  },
  tooManyRequest: {
    codes: [429],
    redirect: ROUTES.statusPage429.data.path,
    alert: null,
  },
  internalProblem: {
    codes: [0, 500, 501, 502, 503, 504, 505, 506, 507, 508, 509, 510, 511, AxiosError.ERR_NETWORK],
    redirect: ROUTES.statusPage500.data.path,
    alert: null,
  },
};
