import './style.scss';

import { ReactNode, useMemo } from 'react';

import { MQPageable } from '@mentorcliq/ui';
import classesNames from 'classnames';

import { PageableDto } from 'types/global';

import { PAGEABLE_SIZE } from 'definitions/configs';

export interface AppDataListProps<T> {
  className?: string;
  data: T[] | null;
  renderItem: (value: RenderItemProps<T>) => ReactNode;
  renderRow?: (content: ReactNode) => ReactNode;
  renderSkeleton?: (index: number) => ReactNode;
  renderCount?: number;
  empty: ReactNode;
  loading: boolean;
  pageable?: PageableDto;
}

export interface RenderItemProps<T> {
  key: number | string;
  item: T | null;
  index: number;
  loading: boolean;
  renderSkeleton?: (index: number) => ReactNode;
}

const AppDataList = <T,>({
  className = '',
  data,
  loading,
  pageable,
  renderRow,
  renderItem,
  renderSkeleton,
  renderCount = PAGEABLE_SIZE,
  empty,
  ...props
}: AppDataListProps<T>) => {
  const list = useMemo(() => data || [], [data]);

  const skeletons = useMemo(() => {
    if (loading) {
      return new Array(Math.max(renderCount - list.length, 0)).fill(null);
    }

    return [];
  }, [list.length, loading, renderCount]);

  const content = useMemo(() => {
    let key = 0;
    const dataRows = list.concat(skeletons).map((item, index) =>
      renderItem({
        key: ++key,
        index,
        item,
        loading,
        renderSkeleton: (index) => renderSkeleton?.(index) || null,
      }),
    );

    return renderRow?.(dataRows) || dataRows;
  }, [list, loading, renderItem, renderRow, renderSkeleton, skeletons]);

  return (
    <section data-testid="app-data-list" className={classesNames('app-data-list', className)} {...props}>
      <div className="app-data-list__body">
        {!!data && !data?.length && !loading ? <div className="app-data-list__empty">{empty}</div> : content}
      </div>

      {pageable && pageable.totalElements > pageable.pageSize && (
        <div className="app-data-list__pagination">
          <MQPageable
            data-testid="app-data-list-pagination"
            loading={loading}
            totalElements={pageable.totalElements}
            pageSize={pageable.pageSize}
            onPaginate={pageable.onPaginate}
            currentPage={pageable.currentPage}
          />
        </div>
      )}
    </section>
  );
};

export default AppDataList;
