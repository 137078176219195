import { SECTIONS_ROOT_PATHS_AND_KEYS } from '../definitions';

export const getSelectedSections = (path: string) =>
  Object.entries(SECTIONS_ROOT_PATHS_AND_KEYS).flatMap(([key, paths]) => {
    if (path.includes(key)) {
      return paths;
    }

    return [];
  });
