import './styles.scss';

import { FC } from 'react';

import { MQIcon, MQLoader, MQTypo } from '@mentorcliq/ui';
import { format } from 'date-fns';

import { TIME_HOURS_MINUTES_WITH_AM_PM } from 'definitions/configs';
import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import { useAppIntl } from 'hooks/useAppIntl';

import MQLayout from 'modules/MQLayout';

interface Props {
  assistantTypeLabel: string;
}

const LoadingMessage: FC<Props> = ({ assistantTypeLabel }) => {
  const intl = useAppIntl();

  const loadingMessages = [
    APP_GLOBAL_MESSAGES.loadingMessage1,
    APP_GLOBAL_MESSAGES.loadingMessage2,
    APP_GLOBAL_MESSAGES.loadingMessage3,
    APP_GLOBAL_MESSAGES.loadingMessage4,
    APP_GLOBAL_MESSAGES.loadingMessage5,
    APP_GLOBAL_MESSAGES.loadingMessage6,
    APP_GLOBAL_MESSAGES.loadingMessage7,
    APP_GLOBAL_MESSAGES.loadingMessage8,
    APP_GLOBAL_MESSAGES.loadingMessage9,
    APP_GLOBAL_MESSAGES.loadingMessage10,
    APP_GLOBAL_MESSAGES.loadingMessage11,
    APP_GLOBAL_MESSAGES.loadingMessage12,
    APP_GLOBAL_MESSAGES.loadingMessage13,
    APP_GLOBAL_MESSAGES.loadingMessage14,
    APP_GLOBAL_MESSAGES.loadingMessage15,
    APP_GLOBAL_MESSAGES.loadingMessage16,
    APP_GLOBAL_MESSAGES.loadingMessage17,
    APP_GLOBAL_MESSAGES.loadingMessage18,
    APP_GLOBAL_MESSAGES.loadingMessage19,
    APP_GLOBAL_MESSAGES.loadingMessage20,
    APP_GLOBAL_MESSAGES.loadingMessage21,
    APP_GLOBAL_MESSAGES.loadingMessage22,
  ];

  return (
    <div className="cliq-ai-loading-message">
      <MQLayout.Heading
        variant="neutral"
        info={<MQTypo.Caption>{format(Date.now(), TIME_HOURS_MINUTES_WITH_AM_PM)}</MQTypo.Caption>}
      >
        <MQTypo.Formatter
          prefix={
            <div className="cliq-ai-loading-message__icon">
              <MQIcon.Svg size="lg" icon="sparkles" />
            </div>
          }
        >
          <MQTypo.Text size="3x" bold nowrap>
            {assistantTypeLabel}
          </MQTypo.Text>
        </MQTypo.Formatter>
      </MQLayout.Heading>
      <MQLoader.Thinking messages={loadingMessages.map((message) => intl.formatMessage(message))} />
    </div>
  );
};

export default LoadingMessage;
