import './style.scss';

import { FC, useEffect, useMemo } from 'react';

import { FileEntityDto, getFileSelector, getFileThunk } from '@mentorcliq/storage';
import { MQAudio, MQImage, MQIcon, MQButton, MQTypo, MQVideo } from '@mentorcliq/ui';

import { AppFileActionProps } from 'types/files';

import {
  AUDIO_FILE_UPLOAD_EXTENSIONS,
  DOCUMENT_FILE_UPLOAD_EXTENSIONS,
  IMAGE_FILE_UPLOAD_EXTENSIONS,
  VIDEO_FILE_UPLOAD_EXTENSIONS,
} from 'definitions/files';
import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import { getFileExtension } from 'helpers/files';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';

import AppFormattedMessage from 'formatters/AppFormattedMessage';
import AppFormattedNumber from 'formatters/AppFormattedNumber';

interface AppFileDownloadProps {
  fileId: number;
  name: string;
  uuid: string;
}

interface AppFilePreviewProps {
  uuid: string;
  id: number;
  data?: FileEntityDto | null;
  showInfo?: boolean;
  cover?: boolean;
  height?: number;
  width?: number;
  onRemove?: (data: AppFileActionProps) => void;
  onReload?: (data: AppFileActionProps) => void;
  onPreview?: (data: AppFileActionProps) => void;
  onDownLoad?: (data: AppFileDownloadProps) => void;
  disabled?: boolean;
}

const AppFilePreview: FC<AppFilePreviewProps> = ({
  data,
  uuid,
  id,
  height,
  width,
  showInfo = false,
  onDownLoad,
  onRemove,
  onReload,
  onPreview,
  cover = false,
  disabled,
}) => {
  const dispatch = useAppDispatch();
  const file = useAppSelector(({ files }) => getFileSelector(files, uuid));
  const result = useMemo(() => file || data, [data, file]);

  const formattedFileSize = useMemo(() => {
    const MaxBytesRender = 1024;
    const maxKilobyteRender = 1024 * 1024;
    if (file?.fileSizeBytes < MaxBytesRender) {
      return (
        <AppFormattedMessage
          defaultMessage="{size} Bytes"
          description="[File size bytes]"
          id="app.file.preview.bytes"
          values={{
            size: <AppFormattedNumber number={file?.fileSizeBytes} />,
          }}
        />
      );
    } else if (file?.fileSizeBytes < maxKilobyteRender) {
      return (
        <AppFormattedMessage
          defaultMessage="{size} KB"
          description="[File size kb]"
          id="app.file.preview.kb"
          values={{
            size: (
              <AppFormattedNumber
                number={file?.fileSizeBytes / MaxBytesRender}
                options={{ maximumFractionDigits: 2 }}
              />
            ),
          }}
        />
      );
    } else {
      return (
        <AppFormattedMessage
          defaultMessage="{size} MB"
          description="[File size mb]"
          id="app.file.preview.mb"
          values={{
            size: (
              <AppFormattedNumber
                number={file?.fileSizeBytes / maxKilobyteRender}
                options={{ maximumFractionDigits: 2 }}
              />
            ),
          }}
        />
      );
    }
  }, [file?.fileSizeBytes]);

  useEffect(() => {
    if (!file?.uuid && uuid) {
      if (onReload) {
        onReload({
          uuid,
          id,
        });
      } else {
        dispatch(
          getFileThunk({
            uuid,
          }),
        );
      }
    }
  }, [dispatch, uuid, file?.uuid, onReload, id]);

  const preview = useMemo(() => {
    if (result) {
      const extension = getFileExtension({
        url: result.fileHandle,
      });

      const actions = {
        download: {
          disabled: !onDownLoad,
          fn: () =>
            onDownLoad?.({
              fileId: result.id,
              name: result.name,
              uuid: result.uuid,
            }),
        },
        preview: {
          fn: () =>
            onPreview?.({
              uuid: result.uuid,
              id: result.id,
            }),
          disabled: !onPreview,
        },
        remove: {
          disabled: !onRemove,
          fn: () =>
            onRemove?.({
              uuid,
              id,
            }),
        },
      };

      if (extension) {
        if (Object.keys(IMAGE_FILE_UPLOAD_EXTENSIONS).includes(extension)) {
          return {
            image: (
              <MQImage
                width={width ?? '100%'}
                height={height ?? 'auto'}
                src={result.fileHandle}
                alt={result.name}
                cover={cover}
              />
            ),
            info: result,
            bytes: formattedFileSize,
            cover: false,
            actions,
          };
        } else if (Object.keys(DOCUMENT_FILE_UPLOAD_EXTENSIONS).includes(extension)) {
          return {
            file: <MQIcon.Svg icon="file-lines" size="2x" />,
            info: result,
            bytes: formattedFileSize,
            cover: true,
            actions,
          };
        } else if (Object.keys(VIDEO_FILE_UPLOAD_EXTENSIONS).includes(extension)) {
          return {
            video: (
              <MQVideo
                width={width ?? '100%'}
                height={height ?? '100%'}
                url={result.fileHandle}
                controls
                allowFullScreen
              />
            ),
            info: result,
            bytes: formattedFileSize,
            cover: false,
            actions,
          };
        } else if (Object.keys(AUDIO_FILE_UPLOAD_EXTENSIONS).includes(extension)) {
          return {
            audio: <MQAudio url={result.fileHandle} />,
            info: result,
            bytes: formattedFileSize,
            cover: false,
            actions,
          };
        }
      }
    }
  }, [result, onDownLoad, onPreview, onRemove, uuid, id, width, height, cover, formattedFileSize]);

  if (preview) {
    return (
      <div className="app-file-preview">
        {!!preview.file && (
          <button
            type="button"
            className="app-file-preview__file"
            onClick={preview.actions.download.fn}
            disabled={disabled || preview.actions.download.disabled}
          >
            {preview.file}
          </button>
        )}
        {!!preview.image && (
          <button
            type="button"
            className="app-file-preview__image"
            onClick={preview.actions.preview.fn}
            disabled={disabled || preview.actions.preview.disabled}
          >
            {preview.image}
          </button>
        )}
        {!!preview.audio && <div className="app-file-preview__audio">{preview.audio}</div>}
        {!!preview.video && <div className="app-file-preview__video">{preview.video}</div>}
        {!!preview.info && showInfo && (
          <div className="app-file-preview__body">
            <div className="app-file-preview__info" data-testid="app-file-preview-info">
              <MQTypo.Text bold nowrap>
                {preview.info.name}
              </MQTypo.Text>
              <MQTypo.Text italic nowrap>
                {formattedFileSize}
              </MQTypo.Text>
              {!!preview?.cover && !preview.actions.download.disabled && (
                <button
                  type="button"
                  className="app-file-preview__cover"
                  onClick={preview.actions.download.fn}
                  disabled={disabled}
                >
                  <MQIcon.Svg icon="download__r" />
                  <AppFormattedMessage {...APP_GLOBAL_MESSAGES.globalDownloadLabel} />
                </button>
              )}
            </div>
            {!preview.actions.remove.disabled && (
              <MQButton
                dataTestId="remove-file"
                className="app-file-preview__button"
                variant="danger"
                startIcon={<MQIcon.Svg icon="xmark-circle__r" />}
                onClick={preview.actions.remove.fn}
                shape="square"
                type="button"
                disabled={disabled}
              />
            )}
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default AppFilePreview;
