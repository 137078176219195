import { AccessTokenScopesEnum } from '@mentorcliq/storage';
import { defineMessage } from 'react-intl';

import ROUTES from 'routes';

import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import { generateLink } from 'helpers/routes';
import { generateToken } from 'helpers/tokens';

export const APP_MESSAGE_TOKENS = {
  userName: generateToken({
    name: 'userName',
    label: '@user_name',
    description: APP_GLOBAL_MESSAGES.useNameTokenDescription,
    linkRestricted: true,
  }),
  siteName: generateToken({
    name: 'siteName',
    label: '[site-name]',
    description: APP_GLOBAL_MESSAGES.siteNameTokenDescription,
  }),
  partnerName: generateToken({
    name: 'partnerName',
    label: '@partner_name',
    description: APP_GLOBAL_MESSAGES.partnerNameTokenDescription,
  }),
  partnerNames: generateToken({
    name: 'partnerNames',
    label: '@partner_names',
    description: APP_GLOBAL_MESSAGES.partnerNamesTokenDescription,
    linkRestricted: true,
  }),
  participant: generateToken({
    name: 'participant',
    label: '@participant',
    description: APP_GLOBAL_MESSAGES.participantTokenDescription,
  }),
  surveyInbox: generateToken({
    name: 'surveyInbox',
    label: '[mentorcliq-survey-inbox-link]',
    isLink: true,
    description: APP_GLOBAL_MESSAGES.surveyInboxTokenDescription,
  }),
  leaveRelationship: generateToken({
    name: 'leaveRelationship',
    label: '[mentorcliq-leave-relationship-link]',
    isLink: true,
    description: APP_GLOBAL_MESSAGES.leaveRelationshipTokenDescription,
    linkRestricted: true,
  }),
  participantName: generateToken({
    name: 'participantName',
    label: '[mentorcliq-participant-name]',
    description: APP_GLOBAL_MESSAGES.participantNameTokenDescription,
    linkRestricted: true,
  }),
  approvalLink: generateToken({
    name: 'approvalLink',
    label: '[mentorcliq-approval-link]',
    isLink: true,
    description: APP_GLOBAL_MESSAGES.approvalLinkTokenDescription,
    linkRestricted: true,
  }),
  approvalDeclineMessage: generateToken({
    name: 'approvalDeclineMessage',
    label: '[mentorcliq-approval-decline-message]',
    description: APP_GLOBAL_MESSAGES.approvalLinkTokenDescription,
  }),
  programName: generateToken({
    name: 'programName',
    label: '!program_name',
    description: APP_GLOBAL_MESSAGES.programNameTokenDescription,
    linkRestricted: true,
  }),
  programNameHtmlEscaped: generateToken({
    name: 'programNameHtmlEscaped',
    label: '@program_name',
    description: null,
  }),
  roleTitle: generateToken({
    name: 'roleTitle',
    label: '!role_title',
    description: APP_GLOBAL_MESSAGES.roleTitleTokenDescription,
    linkRestricted: true,
  }),
  appliedRole: generateToken({
    name: 'appliedRole',
    label: '[applied-role]',
    description: null,
  }),
  programDescription: generateToken({
    name: 'programDescription',
    label: '[!program-description]',
    description: APP_GLOBAL_MESSAGES.programDescriptionTokenDescription,
    linkRestricted: true,
  }),
  interval: generateToken({
    name: 'interval',
    label: '@interval',
    description: APP_GLOBAL_MESSAGES.intervalTokenDescription,
    linkRestricted: true,
  }),
  dueDate: generateToken({
    name: 'dueDate',
    label: '@due_date',
    description: APP_GLOBAL_MESSAGES.dueDateTokenDescription,
    linkRestricted: true,
  }),
  surveyLink: generateToken({
    name: 'surveyLink',
    label: '!survey_link',
    description: APP_GLOBAL_MESSAGES.surveyLinkTokenDescription,
    linkRestricted: true,
  }),
  circleName: generateToken({
    name: 'circleName',
    label: '[@circle-name]',
    description: APP_GLOBAL_MESSAGES.circleNameTokenDescription,
    linkRestricted: true,
  }),
  profileLink: generateToken({
    name: 'profileLink',
    label: '!profile_link',
    description: APP_GLOBAL_MESSAGES.profilePageLinkTokenDescription,
    linkRestricted: true,
  }),
  matchCompatibility: generateToken({
    name: 'matchCompatibility',
    label: '[mentorcliq-match-compatibility]',
    description: APP_GLOBAL_MESSAGES.matchCompatibilityTokenDescription,
    additionalInfo: APP_GLOBAL_MESSAGES.matchCompatibilityTokenInfo,
  }),
  requesterName: generateToken({
    name: 'requesterName',
    label: '@requester_name',
    description: APP_GLOBAL_MESSAGES.requesterNameTokenDescription,
    linkRestricted: true,
  }),
  requesterNameAsIs: generateToken({
    name: 'requesterNameAsIs',
    label: '!requester_name',
    description: null,
  }),
  requesteeName: generateToken({
    name: 'requesteeName',
    label: '@requestee_name',
    description: APP_GLOBAL_MESSAGES.requesteeNameTokenDescription,
    linkRestricted: true,
  }),
  messageToRequestee: generateToken({
    name: 'messageToRequestee',
    label: '!message_to_requestee',
    description: APP_GLOBAL_MESSAGES.messageToRequesteeTokenDescription,
    linkRestricted: true,
  }),
  matchRequestExpiresDays: generateToken({
    name: 'matchRequestExpiresDays',
    label: '!match_request_expires_days',
    description: APP_GLOBAL_MESSAGES.matchRequestExpiresDaysTokenDescription,
    linkRestricted: true,
  }),
  acceptLink: generateToken({
    name: 'acceptLink',
    label: '[mentorcliq-accept-link]',
    isLink: true,
    description: APP_GLOBAL_MESSAGES.acceptLinkTokenDescription,
  }),
  declineLink: generateToken({
    name: 'declineLink',
    label: '[mentorcliq-decline-link]',
    isLink: true,
    description: APP_GLOBAL_MESSAGES.declineLinkTokenDescription,
  }),
  resourceLibrary: generateToken({
    name: 'resourceLibrary',
    label: '!resource_library',
    description: APP_GLOBAL_MESSAGES.resourceLibraryTokenDescription,
    linkRestricted: true,
  }),
  resourceLibraryPath: generateToken({
    name: 'resourceLibraryPath',
    label: '[resource_library_path]',
    description: APP_GLOBAL_MESSAGES.resourceLibraryPathTokenDescription,
    linkRestricted: true,
    value: generateLink({
      host: window.location.origin,
      path: ROUTES.resourceLibraryFile.data.extraPath,
    }),
  }),
  requested: generateToken({
    name: 'requested',
    label: '@requested',
    description: APP_GLOBAL_MESSAGES.requestedNameTokenDescription,
    linkRestricted: true,
  }),
  noteFromRequester: generateToken({
    name: 'noteFromRequester',
    label: '!note_from_requested',
    description: APP_GLOBAL_MESSAGES.noteFromRequesterTokenDescription,
    linkRestricted: true,
  }),
  requestAnotherMatchLink: generateToken({
    name: 'requestAnotherMatchLink',
    label: '[mentorcliq-request-another-match-link]',
    description: APP_GLOBAL_MESSAGES.requestAnotherMatchLinkTokenDescription,
  }),
  name: generateToken({
    name: 'name',
    label: '@name',
    description: APP_GLOBAL_MESSAGES.requesterNameTokenDescription,
    linkRestricted: true,
  }),
  groupMatchDetails: generateToken({
    name: 'groupMatchDetails',
    label: '[group:match-details]',
    description: APP_GLOBAL_MESSAGES.groupMatchDetailsTokenDescription,
  }),
  loginLink: generateToken({
    name: 'loginLink',
    label: '[mentorcliq-login-link]',
    isLink: true,
    description: APP_GLOBAL_MESSAGES.loginLinkTokenDescription,
  }),
  myQueueLink: generateToken({
    name: 'myQueueLink',
    label: '[mentorcliq-myqueue-link]',
    isLink: true,
    description: null,
  }),
  groupOtherMembers: generateToken({
    name: 'groupOtherMembers',
    label: '[mentorcliq-group-other-members]',
    description: APP_GLOBAL_MESSAGES.groupOtherMembersTokenDescription,
  }),
  mentorcliqSurveyLink: generateToken({
    name: 'mentorcliqSurveyLink',
    label: '[mentorcliq-survey-link]',
    isLink: true,
    description: APP_GLOBAL_MESSAGES.mentorcliqSurveyLinkTokenDescription,
  }),
  enrollmentLink: generateToken({
    name: 'enrollmentLink',
    label: '[mentorcliq-enrollment-link]',
    isLink: true,
    description: APP_GLOBAL_MESSAGES.enrollmentLinkTokenDescription,
  }),
  milestoneLink: generateToken({
    name: 'milestoneLink',
    label: '[mentorcliq-milestone-link]',
    isLink: true,
    description: APP_GLOBAL_MESSAGES.milestoneLinkTokenDescription,
  }),
  eventLink: generateToken({
    name: 'eventLink',
    label: '[mentorcliq-event-link]',
    isLink: true,
    description: null,
  }),
  developmentTimelineLink: generateToken({
    name: 'developmentTimelineLink',
    label: '[mentorcliq-development-timeline-link]',
    isLink: true,
    description: APP_GLOBAL_MESSAGES.developmentTimelineLinkTokenDescription,
  }),
  matchDetails: generateToken({
    name: 'matchDetails',
    label: '[mentorcliq-match-details]',
    description: null,
  }),
  mentorcliqProgramName: generateToken({
    name: 'mentorcliqProgramName',
    label: '[mentorcliq-program-name]',
    description: APP_GLOBAL_MESSAGES.mentorcliqProgramNameTokenDescription,
  }),
  mentorcliqPlatformName: generateToken({
    name: 'mentorcliqPlatformName',
    label: '[mentorcliq-platform-name]',
    description: APP_GLOBAL_MESSAGES.mentorcliqPlatformNameTokenDescription,
  }),
  retiredUserName: generateToken({
    name: 'retiredUserName',
    label: '[mentorcliq-retired-user-name]',
    description: null,
  }),
  mentorcliqProgramDescription: generateToken({
    name: 'mentorcliqProgramDescription',
    label: '[mentorcliq-program-description]',
    description: null,
  }),
  userProgramRole: generateToken({
    name: 'userProgramRole',
    label: '[mentorcliq-user-program-role]',
    description: null,
  }),
  userProgramRolePlural: generateToken({
    name: 'userProgramRolePlural',
    label: '[mentorcliq-user-program-role-plural]',
    description: null,
  }),
  gettingStarted: generateToken({
    name: 'gettingStarted',
    label: '[mentorcliq-getting-started]',
    description: null,
  }),
  partnershipAgreement: generateToken({
    name: 'partnershipAgreement',
    label: '[mentorcliq-partnership-agreement]',
    description: null,
  }),
  quickcliqPeriodSummary: generateToken({
    name: 'quickcliqPeriodSummary',
    label: '!quickcliq_period_summary',
    description: APP_GLOBAL_MESSAGES.quickcliqPeriodSummaryTokenDescription,
    linkRestricted: true,
  }),
  enrollmentSummary: generateToken({
    name: 'enrollmentSummary',
    label: '[enrollment-summary]',
    description: null,
  }),
  welcomeForm: generateToken({
    name: 'welcomeForm',
    label: '[welcome-form]...[/welcome-form]',
    description: null,
  }),
  note: generateToken({
    name: 'note',
    label: '!note',
    description: null,
  }),
  investedHours: generateToken({
    name: 'investedHours',
    label: '@hours',
    description: null,
  }),
  matchMembers: generateToken({
    name: 'matchMembers',
    label: '@members',
    description: null,
  }),
  mentorcliqLoginLink: generateToken({
    name: 'mentorcliqLoginLink',
    label: '[mentorcliq-login-link]',
    isLink: true,
    description: null,
  }),
  mentorcliqDomainToken: generateToken({
    name: 'mentorcliqDomainToken',
    label: '[mc_domain_token]',
    description: null,
  }),
  mentorcliqQuickcliqLink: generateToken({
    name: 'mentorcliqQuickcliqLink',
    label: '[mentorcliq-quickcliq-link]',
    isLink: true,
    description: APP_GLOBAL_MESSAGES.mentorcliqQuickcliqLinkTokenDescription,
  }),
  enrollmentPreferencesLink: generateToken({
    name: 'enrollmentPreferencesLink',
    label: '[enrollment-preferences-link]',
    isLink: true,
    description: APP_GLOBAL_MESSAGES.enrollmentPreferencesLinkTokenDescription,
  }),
};

export const ACCESS_TOKENS_SCOPES = {
  [AccessTokenScopesEnum.GetUser]: defineMessage({
    defaultMessage: 'Read user',
    description: '[AccessTokens] Read user scope',
    id: 'access.tokens.get.user.scope',
  }),
  [AccessTokenScopesEnum.ManageUser]: defineMessage({
    defaultMessage: 'Manage user',
    description: '[AccessTokens] Manage user scope',
    id: 'access.tokens.manage.user.scope',
  }),
};
