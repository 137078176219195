import './style.scss';

import { FC, useCallback, useMemo } from 'react';

import { FileEntityDto, getChatFileThunk, UserBasicInfoDto } from '@mentorcliq/storage';
import { MQTypo } from '@mentorcliq/ui';
import classNames from 'classnames';
import { format } from 'date-fns';

import { AppFileActionProps } from 'types/files';

import { TIME_HOURS_MINUTES_WITH_AM_PM } from 'definitions/configs';

import { downloadChatFile } from 'helpers/files';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppFiles } from 'hooks/useAppFiles';

import MQAvatar from 'modules/MQAvatar';
import MQLayout from 'modules/MQLayout';

import AppFilePreview from 'components/AppFilePreview';
import AppLinkPreview from 'components/AppLinkPreview';

interface ChatMessageProps {
  authorBasicInfo: UserBasicInfoDto;
  attachment?: FileEntityDto | null;
  created: number;
  message: string;
  unread?: boolean;
}

const ChatMessage: FC<ChatMessageProps> = ({ authorBasicInfo, attachment, message, created, unread = false }) => {
  const dispatch = useAppDispatch();
  const { previewChatFile } = useAppFiles();

  const parsed = useMemo(() => {
    const initialMatches = message?.match(/(\b(https?|ftp):\/\/[-A-Z0-9+&@#%?=~_|!:,.;]*[-A-Z0-9+&@#%=~_|])/gim) || [];
    const currentMatches = message?.match(/(^|[^])(www\.\S+(\b|$))/gim) || [];
    const finalMatches = [...initialMatches, ...currentMatches].reduce<Record<string, string>>(
      (acc, value, index) => ({
        ...acc,
        [`{${index}}`]: value,
      }),
      {},
    );
    if (finalMatches) {
      const parsed = Object.entries(finalMatches).reduce((acc, [key, value]) => acc.replace(value, key), message);
      return parsed
        ?.split(/(\{\d+})/g)
        .filter(Boolean)
        .map((part) => {
          if (finalMatches[part]) {
            return <AppLinkPreview key={part} url={finalMatches[part]} />;
          }
          return part;
        });
    }
    return message;
  }, [message]);

  const handleReload = useCallback(
    (data: AppFileActionProps) =>
      dispatch(
        getChatFileThunk({
          attachmentId: data.id,
        }),
      ),
    [dispatch],
  );

  const handlePreview = useCallback(
    (data: AppFileActionProps) => {
      previewChatFile(data);
    },
    [previewChatFile],
  );

  return (
    <div className={classNames('app-chat-message', { unread })}>
      <MQAvatar.Image
        size="sm"
        title={authorBasicInfo.realName}
        imageUrl={authorBasicInfo.profile.profilePictureFileHandle}
      />
      <div className="app-chat-message__content" data-testid="app-chat-message-content">
        <div className="app-chat-message__header">
          <MQLayout.Heading
            variant="neutral"
            info={<MQTypo.Caption>{format(created, TIME_HOURS_MINUTES_WITH_AM_PM)}</MQTypo.Caption>}
          >
            <MQTypo.Text size="3x" bold nowrap>
              {authorBasicInfo.realName}
            </MQTypo.Text>
          </MQLayout.Heading>
        </div>
        {!!attachment && (
          <div className="app-chat-message__attachment">
            <AppFilePreview
              uuid={attachment.uuid}
              id={attachment.id}
              data={attachment}
              onReload={handleReload}
              onPreview={handlePreview}
              onDownLoad={downloadChatFile}
              showInfo
            />
          </div>
        )}
        {parsed && (
          <div className="app-chat-message__text" data-testid="app-chat-message-text">
            {parsed}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatMessage;
