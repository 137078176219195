export const GUIDE_STEPS = {
  dashboard: [
    {
      selector: '[data-testid="stats-card-people-enrolled"]',
      content: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/hZdkQaZEA78?si=jIv4aXTgTU49wA6t" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
      name: 'guide',
      title: 'guide',
      actions: ['load'],
    },
    {
      selector: '[data-testid="stats-card-people-matched"]',
      content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      name: 'guide1',
      title: 'guide1',
      actions: ['load'],
    },
    {
      selector: '.admin-dashboard__chart-wrapper',
      content: ' Lorem Ipsum is not simply random text.Richard McClintock',
      name: 'guide3',
      title: 'guide3',
      actions: ['load'],
    },
    {
      selector: '[data-testid="stats-card-surplus-availability"]',
      content: 'Hello Guide 2 Hello Guide 2Hello Guide 2Hello Guide 2Hello Guide 2Hello Guide 2',
      name: 'guide2',
      title: 'guide2',
      actions: ['load'],
    },
  ],
};
