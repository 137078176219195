import { MessageTokenDto, MessageTokenParamsDto } from 'types/global';

import { APP_GLOBAL_MESSAGES } from 'definitions/messages';



export const generateToken = (data: MessageTokenParamsDto): MessageTokenDto => {
  if (data.linkRestricted === false) {
    data.additionalInfo = APP_GLOBAL_MESSAGES.tokenEmbedAsLinkRestrictLabel;
  }

  return {
    ...data,
    isLink: !!data.isLink,
  };
};
